import { Blueprint } from '../firebase';
import collections from './collections';

export const blueprintIdFromBlueprint = (blueprint: Blueprint) =>
  `${blueprint.department}-${blueprint.documentNr}-${blueprint.type}-${blueprint.language}-v${blueprint?.version?.admin ?? 1}-${blueprint?.version?.customer ?? 0
  }`;

export const cleanBlueprint = (blueprint: Blueprint) => {
  const { products, ...cleanedBlueprint } = blueprint;
  return { cleanedBlueprint, rest: { products } };
};

export const firestoreBlueprintPath = (id: string) => `/${collections.blueprints}/${id}`;
