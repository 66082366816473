import { WPKUser } from '../types/firebase';
import { firestore } from '../firebase/init';
import { MandateRoles, WpkRole } from '../types/global';
import collections from '../types/shared/collections';
import { IdTokenResult } from '@firebase/auth';
import { doc } from 'firebase/firestore';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const userIsAdmin = (idTokenResult: IdTokenResult | undefined) => (idTokenResult?.claims?.roles as any)?.admin === true;
export const displayName = (user?: WPKUser) =>
  user?.firstName || user?.lastName ? `${user.firstName ?? ''} ${user?.lastName ?? ''}` : user?.email;

export const userIdFromWpkUser = (user: WPKUser) => user.key;
export const firestoreUserPath = (id: string) => `/${collections.users}/${id}`;
export const userGetRef = (user: WPKUser) => doc(firestore, firestoreUserPath(userIdFromWpkUser(user)));
export const userGetRefById = (userId: string) => doc(firestore, firestoreUserPath(userId));

export const mandateIsAllowedToWrite = (isMandate: boolean, user: WPKUser) => isMandate && user.role === WpkRole.Consultant;

export const hasMandate = (user?: WPKUser) => (user && MandateRoles.includes(user.role)) === true;

export const isCustomer = (user?: WPKUser) => (user && user.role === WpkRole.Customer) === true;
export const isCertifier = (user?: WPKUser) => user && user.role === WpkRole.Certifier;
