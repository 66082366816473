import { Form } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { admin } from '../../../../routing/routes';
import { toggleArchiveBlueprint, updateBlueprint } from '../../../../services/firebase/blueprints';
import { useNotifications } from '../../../../shared/notifications';
import { Blueprint } from '../../../../types/firebase';
import BlueprintForm from '../form';

interface URLParams {
  blueprintId?: string;
}

export const BlueprintSettings: React.FC<{ blueprint: Blueprint }> = ({ blueprint }) => {
  const [form] = Form.useForm();
  const { blueprintId } = useParams<URLParams>();
  const { t } = useTranslation();
  const history = useHistory();
  const { notifyGeneralError, notifySaveSuccess } = useNotifications(t('admin.blueprints.single'));

  const onUpdate = (blueprintData: Blueprint) => {
    if (!blueprintId) return Promise.resolve(false);
    return updateBlueprint({ ...blueprint, ...blueprintData })
      .then(notifySaveSuccess)
      .then(() => true)
      .catch(notifyGeneralError)
      .catch(() => false);
  };

  const onToggleArchiveBlueprintCallback = (bp: Blueprint) =>
    toggleArchiveBlueprint(bp)
      .then(notifySaveSuccess)
      .then(() => history.push(admin.blueprints.list()))
      .catch((e: Error) => {
        console.error(e);
        notifyGeneralError();
      });

  return (
    <BlueprintForm
      blueprint={blueprint}
      hideSaveAndNew
      form={form}
      onFormValuesChanged={onUpdate}
      onToggleArchiveCallback={onToggleArchiveBlueprintCallback}
    />
  );
};

export default BlueprintSettings;
