import { Company } from '../types/firebase/collections/company';
import { firestore } from '../firebase/init';
import collections from '../types/shared/collections';
import { doc } from 'firebase/firestore';

export const companyIsComplete = (company?: Company) =>
  company?.registrationNumber && company.registrationNumber.length > 0 && company?.name && company?.name.length > 0;

export const companyIdFromCompany = (company: Company) => company.key;
export const firestoreCompanyPath = (id: string) => `/${collections.companies}/${id}`;
export const companyGetRef = (company: Company) => doc(firestore, firestoreCompanyPath(companyIdFromCompany(company)));
export const companyGetRefById = (companyId: string) => doc(firestore, firestoreCompanyPath(companyId));
