import bluebird from 'bluebird';
import { callables } from '../../firebase/functions';
import { blueprintGetRef } from '../../shared/blueprint';
import { productGetRef } from '../../shared/products';
import { DuplicateSaveError } from '../../types/errors';
import { Blueprint, BlueprintStatus } from '../../types/firebase';
import { blueprintIdFromBlueprint, cleanBlueprint } from '../../types/shared/blueprints';

const updateOrcreate = (blueprint: Blueprint, isUpdate: boolean = false) => {
  const createOrUpdateFunction = async () => {
    const {
      cleanedBlueprint,
      rest: { products },
    } = cleanBlueprint(blueprint);

    const key = blueprintIdFromBlueprint(cleanedBlueprint);
    try {
      const editFunction = isUpdate ? callables.blueprints.update : callables.blueprints.create;

      const { productRefs, attachmentRefs, previousHandbookRef, ...dataToSend } = cleanedBlueprint;

      await editFunction({
        key,
        blueprintData: dataToSend,
        blueprintRefPath: blueprintGetRef(cleanedBlueprint).path,
        productPaths: (products ?? []).map((p) => productGetRef(p).path),
        documentPaths: cleanedBlueprint?.attachmentRefs?.map((r) => r.path) ?? [],
      });

      return true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error(error);
      if (error.message === 'DUPLICATE') {
        throw new DuplicateSaveError('Already exists');
      }
      throw error;
    }
  };

  return bluebird.resolve(createOrUpdateFunction());
};

export const createBlueprint = (blueprint: Blueprint) => updateOrcreate(blueprint);

export const updateBlueprint = (blueprint: Blueprint) => updateOrcreate(blueprint, true);

export const toggleArchiveBlueprint = (blueprint: Blueprint) =>
  updateOrcreate(
    { ...blueprint, status: blueprint.status === BlueprintStatus.Retired ? BlueprintStatus.Released : BlueprintStatus.Retired },
    true,
  );

export const publishBlueprint = (blueprint: Blueprint) => {
  if (blueprint.status !== BlueprintStatus.Draft) return Promise.reject('Blueprint is not in status DRAFT, not going to publish');
  const { cleanedBlueprint } = cleanBlueprint(blueprint);

  return callables.blueprints.publish({
    blueprintRefPath: blueprintGetRef(cleanedBlueprint).path,
  });
};

export const copyBlueprint = (blueprint: Blueprint) => {
  if (blueprint.status !== BlueprintStatus.Released) return Promise.reject('Blueprint is not in status RELEASED, not going to copy');
  if (!blueprint.version || !blueprint?.version?.admin) return Promise.reject('Blueprint has no version');

  return callables.blueprints.copy({
    blueprintRefPath: blueprintGetRef(blueprint).path,
  });
};

export const deleteBlueprint = (blueprint: Blueprint) => {
  if (blueprint.status !== BlueprintStatus.Draft && blueprint.status !== BlueprintStatus.Init) {
    return Promise.reject('Blueprint is not in status DRAFT, not going to delete');
  }

  return callables.blueprints.delete({
    blueprintRefPath: blueprintGetRef(blueprint).path,
  });
};
