import bluebird from 'bluebird';
import { collection, doc, runTransaction, Timestamp } from 'firebase/firestore';
import { deleteObject, getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { firestore, storage } from '../../firebase/init';
import { companyGetRef } from '../../shared/company';
import { createNewLocation } from '../../shared/location';
import { Company, OnboardingData } from '../../types/firebase/collections/company';
import collections from '../../types/shared/collections';
import { createFileData } from './documents';

export const storageCompanyDocumentsFolder = process.env.NODE_ENV === 'development' ? 'companyLogosDevelopment' : 'companyLogos';
// const bucketReference = storage.ref().child(storageCompanyDocumentsFolder);

export const updateCompany = (company: Company, data: OnboardingData['company']) => {
  return bluebird.resolve(
    runTransaction(firestore, async (t) => {
      const { file, ...updates } = data;
      let companyUpdates = { ...updates };

      const { downloadURL, fullPath } = await uploadFileIfNeeded(file);
      if (downloadURL && fullPath) {
        // new file, delete the old one if needed
        if (company.logoFullPath) {
          try {
            const fileRef = ref(storage, company.logoFullPath);
            await deleteObject(fileRef);
          } catch (error) {
            // maybe somebody removed the file from the storage already
            console.error(error);
          }
        }

        companyUpdates = { ...companyUpdates, logoURL: downloadURL, logoFullPath: fullPath };
      }

      const companyRef = companyGetRef(company);
      t.set(companyRef, { ...companyUpdates, updatedAt: Timestamp.now() }, { merge: true });

      company.users.forEach((u) => t.set(u, { companyName: companyUpdates.name }, { merge: true }));
    }),
  );
};

export const deleteCompanyLogo = (company: Company) => {
  return bluebird.resolve(
    runTransaction(firestore, async (t) => {
      if (company.logoFullPath) {
        try {
          const fileRef = ref(storage, `${storageCompanyDocumentsFolder}/${company.logoFullPath}`);
          await deleteObject(fileRef);
        } catch (error) {
          // maybe somebody removed the file from the storage already
          console.error(error);
          if (process.env.NODE_ENV !== 'development') {
            throw error;
          }
        }

        t.set(companyGetRef(company), { logoFullPath: null, logoURL: null } as Partial<Company>, { merge: true });
      }
    }),
  );
};

export const saveOnboardingData = (company: Company, data: OnboardingData) => {
  return bluebird.resolve(
    runTransaction(firestore, async (t) => {
      const {
        company: { file, ...restCompany },
      } = data;

      const { downloadURL, fullPath } = await uploadFileIfNeeded(file);

      const companyRef = companyGetRef(company);
      const locationRef = doc(collection(firestore, companyRef.path, collections.locations));
      const companySnapshot = await t.get(companyRef);

      t.set(companyRef, { ...restCompany, logoURL: downloadURL, logoFullPath: fullPath }, { merge: true });
      t.set(locationRef, createNewLocation(locationRef, data.location));

      // set the company name on all users
      (companySnapshot.data() as Company).users.forEach((u) => t.set(u, { companyName: restCompany.name }, { merge: true }));
    }),
  );
};

const uploadFileIfNeeded = async (file: File | undefined) => {
  let downloadURL = null;
  let fullPath = null;

  if (file) {
    const { storageFilename } = createFileData(file);
    const fileRef = ref(storage, `${storageCompanyDocumentsFolder}/${storageFilename}`);

    // upload the file
    await uploadBytes(fileRef, file);
    downloadURL = await getDownloadURL(fileRef);
    fullPath = fileRef.fullPath;
  }
  return { downloadURL, fullPath };
};
