import React from 'react';
import { Empty, Button } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Text from 'antd/lib/typography/Text';

interface Props {
  title: string;
  description: string;
  buttonTitle?: string;
  buttonIcon?: React.ReactNode;
  to?: string;
  onClick?: () => void;
  logo: string | React.ReactNode;
  enableAddButton?: boolean;
}

const StyledText = styled(Text)`
  font-weight: bold;
  display: block;
  font-size: 18px;
`;

const StyledEmpty = styled(Empty)`
  .ant-empty-image {
    height: auto;
    margin-bottom: 16px;
  }
`;

const EmptyMessage: React.FC<Props> = (props) => {
  const { title, description, buttonTitle, buttonIcon, to, logo, onClick, enableAddButton } = props;

  const button = (
    <Button size="large" icon={buttonIcon} type="primary" onClick={onClick}>
      {buttonTitle}
    </Button>
  );

  return (
    <StyledEmpty
      image={logo}
      imageStyle={{
        fontSize: 64,
      }}
      description={
        <>
          <StyledText>{title}</StyledText>
          {enableAddButton && <span>{description}</span>}
        </>
      }
    >
      {to && buttonTitle && buttonIcon && enableAddButton && <Link to={to}>{button}</Link>}
      {onClick && buttonTitle && buttonIcon && enableAddButton && button}
    </StyledEmpty>
  );
};

export default EmptyMessage;
