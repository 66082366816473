import React from 'react';
import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';

const NotActive: React.FC<unknown> = (_props) => {
  const { t } = useTranslation();

  return <Alert message={t('private.notActive.title')} description={t('private.notActive.description')} type="warning"></Alert>;
};

export default NotActive;
