import { WarningOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Tabs } from 'antd';
import firebase from 'firebase/app';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import MainLayout, { StyledChildrenContainer } from '../../../../components/layouts/main';
import LoadingIndicator from '../../../../components/molecules/loadingIndicator';
import WillaxPageHeader from '../../../../components/molecules/pageHeader';
import StatusRow from '../../../../components/molecules/statusRow';
import { StyledTabChildContainer, StyledTabContainer } from '../../../../components/molecules/tabs';
import { firestore } from '../../../../firebase/init';
import { app } from '../../../../routing/routes';
import { publishHandbook, updateHandbookCertification } from '../../../../services/firebase/handbooks';
import { companyGetRefById } from '../../../../shared/company';
import { UserContext } from '../../../../shared/contexts';
import { handbookGetRefById } from '../../../../shared/handbooks';
import { useNotifications } from '../../../../shared/notifications';
import { isCertifier, mandateIsAllowedToWrite, userGetRef } from '../../../../shared/user';
import { Company, Handbook } from '../../../../types/firebase';
import { HandbookStatus } from '../../../../types/firebase/collections/handbookTypes';
import collections from '../../../../types/shared/collections';
import { HandbookCertificationFormData, HandbookCertificationFormItems } from '../certificationForm';
import { HandbookChangeHistory } from './changeHistory';
import { HandbookComments } from './comments';
import { HandbookFormsList } from './forms';
import { HandbookSettings } from './settings';
import { onSnapshot, query, where, collectionGroup, Timestamp } from 'firebase/firestore';

interface URLParams {
  handbookId?: string;
  companyId?: string;
}

interface Props {
  isMandate?: boolean;
}

export const HandbookEdit: React.FC<Props> = (props) => {
  const { isMandate } = props;
  const { handbookId, companyId } = useParams<URLParams>();

  const { t } = useTranslation();
  const history = useHistory();
  const { notifyGeneralError, notifySaveSuccess } = useNotifications(t('private.handbooks.single'));
  const { firestoreUser } = useContext(UserContext);

  const cancelUrl = isMandate ? app.mandate.list() : app.handbooks.list();

  const [isCertificationModalVisible, setIsCertificationModalVisible] = useState<boolean>(false);
  const [company, setCompany] = useState<Company | undefined>(undefined);
  const [handbook, setHandbook] = useState<Handbook | undefined>(undefined);
  const [handbookForm] = Form.useForm();

  useEffect(() => {
    if (!handbookId || !companyId || !firestoreUser) return;

    const handbookRef = handbookGetRefById(companyId, handbookId);
    const clearFunctions = [
      onSnapshot(companyGetRefById(companyId), (snapshot) => setCompany(snapshot.data() as Company)),

      isMandate
        ? onSnapshot(
            query(
              collectionGroup(firestore, collections.handbooks),
              where('key', '==', handbookId),
              where(`${firestoreUser.role}Ref`, '==', userGetRef(firestoreUser)),
            ),
            (snapshot) => {
              if (snapshot.size === 1) {
                setHandbook(snapshot.docs[0].data() as Handbook);
              } else {
                setHandbook(undefined);
                history.push(app.mandate.list());
              }
            },
          )
        : onSnapshot(handbookRef, (snapshot) => setHandbook(snapshot.data() as Handbook)),
    ];

    return () => clearFunctions.forEach((f) => f());
  }, [handbookId, companyId, setHandbook, setCompany, firestoreUser, isMandate, history]);

  if (!handbookId || !companyId) {
    return <Redirect to={cancelUrl} />;
  }

  const onHandbookPublish = () => {
    if (!company || !handbook) return;

    return publishHandbook(company, handbook)
      .then(notifySaveSuccess)
      .then(() => history.push(cancelUrl))
      .catch((e: Error) => {
        console.error(e);
        notifyGeneralError();
      });
  };

  const onHandbookPublishCallback = () => {
    Modal.confirm({
      title: t('private.handbooks.edit.publishConfirm.title'),
      icon: <WarningOutlined />,
      content: t('private.handbooks.edit.publishConfirm.description'),
      okText: t('general.form.ok'),
      cancelText: t('general.form.cancel'),
      onOk: onHandbookPublish,
    });
  };

  const onHandbookEnterCertInfoCallback = () => setIsCertificationModalVisible(true);
  const onHandbookCertificationCancelled = () => {
    handbookForm.resetFields();
    setIsCertificationModalVisible(false);
  };

  const onHandbookCertificationSave = (values: HandbookCertificationFormData) => {
    if (!company || !handbook) return;
    return updateHandbookCertification(company, handbook, {
      certification:
        values.success && values.certificationNumber && values.validUntil
          ? {
              certificationNumber: values.certificationNumber,
              validUntil: Timestamp.fromDate(values.validUntil.toDate()),
            }
          : null,
    })
      .then(notifySaveSuccess)
      .then(() => setIsCertificationModalVisible(false))
      .then(() => history.push(app.handbooks.list()))
      .catch((e) => {
        console.error(e);
        notifyGeneralError();
      });
  };

  const onHandbookCertificationSaveCallback = () =>
    handbookForm
      .validateFields()
      .then((values) => {
        Modal.confirm({
          title: t('private.handbooks.edit.certification.confirmation.title'),
          icon: <WarningOutlined />,
          content: t('private.handbooks.edit.certification.confirmation.description'),
          okText: t('general.form.ok'),
          cancelText: t('general.form.cancel'),
          onOk: () => onHandbookCertificationSave(values as HandbookCertificationFormData),
        });
      })
      .catch((info) => {
        console.error('Validate Failed:', info);
      });

  const openPrint = () => window.open(app.handbooks.print(false, { companyId, handbookId, formId: '' }), '_blank');

  if (!firestoreUser) return null;

  const isAllowedToWrite = !isMandate || mandateIsAllowedToWrite(isMandate, firestoreUser);

  return (
    <MainLayout
      pageHeader={
        <WillaxPageHeader
          title={
            <StatusRow
              title={handbook?.name}
              statusToCheck={HandbookStatus.Draft}
              date={handbook?.updatedAt.toDate()}
              millis={handbook?.updatedAt.toMillis()}
              status={handbook?.status}
              translationPrefix="private.handbooks.status"
            />
          }
          extra={
            <>
              {handbook?.status === HandbookStatus.Draft && isAllowedToWrite && (
                <Button size="large" type="primary" onClick={onHandbookPublishCallback}>
                  {t('private.handbooks.edit.publish')}
                </Button>
              )}
              {handbook?.status === HandbookStatus.Released && (isCertifier(firestoreUser) || isAllowedToWrite) && (
                <Button size="large" type="primary" onClick={onHandbookEnterCertInfoCallback}>
                  {t('private.handbooks.edit.certification.title')}
                </Button>
              )}
              {!!handbook && !handbook.isArchived && (
                <Button size="large" type="ghost" target="_blank" onClick={openPrint} rel="noopener noreferrer">
                  {t('private.handbooks.print')}
                </Button>
              )}
            </>
          }
          routes={[
            { path: cancelUrl, breadcrumbName: t('private.customer.header.handbooks') },
            { path: '', breadcrumbName: handbook?.name ?? ' ' },
          ]}
        />
      }
    >
      {(!company || !handbook) && (
        <StyledChildrenContainer>
          <LoadingIndicator />
        </StyledChildrenContainer>
      )}

      {!!company && !!handbook && (
        <Modal
          visible={isCertificationModalVisible}
          onCancel={onHandbookCertificationCancelled}
          onOk={onHandbookCertificationSaveCallback}
          title={t('private.handbooks.edit.certification.title')}
          okText={t('general.form.save')}
          cancelText={t('general.form.cancel')}
        >
          <HandbookCertificationFormItems form={handbookForm} certificationNumber={handbook.certification?.certificationNumber} />
        </Modal>
      )}

      {!!company && !!handbook && (
        <StyledChildrenContainer>
          <StyledTabContainer className="card-container">
            <Tabs type="card" defaultActiveKey="form">
              <Tabs.TabPane tab={t('private.handbooks.edit.tabs.forms')} key="form">
                <StyledTabChildContainer>
                  <HandbookFormsList company={company} handbook={handbook} isMandate={isMandate} isAllowedToWrite={isAllowedToWrite} />
                </StyledTabChildContainer>
              </Tabs.TabPane>
              {handbook?.status !== HandbookStatus.Deactivated && (
                <Tabs.TabPane tab={t('private.handbooks.edit.tabs.comments')} key="comments">
                  <StyledTabChildContainer>
                    <HandbookComments company={company} handbook={handbook} isMandate={isMandate} />
                  </StyledTabChildContainer>
                </Tabs.TabPane>
              )}
              {handbook?.status !== HandbookStatus.Deactivated && (
                <Tabs.TabPane tab={t('changeHistory.single')} key="changeHistory">
                  <StyledTabChildContainer>
                    <HandbookChangeHistory company={company} handbook={handbook} />
                  </StyledTabChildContainer>
                </Tabs.TabPane>
              )}
              {isAllowedToWrite && handbook?.status !== HandbookStatus.Deactivated && (
                <Tabs.TabPane tab={t('private.handbooks.edit.tabs.settings')} key="settings">
                  <StyledTabChildContainer>
                    <HandbookSettings company={company} handbook={handbook} isMandate={isMandate} />
                  </StyledTabChildContainer>
                </Tabs.TabPane>
              )}
            </Tabs>
          </StyledTabContainer>
        </StyledChildrenContainer>
      )}
    </MainLayout>
  );
};

export default HandbookEdit;
