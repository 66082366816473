import {
  CopyOutlined,
  DeleteOutlined,
  FileAddOutlined,
  MoreOutlined,
  PaperClipOutlined,
  PlusOutlined,
  RightOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Menu, Modal, Table, Tabs, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import Title from 'antd/lib/typography/Title';
import { History } from 'history';
import { TFunction } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import StyledListHeader from '../../../components/atoms/listPageHeader';
import MainLayout, { StyledChildrenContainer } from '../../../components/layouts/main';
import EmptyMessage from '../../../components/molecules/emptyMessage';
import LoadingIndicator from '../../../components/molecules/loadingIndicator';
import { LanguageRadioButtonFilter, RadioButtonFilterDefaultValue } from '../../../components/molecules/radioButtonFilter';
import SearchBar from '../../../components/molecules/searchBar';
import { StyledTabChildContainer, StyledTabContainer } from '../../../components/molecules/tabs';
import { firestore } from '../../../firebase/init';
import { admin } from '../../../routing/routes';
import { copyBlueprint, deleteBlueprint } from '../../../services/firebase/blueprints';
import { ApplicationContext } from '../../../shared/contexts';
import { noOp } from '../../../shared/functions';
import { useNotifications } from '../../../shared/notifications';
import { Blueprint, BlueprintStatus, UnsubscribeFunction } from '../../../types/firebase';
import collections from '../../../types/shared/collections';
import { collection, onSnapshot, where, orderBy, query } from 'firebase/firestore';

let unsubscribeProducts: UnsubscribeFunction = null;

export const BlueprintList: React.FC<unknown> = (_props) => {
  const { t } = useTranslation();
  const [blueprints, setBlueprints] = useState<Blueprint[] | undefined>(undefined);
  const history = useHistory();
  const { availableLanguages } = useContext(ApplicationContext);
  const { notifyDeletedSuccess, notifyGeneralError, notifyDeleteConfirm, notifySaveSuccess } = useNotifications<Blueprint>(
    t('admin.blueprints.single'),
  );
  const [selectedLanguage, setSelectedLanguage] = useState(RadioButtonFilterDefaultValue);
  const [searchTerm, setSearchTerm] = useState('');

  const searching = !!searchTerm || selectedLanguage !== RadioButtonFilterDefaultValue;

  useEffect(() => {
    const whereClauses = [];
    if (selectedLanguage !== RadioButtonFilterDefaultValue) {
      whereClauses.push(where('language', '==', selectedLanguage));
    }
    if (searchTerm && searchTerm.length > 0) {
      whereClauses.push(where('searchText', 'array-contains-any', searchTerm.toLowerCase().split(' ')));
    }

    unsubscribeProducts && unsubscribeProducts();
    unsubscribeProducts = onSnapshot(query(collection(firestore, collections.blueprints), ...whereClauses, orderBy('key')), (snapshot) => {
      const docsData = snapshot.docs.map((doc) => doc.data() as Blueprint);
      setBlueprints(docsData);
    });

    return function cleanup() {
      unsubscribeProducts && unsubscribeProducts();
    };
  }, [selectedLanguage, availableLanguages, searchTerm]);

  const onDeleteBlueprint = (blueprint: Blueprint) =>
    deleteBlueprint(blueprint)
      .then(notifyDeletedSuccess)
      .catch((e: Error) => {
        console.error(e);
        notifyGeneralError();
      });

  const onCopyBlueprint = (blueprint: Blueprint) =>
    copyBlueprint(blueprint)
      .then(notifySaveSuccess)
      .catch((e: Error) => {
        console.error(e);
        notifyGeneralError();
      });

  const copyBlueprintCallback = (blueprint: Blueprint) => {
    Modal.confirm({
      title: t('admin.blueprints.copyConfirm.title'),
      icon: <CopyOutlined />,
      content: t('admin.blueprints.copyConfirm.description'),
      okText: t('general.form.ok'),
      cancelText: t('general.form.cancel'),
      onOk: () => onCopyBlueprint(blueprint),
    });
  };

  const columns = createColumns(
    t,
    (blueprint: Blueprint) => notifyDeleteConfirm(blueprint, onDeleteBlueprint, noOp),
    copyBlueprintCallback,
    history,
  );

  const searchEmpty = !searching && blueprints && blueprints.length === 0 && (
    <EmptyMessage
      title={t('admin.blueprints.empty.title')}
      description={t('admin.blueprints.empty.description')}
      buttonTitle={t('admin.blueprints.empty.addButtonTitle')}
      buttonIcon={<PlusOutlined />}
      to={admin.blueprints.new()}
      logo={<FileAddOutlined />}
      enableAddButton
    />
  );

  const loading = typeof blueprints === 'undefined' && <LoadingIndicator />;

  const noData = searching && blueprints && blueprints.length === 0 && (
    <EmptyMessage
      title={t('general.notFound.title', { item: t('admin.blueprints.single') })}
      description={t('general.notFound.description')}
      buttonTitle={t('general.notFound.addButtonTitle', { item: t('admin.blueprints.single') })}
      buttonIcon={<PlusOutlined />}
      to={admin.blueprints.new()}
      logo={<SearchOutlined />}
      enableAddButton
    />
  );

  return (
    <MainLayout
      pageHeader={
        <StyledListHeader>
          <Title level={4}>{t('admin.header.blueprints')}</Title>
          <SearchBar
            extra={[
              <Link key="new" to={admin.blueprints.new()}>
                <Button icon={<PlusOutlined />} size="large" type="primary">
                  {t('admin.blueprints.empty.addButtonTitle')}
                </Button>
              </Link>,
            ]}
            placeholderText={t('admin.blueprints.list.placeholder')}
            onSearchInput={setSearchTerm}
            radioFilters={[<LanguageRadioButtonFilter key="language" onItemSelected={setSelectedLanguage} />]}
          />
        </StyledListHeader>
      }
    >
      <StyledChildrenContainer>
        <StyledTabContainer className="card-container">
          <Tabs type="card" defaultActiveKey="form">
            <Tabs.TabPane tab={t('private.handbooks.list.tabs.current')} key="form">
              <StyledTabChildContainer>
                {loading}
                {searchEmpty}
                {noData}

                {blueprints && blueprints.length > 0 && (
                  <Table
                    scroll={{ x: true }}
                    pagination={false}
                    dataSource={blueprints.filter((b) => b.status !== BlueprintStatus.Retired)}
                    columns={columns}
                    locale={{ emptyText: t('admin.blueprints.empty.title') }}
                  />
                )}
              </StyledTabChildContainer>
            </Tabs.TabPane>

            <Tabs.TabPane tab={t('private.handbooks.list.tabs.archive')} key="attachments">
              <StyledTabChildContainer>
                {loading}
                {searchEmpty}
                {noData}

                {blueprints && blueprints.length > 0 && (
                  <Table
                    scroll={{ x: true }}
                    pagination={false}
                    dataSource={blueprints.filter((b) => b.status === BlueprintStatus.Retired)}
                    columns={columns}
                    locale={{ emptyText: t('admin.blueprints.empty.title') }}
                  />
                )}
              </StyledTabChildContainer>
            </Tabs.TabPane>
          </Tabs>
        </StyledTabContainer>
      </StyledChildrenContainer>
    </MainLayout>
  );
};

export default BlueprintList;

const createColumns = (
  t: TFunction,
  showDeleteConfirm: (blueprint: Blueprint) => void,
  showCopyConfirm: (blueprint: Blueprint) => void,
  history: History,
): ColumnsType<Blueprint> => [
  {
    title: t('admin.blueprints.list.header.id'),
    dataIndex: 'key',
    key: 'key',
    fixed: true,
  },

  {
    title: t('admin.blueprints.list.header.title'),
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: t('admin.blueprints.list.header.language'),
    key: 'language',
    dataIndex: 'language',
    render: (_text, product, _index) => <span>{t(`general.languages.names.${product.language}`)}</span>,
  },
  {
    title: t('admin.blueprints.list.header.isRequired'),
    key: 'isRequired',
    dataIndex: 'isRequired',
    render: (_text, blueprint, _index) => <span>{t(`general.form.${blueprint.isRequired}`)}</span>,
  },
  {
    title: t('admin.blueprints.list.header.status'),
    key: 'status',
    dataIndex: 'status',
    render: (_text, blueprint, _index) => {
      const color = t(`admin.blueprints.statusColor.${blueprint.status}`);
      return <Tag color={color}>{t(`admin.blueprints.status.${blueprint.status}`)}</Tag>;
    },
  },
  {
    title: t('private.handbooks.list.header.version'),
    key: 'version',
    dataIndex: 'version',
    align: 'right',
    render: (_text, f, _index) => (
      <span>
        {f.version?.admin}.{f.version?.customer}
      </span>
    ),
  },
  {
    title: '',
    key: 'attachments',
    width: 24,
    render: (_text, f, _index) => (!!f.attachmentRefs && f.attachmentRefs.length > 0 ? <PaperClipOutlined /> : null),
  },
  {
    title: '',
    key: 'actions',
    render: (_text, blueprint, _index) => {
      const menu = (
        <Menu>
          {blueprint.status === BlueprintStatus.Released && (
            <Menu.Item>
              <Button size="large" type="link" onClick={() => showCopyConfirm(blueprint)}>
                <CopyOutlined />
                {t('admin.blueprints.copy')}
              </Button>
            </Menu.Item>
          )}
          <Menu.Item>
            <Button
              size="large"
              type="link"
              danger
              onClick={() => showDeleteConfirm(blueprint)}
              disabled={blueprint.status !== BlueprintStatus.Draft && blueprint.status !== BlueprintStatus.Init}
            >
              <DeleteOutlined />
              {t('general.form.delete')}
            </Button>
          </Menu.Item>
        </Menu>
      );
      return (
        <div style={{ width: 180 }}>
          <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
            <Button type="ghost" shape="circle" icon={<MoreOutlined />} size="small" />
          </Dropdown>
          <Button
            type="primary"
            shape="circle"
            icon={<RightOutlined />}
            size="small"
            style={{ marginLeft: 24 }}
            onClick={() => history.push(admin.blueprints.edit(false, { blueprintId: blueprint.key }))}
          />
        </div>
      );
    },
    align: 'right',
  },
];
