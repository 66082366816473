import { Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { firestore } from '../../../firebase/init';
import { app } from '../../../routing/routes';
import { UserContext } from '../../../shared/contexts';
import { updateTabTitle } from '../../../shared/document';
import { handbookFormGetRefById, handbookGetRefById } from '../../../shared/handbooks';
import { hasMandate, userGetRef } from '../../../shared/user';
import { Blueprint, Company, CustomerForm, Handbook, WPKLocation } from '../../../types/firebase';
import { WpkRole } from '../../../types/global';
import { blueprintIdFromBlueprint } from '../../../types/shared/blueprints';
import collections from '../../../types/shared/collections';
import {
  StyledAddress,
  StyledFooterLogo,
  StyledFormContainer,
  StyledFormHeader,
  StyledFormSubHeader,
  StyledFormTitle,
  StyledHandbookHeader,
  StyledImage,
  StyledImageContainer,
  StyledMainTitle,
  StyledPrintHandbook,
} from '../../atoms/print';
import { BlueprintTypeColors } from '../../molecules/blueprintColor';
import LoadingIndicator from '../../molecules/loadingIndicator';
import BlueprintFormEditor from './index';
import { PaperClipOutlined } from '@ant-design/icons';
import { getDoc } from '@firebase/firestore';
import { collection, collectionGroup, doc, getDocs, orderBy, query, where } from 'firebase/firestore';

interface PrintData {
  handbook: Handbook;
  company: Company;
  location: WPKLocation;
  forms: CustomerForm[];
}

interface URLParams {
  formId?: string;
  companyId?: string;
  handbookId?: string;
}

export const HandbookPrintView: React.FC<unknown> = (_props) => {
  const { formId, companyId, handbookId } = useParams<URLParams>();
  const { firestoreUser } = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();

  const [printData, setPrintData] = useState<PrintData | undefined>(undefined);

  useEffect(() => {
    if (!firestoreUser) return;

    async function fetchData() {
      if (!firestoreUser) return;
      if (!companyId || !handbookId) return;

      const whereClauses = [where('key', '==', handbookId), where('isArchived', '==', false)];
      if (hasMandate(firestoreUser)) {
        whereClauses.push(where(`${firestoreUser.role}Ref`, '==', userGetRef(firestoreUser)));
      }

      let handbook: Handbook | undefined = undefined;
      const results = await getDocs(query(collectionGroup(firestore, collections.handbooks), ...whereClauses));
      if (!results.empty) {
        handbook = results.docs[0].data() as Handbook;
      }

      // no access
      if (!handbook) return history.push(app.mandate.list());

      if (!handbook.locationRef) return;
      const handbookRef = handbookGetRefById(companyId, handbookId);

      let forms: CustomerForm[] = [];

      if (!formId) {
        // print the whole handbook
        forms = (
          await getDocs(query(collection(firestore, handbookRef.path, collections.forms), orderBy('originalKey'), where('isActive', '==', true)))
        ).docs.map((d) => d.data() as CustomerForm);
      } else {
        // only fetch a single form
        const form = (await getDoc(handbookFormGetRefById(companyId, handbookId, formId))).data() as CustomerForm;
        forms = [form];
      }

      const company = (await getDoc(handbook.companyRef)).data() as Company;
      const location = (await getDoc(handbook.locationRef)).data() as WPKLocation;

      setPrintData({ handbook, forms, company, location });
    }

    void fetchData();
  }, [setPrintData, formId, companyId, handbookId, firestoreUser, history]);

  useEffect(() => {
    if (printData) {
      if (printData.forms.length > 1) {
        updateTabTitle(printData.handbook.name);
      } else if (printData.forms.length === 1) {
        updateTabTitle(`${printData.forms[0].title} (${blueprintIdFromBlueprint(printData.forms[0])})`);
      }
    }
  }, [printData]);

  if (!printData) {
    return <LoadingIndicator />;
  }

  return (
    <StyledPrintHandbook>
      {printData.forms.length > 1 && (
        <StyledHandbookHeader>
          <StyledMainTitle level={1}>{printData.handbook.name}</StyledMainTitle>

          <p>
            {t('private.handbooks.list.header.version')}:&nbsp;{printData.handbook.version.major}.{printData.handbook.version.minor}
            <br />
            {t('private.handbooks.list.header.updatedAt')}:&nbsp;{printData.handbook.updatedAt.toDate().toLocaleString()}
            <br />
            {t('admin.blueprints.list.header.status')}:&nbsp;{t(`private.handbooks.status.${printData.handbook.status}`)}
          </p>

          <StyledAddress>
            <Typography.Title level={3}>{t('private.customer.header.company')}</Typography.Title>
            {printData.company.name} <br />
            {printData.company.address?.street}
            <br />
            {printData.company.address?.zipCode}&nbsp;{printData.company.address?.city}
          </StyledAddress>

          {printData.company.logoURL ? (
            <StyledImageContainer>
              <StyledImage src={printData.company.logoURL} alt="company logo" />
            </StyledImageContainer>
          ) : null}

          <StyledAddress>
            <Typography.Title level={3}>{t('private.location.single')}</Typography.Title>
            {printData.location.name} <br />
            {printData.location.street}
            <br />
            {printData.location.zipCode}&nbsp;{printData.location.city}
          </StyledAddress>

          <StyledFooterLogo src="/footer-logo.jpg" />
          <p>
            © {new Date().getFullYear()} {t('public.appName')}
          </p>
        </StyledHandbookHeader>
      )}
      {printData.forms.map((form) => (
        <StyledPrintForm key={form.key} form={form} company={printData.company} handbook={printData.handbook} />
      ))}
    </StyledPrintHandbook>
  );
};

export const StyledPrintForm: React.FC<{ form: CustomerForm | Blueprint; company?: Company; handbook?: Handbook }> = (props) => {
  const { t } = useTranslation();
  const { form, company, handbook } = props;
  const hasAttachments = (form.attachmentRefs?.length ?? 0) + (form.customerAttachments?.length ?? 0) > 0;

  return (
    <StyledFormContainer key={form.key}>
      <StyledFormHeader backgroundColor={BlueprintTypeColors[form.type]}>
        <StyledFormTitle style={{ fontSize: '14pt', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {form.title}&nbsp;
          <small style={{ fontSize: '10pt', textAlign: 'right' }}>
            {hasAttachments && (
              <>
                <PaperClipOutlined />
                &nbsp;
              </>
            )}
            {handbook && t(`admin.blueprints.types.${form.type}`)}
            <br />
            {blueprintIdFromBlueprint(form)}
            {form.location && (
              <>
                <br />
                {`${t('formLocation.title')}: ${form.location}`}
              </>
            )}
          </small>
        </StyledFormTitle>
      </StyledFormHeader>
      {handbook && (
        <StyledFormSubHeader>
          {company?.logoURL && <StyledImage style={{ maxWidth: '4cm' }} src={company.logoURL} alt="company logo" />}
          <div style={{ marginLeft: '0.3cm', fontSize: '8pt' }}>
            <b style={{ fontWeight: 'bold' }}>{handbook?.name}</b>
            <br />
            <p>
              {t('private.handbooks.list.header.version')}:&nbsp;{handbook.version.major}.{handbook.version.minor}&nbsp;
              {handbook.updatedAt.toDate().toLocaleString()}
            </p>
          </div>
          <div style={{ flex: 1, textAlign: 'right' }}>{t(`private.handbooks.status.${handbook.status}`)}</div>
        </StyledFormSubHeader>
      )}
      <BlueprintFormEditor
        blueprint={form}
        onSave={() => Promise.resolve()}
        isAdminEditing={false}
        readonly={true}
        isMandate={true}
        isAllowedToWrite={false}
      />
    </StyledFormContainer>
  );
};
