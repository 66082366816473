import { Form } from 'antd';
import { doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import MainLayout, { StyledChildrenContainer } from '../../../components/layouts/main';
import LoadingIndicator from '../../../components/molecules/loadingIndicator';
import WillaxPageHeader from '../../../components/molecules/pageHeader';
import { firestore } from '../../../firebase/init';
import { admin } from '../../../routing/routes';
import { updateProduct } from '../../../services/firebase/products';
import { useNotifications } from '../../../shared/notifications';
import { firestoreProductPath, productIdFromProduct } from '../../../shared/products';
import { Product } from '../../../types/firebase';
import ProductForm from './form';

interface URLParams {
  productId?: string;
}

export const ProductEdit: React.FC<unknown> = (_props) => {
  const [form] = Form.useForm();
  const { productId } = useParams<URLParams>();
  const history = useHistory();
  const { t } = useTranslation();
  const { notifyGeneralError, notifySaveSuccess } = useNotifications(t('admin.products.single'));
  const [product, setProduct] = useState<Product | undefined>(undefined);

  useEffect(() => {
    const fetchData = async () => {
      const product = (await getDoc(doc(firestore, firestoreProductPath(productId ?? 'will-never-match'))))?.data() as Product;
      if (!product) return history.push(admin.products.list());

      setProduct(product);
    };
    void fetchData();
  }, [productId, history, notifyGeneralError]);

  const onUpdate = (product: Product) => {
    return updateProduct(product)
      .then(notifySaveSuccess)
      .then(() => history.push(admin.products.list()))
      .catch(notifyGeneralError);
  };

  return (
    <MainLayout
      pageHeader={
        <WillaxPageHeader
          title={product?.name ?? ''}
          routes={[
            { path: admin.products.list(), breadcrumbName: t('admin.header.products') },
            { path: '', breadcrumbName: product ? productIdFromProduct(product) : '' },
          ]}
        />
      }
    >
      <StyledChildrenContainer>
        {!product && <LoadingIndicator />}
        {!!product && <ProductForm product={product} hideSaveAndNew form={form} onFormValuesChanged={onUpdate} />}
      </StyledChildrenContainer>
    </MainLayout>
  );
};

export default ProductEdit;
