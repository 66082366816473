import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';

const Container = styled.div`
  width: 100%;
  margin: 0 auto;

  @media (min-width: ${breakpoints.desktopSmall}) {
    max-width: 1168px;
  }

  @media (min-width: ${breakpoints.desktopLarge}) {
    max-width: 1312px;
  }
`;

export default Container;
