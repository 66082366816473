import React from 'react';
import { Company } from '../../../types/firebase';

interface Props {
  company: Company;
}

export const CompanyInfo: React.FC<Props> = (props) => {
  const { company } = props;

  return (
    <address>
      {company.name}
      <br />
      {company.address?.street}
      <br />
      {company.address?.zipCode} {company.address?.city}
      <br />
      {company.registrationNumber}
      <br />
      {company.website && (
        <a href={company.website} target="_blank" rel="noopener noreferrer">
          {company.website}
        </a>
      )}
    </address>
  );
};

export default CompanyInfo;
