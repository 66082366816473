import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';
import { elementSpacing } from '../../styles/spacings';
import Container from '../molecules/container';

const StyledListHeader = styled(Container)`
  padding: ${elementSpacing};

  @media only screen and (min-width: ${breakpoints.desktopSmall}) {
    padding-left: 0;
    padding-right: 0;
  }
`;

export default StyledListHeader;
