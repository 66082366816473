import { CheckOutlined, CommentOutlined, LinkOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { Dictionary } from 'lodash';
import groupBy from 'lodash/groupBy';
import head from 'lodash/head';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import EmptyMessage from '../../../../components/molecules/emptyMessage';
import LoadingIndicator from '../../../../components/molecules/loadingIndicator';
import { CommentCard } from '../../../../components/organisms/commentsEditor';
import { firestore } from '../../../../firebase/init';
import { app } from '../../../../routing/routes';
import { markHandbookCommentsAsDone } from '../../../../services/firebase/handbooks';
import { UserContext } from '../../../../shared/contexts';
import { handbookGetRef } from '../../../../shared/handbooks';
import { useNotifications } from '../../../../shared/notifications';
import { userGetRef } from '../../../../shared/user';
import { Company, Handbook, WPKFormComment } from '../../../../types/firebase';
import collections from '../../../../types/shared/collections';

interface Props {
  company: Company;
  handbook: Handbook;
  isMandate?: boolean;
}

export const HandbookComments: React.FC<Props> = (props) => {
  const { handbook, company, isMandate } = props;
  const { firestoreUser } = useContext(UserContext);
  const { t } = useTranslation();
  const { notifySaveSuccess, notifyGeneralError } = useNotifications(t('commentsEditor.single'));

  const [comments, setComments] = useState<Dictionary<WPKFormComment[]> | undefined>(undefined);

  useEffect(() => {
    const handBookRef = handbookGetRef(company, handbook);
    const cleanFunctions = [
      onSnapshot(query(collection(firestore, handBookRef.path, collections.comments), orderBy('createdAt')), async (snapshot) => {
        // group stuff
        const firestoreComments = snapshot.docs.map((d) => d.data() as WPKFormComment);
        const groupedComments = groupBy(firestoreComments, (e) => e.formKey);

        setComments(groupedComments);
      }),
    ];

    return () => cleanFunctions.forEach((f) => f());
  }, [company, handbook]);

  return (
    <>
      {(!comments || !firestoreUser) && <LoadingIndicator />}

      {!!comments && Object.keys(comments).length === 0 && (
        <EmptyMessage
          title={t('general.notFound.title', { item: t('commentsEditor.title') })}
          description={t('general.notFound.description')}
          logo={<CommentOutlined />}
        />
      )}

      {comments &&
        firestoreUser &&
        Object.keys(comments).map((formKey) => {
          const formComments = comments[formKey];
          const firstComment = head(formComments);
          const groupedSectionComments = groupBy(formComments, (c) => c.sectionIndex);
          const openTotalCount = formComments.filter((c) => c.isDone !== true).length;

          if (!firstComment) return null; // technically not possible, but hey

          return (
            <Collapse key={formKey}>
              <Collapse.Panel
                header={t('private.handbooks.comments.openIssuesTitle', {
                  title: firstComment.formTitle,
                  open: openTotalCount,
                  total: formComments.length,
                })}
                key={formKey}
              >
                {Object.keys(groupedSectionComments)
                  .sort()
                  .map((sectionKey) => {
                    const sectionComments = groupedSectionComments[sectionKey];
                    const firstSectionComment = head(sectionComments);

                    if (!firstSectionComment) return null; // again, not possible

                    const { companyId, handbookId, sectionId, formKey } = firstSectionComment;
                    const edit = (() => (isMandate ? app.mandate.forms.edit : app.handbooks.forms.edit))();
                    const route = edit(false, { companyId, handbookId, formId: formKey }, `#${sectionId}`);

                    const totalCountFormSection = sectionComments.filter((c) => c.isDone !== true).length;

                    const onMarkCommentsAsDone = () =>
                      markHandbookCommentsAsDone(companyId, handbookId, sectionComments)
                        .then(notifySaveSuccess)
                        .catch((e) => {
                          console.error(e);
                          notifyGeneralError();
                        });

                    return (
                      <Collapse
                        key={sectionKey}
                        defaultActiveKey={totalCountFormSection > 0 ? Object.keys(groupedSectionComments) : 'will-never-match'}
                      >
                        <Collapse.Panel
                          header={t('private.handbooks.comments.openIssuesTitle', {
                            title: firstSectionComment.sectionTitle,
                            open: totalCountFormSection,
                            total: sectionComments.length,
                          })}
                          key={sectionKey}
                          extra={
                            <>
                              {totalCountFormSection > 0 && (
                                <CheckOutlined title={t('private.handbooks.comments.markAsDone')} onClick={onMarkCommentsAsDone} />
                              )}

                              <NavLink to={route} title={t('private.handbooks.comments.jumpToSection')} style={{ marginLeft: 16 }}>
                                <LinkOutlined />
                              </NavLink>
                            </>
                          }
                        >
                          {sectionComments.map((c) => {
                            return <CommentCard key={c.key} comment={c} userRef={userGetRef(firestoreUser)} />;
                          })}
                        </Collapse.Panel>
                      </Collapse>
                    );
                  })}
              </Collapse.Panel>
            </Collapse>
          );
        })}
    </>
  );
};
