import { DeleteOutlined } from '@ant-design/icons';
import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import debounce from 'lodash/debounce';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { noOp } from '../../../shared/functions';
import { useNotifications } from '../../../shared/notifications';
import colors from '../../../styles/colors';
import { layoutSpacings } from '../../../styles/spacings';
import { BlueprintContentSectionBaseElement, ElementId, FormEditorInputValue } from '../../../types/firebase';
import { FormInputElementProps, HelpText, ListCheckbox, ListText, Paragraph, Title, VariantInput } from './elements';

interface Props {
  element: BlueprintContentSectionBaseElement;
  onDeleteElement: (elementId: ElementId) => void;
  onElementEditableChanged: (elementId: ElementId, editable: boolean) => void;
  onValueChanged: SectionElementOnValueChanged;
  isAdminEditing?: boolean;
  customerValue?: FormEditorInputValue;
  adminValue?: FormEditorInputValue;
  readonly: boolean;
}

export type SectionElementOnValueChanged = (elementId: ElementId, newValue: unknown) => void;

const StyledDeleteButton = styled(DeleteOutlined)`
  float: right;
  svg {
    fill: ${colors.red};
  }
`;

const StyledElementContainer = styled.div`
  /* ${layoutSpacings.marginBottom} */

  .ant-typography {
    margin-bottom: 8px;
  }
`;

const StyledEditContainer = styled.div`
  margin-top: 12px;
`;

const inputElementMap: { [key: string]: React.ComponentType<FormInputElementProps> } = {
  title: Title,
  paragraph: Paragraph,
  helpText: HelpText,
  input: VariantInput,
  listText: ListText,
  listCheckbox: ListCheckbox,
};

const SectionElement: React.FC<Props> = (props) => {
  const { element, onDeleteElement, onElementEditableChanged, onValueChanged, isAdminEditing, adminValue, customerValue, readonly } = props;
  const { t } = useTranslation();
  const { notifyDeleteConfirm } = useNotifications<BlueprintContentSectionBaseElement>(t('formEditor.elements.single'));

  const valueToUse = isAdminEditing ? adminValue : customerValue ?? adminValue;

  const onEditableByUserChanged = useCallback(
    (e: RadioChangeEvent) => onElementEditableChanged(element.id, e.target.value),
    [onElementEditableChanged, element],
  );

  const onDeleteClicked = useCallback(
    () => notifyDeleteConfirm(element, (item) => onDeleteElement(item.id), noOp),
    [notifyDeleteConfirm, onDeleteElement, element],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onValueChangedCallback = useCallback(
    debounce((value: unknown) => onValueChanged(element.id, value), 250, { trailing: true }),
    [onValueChanged, element],
  );

  const ElementToUse = inputElementMap[element.type];

  return (
    <StyledElementContainer>
      {ElementToUse && (
        <ElementToUse
          {...element}
          onValueChanged={onValueChangedCallback}
          isAdminEditing={isAdminEditing}
          value={valueToUse}
          adminValue={adminValue}
          customerValue={customerValue}
          readonly={readonly}
          additional={
            isAdminEditing === true ? (
              <StyledEditContainer>
                <span className="user-editeable">
                  {t('formEditor.elements.editableByUser')}
                  <Radio.Group onChange={onEditableByUserChanged} value={element.isUserEditable} style={{ marginLeft: 16 }}>
                    <Radio value={false}>{t('general.form.no')}</Radio>
                    <Radio value={true}>{t('general.form.yes')}</Radio>
                  </Radio.Group>
                </span>
                <StyledDeleteButton onClick={onDeleteClicked} />
              </StyledEditContainer>
            ) : null
          }
        />
      )}
    </StyledElementContainer>
  );
};

export default React.memo(SectionElement);
