import { Form } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import MainLayout, { StyledChildrenContainer } from '../../../components/layouts/main';
import WillaxPageHeader from '../../../components/molecules/pageHeader';
import { admin } from '../../../routing/routes';
import { createBlueprint } from '../../../services/firebase/blueprints';
import { useNotifications } from '../../../shared/notifications';
import { DuplicateSaveError } from '../../../types/errors';
import { Blueprint } from '../../../types/firebase';
import BlueprintForm from './form';

export const BlueprintNew: React.FC<unknown> = (_props) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { notifyGeneralError, notifySaveDuplicateError, notifySaveSuccess } = useNotifications(t('admin.blueprints.single'));

  const [form] = Form.useForm();
  form.setFieldsValue({ language: i18n.language });

  const executeSave = (values: Blueprint) =>
    createBlueprint(values)
      .then(() => {
        notifySaveSuccess();
        return true;
      })
      .catch(DuplicateSaveError, () => {
        notifySaveDuplicateError();
        return false;
      })
      .catch((error) => {
        console.error(error);
        notifyGeneralError();
        return false;
      });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFormValuesChanged = (values: any) =>
    executeSave(values)
      .then((success) => {
        if (success) history.push(admin.blueprints.list());
        return true;
      })
      .catch(() => false);

  const onSaveAndNew = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue() as Blueprint;
      return executeSave(values)
        .then((success) => {
          if (success) form.resetFields();
          return true;
        })
        .catch((e) => {
          console.error(e);
          notifyGeneralError();
          return false;
        });
    } catch (error) {
      // nothing to do
    }
  };

  return (
    <MainLayout
      pageHeader={
        <WillaxPageHeader
          title={t('admin.blueprints.empty.addButtonTitle')}
          routes={[
            { path: admin.blueprints.list(), breadcrumbName: t('admin.header.blueprints') },
            { path: admin.blueprints.new(), breadcrumbName: t('admin.blueprints.empty.addButtonTitle') },
          ]}
        />
      }
    >
      <StyledChildrenContainer>
        <BlueprintForm onFormValuesChanged={onFormValuesChanged} onSaveAndNew={onSaveAndNew} form={form} />
      </StyledChildrenContainer>
    </MainLayout>
  );
};

export default BlueprintNew;
