import { BaseCollectionObject } from '.';

export enum ProductStatus {
  Draft = 'DRAFT',
  Released = 'RELEASED',
  Retired = 'RETIRED',
}

export interface Product extends BaseCollectionObject {
  enNorm: string;
  name: string;
  variant: string | null;
  language: string;
  price: number;
  blueprints: { [key: string]: boolean | undefined };
  blueprintsPublished: { [key: string]: boolean | undefined };
  numberOfPublishedBlueprints: number;
  status: ProductStatus;
  version?: number | null;
  description?: string | null;
}
