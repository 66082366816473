import { ExclamationOutlined, MinusCircleOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, List, Modal, Radio, Tooltip } from 'antd';
import { FormProps } from 'antd/lib/form';
import { Store } from 'antd/lib/form/interface';
import Paragraph from 'antd/lib/typography/Paragraph';
import Title from 'antd/lib/typography/Title';
import { doc } from 'firebase/firestore';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { StyledSelectButton } from '../../../components/atoms/buttons';
import { StyledNumberInput } from '../../../components/atoms/inputs';
import BlueprintColor from '../../../components/molecules/blueprintColor';
import FormActions from '../../../components/molecules/formActions';
import { StyledRadio } from '../../../components/molecules/radio';
import { firestore } from '../../../firebase/init';
import { admin } from '../../../routing/routes';
import { ApplicationContext } from '../../../shared/contexts';
import { productDocPathForProduct } from '../../../shared/products';
import { Blueprint, BlueprintStatus, BlueprintType, Product } from '../../../types/firebase';
import ProductPicker from '../products/picker';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFormValuesChanged: (values: any) => Promise<boolean>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSaveAndNew?: () => void;
  onToggleArchiveCallback?: (blueprint: Blueprint) => void;
  hideSaveAndNew?: boolean;
  form: FormProps['form'];
  blueprint?: Blueprint;
}

const StyledBlueprintColor = styled(BlueprintColor)`
  margin-left: 4px;
  margin-top: 2px;
`;

const BlueprintForm: React.FC<Props> = (props) => {
  const { onFormValuesChanged, onSaveAndNew, onToggleArchiveCallback: onArchiveCallback, form, hideSaveAndNew, blueprint } = props;
  const { availableLanguages } = useContext(ApplicationContext);
  const { t } = useTranslation();

  const [showProductPicker, setShowProductPicker] = useState(false);
  const [blueprintCopy, setBlueprintCopy] = useState<Blueprint | undefined>(blueprint);
  const [isSaving, setIsSaving] = useState(false);

  const editMode = !!blueprint;
  const isProductSelectionPossible = !blueprint || blueprint?.status === 'DRAFT';

  const onProductsSelected = (products: Product[]) => {
    setShowProductPicker(false);

    const previousProductKeys = (blueprintCopy?.products ?? []).map((p) => p.key);
    const newProductsToAdd = products.filter((p) => previousProductKeys.indexOf(p.key) === -1);
    const combinedNewProducts = [...(blueprintCopy?.products ?? []), ...newProductsToAdd];
    const productRefs = combinedNewProducts.map((p) => doc(firestore, productDocPathForProduct(p)));

    setBlueprintCopy({ ...((blueprintCopy ?? {}) as Blueprint), productRefs, products: combinedNewProducts });
    form?.setFieldsValue({ products: combinedNewProducts });
  };

  const renderListItem = (product: Product, onDelete: (index: number) => void, index: number, allowDelete: boolean) => (
    <List.Item actions={allowDelete ? [<MinusCircleOutlined key="delete" onClick={() => onDelete(index)} />] : []}>
      <List.Item.Meta title={`${product.name} ${product.key}`} />
    </List.Item>
  );

  const onSave = (values: Store) => {
    setIsSaving(true);
    void onFormValuesChanged(values).then(() => setIsSaving(false));
  };

  const archiveFormCallback = () => {
    if (!blueprint) return;

    Modal.confirm({
      title: t(`admin.blueprints.toggle${blueprint.status.toUpperCase()}`),
      icon: <ExclamationOutlined />,
      okText: t('general.form.ok'),
      cancelText: t('general.form.cancel'),
      onOk: () => onArchiveCallback?.(blueprint),
    });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onValuesChange={(changset, values) => setBlueprintCopy(values as Blueprint)}
      onFinish={onSave}
      initialValues={blueprintCopy}
    >
      <Title level={4}>{t('general.languages.title')}</Title>
      <p>{t('admin.blueprints.create.languageIntroduction')}</p>

      <Form.Item name="language" rules={[{ required: true, message: t('general.form.required') }]}>
        <Radio.Group buttonStyle="solid" disabled={editMode}>
          {availableLanguages?.map((lang: string) => (
            <Radio.Button key={lang} value={lang}>
              {t(`general.languages.names.${lang}`)}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>

      <Title level={4}>{t('admin.header.products')}</Title>
      <Paragraph>{t('admin.blueprints.create.fields.product.introduction')}</Paragraph>

      {showProductPicker && (
        <ProductPicker
          visible={true}
          onCancel={() => setShowProductPicker(false)}
          onOk={onProductsSelected}
          language={form?.getFieldValue('language')}
        />
      )}
      <Form.Item>
        <Form.Item name="products" valuePropName="products" noStyle rules={[{ required: true, message: t('general.form.required') }]}>
          <Form.List name="products">
            {(fields, { remove }) => {
              const products = blueprintCopy?.products ?? [];
              return (
                <>
                  {fields.length > 0 && (
                    <List
                      className="demo-loadmore-list"
                      itemLayout="horizontal"
                      dataSource={products}
                      renderItem={(item: Product, index: number) =>
                        renderListItem(item, remove, index, fields.length > 1 && isProductSelectionPossible)
                      }
                    />
                  )}

                  {isProductSelectionPossible && (
                    <StyledSelectButton
                      type="default"
                      onClick={() => setShowProductPicker(true)}
                      disabled={!form?.getFieldValue('language')}
                    >
                      <PlusOutlined /> {t('admin.blueprints.create.fields.product.label')}
                    </StyledSelectButton>
                  )}
                </>
              );
            }}
          </Form.List>
        </Form.Item>
      </Form.Item>

      <Title level={4}>{t('admin.blueprints.create.detailsTitle')}</Title>

      <Form.Item
        name="title"
        rules={[{ required: true, message: t('general.form.required') }]}
        label={t('admin.blueprints.create.fields.title.label')}
      >
        <Input size="large" placeholder={t('admin.blueprints.create.fields.title.placeholder')} disabled={!isProductSelectionPossible} />
      </Form.Item>

      <Form.Item
        name="department"
        rules={[{ required: true, message: t('general.form.required') }]}
        label={t('admin.blueprints.create.fields.department.label')}
      >
        <Input size="large" placeholder={t('admin.blueprints.create.fields.department.placeholder')} disabled={editMode} />
      </Form.Item>

      <Form.Item
        name="documentNr"
        rules={[{ required: true, message: t('general.form.required') }]}
        label={t('admin.blueprints.create.fields.documentNr.label')}
      >
        <StyledNumberInput
          size="large"
          type="number"
          placeholder={t('admin.blueprints.create.fields.documentNr.placeholder')}
          disabled={editMode}
          min={0}
        />
      </Form.Item>

      <Form.Item
        name="type"
        rules={[{ required: true, message: t('general.form.required') }]}
        label={t('admin.blueprints.create.fields.type.label')}
      >
        <Radio.Group>
          {Object.keys(BlueprintType).map((type, _index) => {
            return (
              <StyledRadio key={type} value={type} style={{}} disabled={editMode}>
                <StyledBlueprintColor type={type as BlueprintType} />
                &nbsp;&nbsp;
                <b>{type}</b>&nbsp;
                {t(`admin.blueprints.types.${type}`)}
              </StyledRadio>
            );
          })}
        </Radio.Group>
      </Form.Item>

      <Form.Item
        name="isRequired"
        rules={[{ required: true, message: t('general.form.required') }]}
        label={
          <span>
            {t('admin.blueprints.create.fields.required.label')}&nbsp;
            <Tooltip title={t('admin.blueprints.create.fields.required.introduction')}>
              <QuestionCircleOutlined />
            </Tooltip>
          </span>
        }
      >
        <Radio.Group disabled={!isProductSelectionPossible}>
          <Radio value={true}>{t('general.form.yes')}</Radio>
          <Radio value={false}>{t('general.form.no')}</Radio>
        </Radio.Group>
      </Form.Item>

      {isProductSelectionPossible && (
        <FormActions
          cancelUrl={editMode ? undefined : admin.blueprints.list()}
          saveAndNew={onSaveAndNew}
          hideSaveAndNew={hideSaveAndNew}
          isSaving={isSaving}
        />
      )}

      {blueprint && blueprint.isCurrent && [BlueprintStatus.Released, BlueprintStatus.Retired].indexOf(blueprint.status) !== -1 && (
        <Button danger onClick={archiveFormCallback}>
          {t(`admin.blueprints.toggle${blueprint.status.toUpperCase()}`)}
        </Button>
      )}
    </Form>
  );
};

export default BlueprintForm;
