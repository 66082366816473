const colors = {
  white: '#ffffff',
  darkBlue: 'rgb(224, 228, 236)',
  black: '#001529',
  red: 'red',
  deactivated: '#F0F4F8',
  deactivatedColor: 'rgba(0,0,0,0.2)',
  background: '#F0F4F8',
  linkInactive: 'rgba(255, 255, 255, 0.65)',
};

export default colors;
