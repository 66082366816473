import { DeleteOutlined } from '@ant-design/icons';
import { Modal, notification } from 'antd';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export function useNotifications<T>(item: string) {
  const { t } = useTranslation();

  const notifySaveSuccess = useCallback(
    () =>
      notification.success({
        message: t('general.messages.success.title'),
        description: t('general.messages.success.description', { item }),
      }),
    [item, t],
  );

  const notifyDeletedSuccess = useCallback(
    () =>
      notification.success({
        message: t('general.messages.deleted.title', { item }),
        description: t('general.messages.deleted.description', { item }),
      }),
    [item, t],
  );

  const notifyGeneralError = useCallback(
    () =>
      notification.error({
        message: t('general.messages.error.title'),
        description: t('general.messages.error.description', { item }),
      }),
    [item, t],
  );

  const notifySaveDuplicateError = useCallback(
    () =>
      notification.error({
        message: t('general.messages.errorDuplicate.title'),
        description: t('general.messages.errorDuplicate.description', { item }),
      }),
    [item, t],
  );

  const notifyDeleteConfirm = useCallback(
    (callbackItem: T, okCallback: (callbackItem: T) => void, cancelCallback: (callbackItem: T) => void) => {
      Modal.confirm({
        title: t('general.messages.delete.title', { item }),
        icon: <DeleteOutlined />,
        content: t('general.messages.delete.description', { item }),
        okText: t('general.form.deleteConfirm'),
        cancelText: t('general.form.cancel'),
        onOk: () => okCallback(callbackItem),
        onCancel: () => cancelCallback(callbackItem),
      });
    },
    [item, t],
  );

  return { notifySaveSuccess, notifyDeletedSuccess, notifyGeneralError, notifySaveDuplicateError, notifyDeleteConfirm };
}
