const collections = {
  users: 'users',
  metaData: 'metadata',
  config: 'config',
  products: 'products',
  blueprints: 'blueprints',
  forms: 'forms',
  documents: 'documents',
  companies: 'companies',
  locations: 'locations',
  handbooks: 'handbooks',
  comments: 'comments',
  changeHistory: 'changeHistory',
  mail: 'mail',
  mailTemplates: 'mail-templates',
};

export default collections;
