import { Button, Form, Input } from 'antd';
import { FormProps } from 'antd/lib/form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledAddressLine } from '..';
import { WPKLocation } from '../../../../types/firebase/collections/location';
import { StyledAddressStreetLine } from '../form';

interface ItemProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  location?: Location;
  editMode?: boolean;
}

export const LocationFormItems: React.FC<ItemProps> = (props) => {
  const { editMode } = props;
  const { t } = useTranslation();

  return (
    <>
      <Form.Item
        name={['location', 'name']}
        rules={[{ required: true, message: t('general.form.required') }]}
        label={t('private.location.create.fields.name')}
      >
        <Input size="large" disabled={editMode} />
      </Form.Item>

      <Form.Item
        name={['location', 'street']}
        rules={[{ required: true, message: t('general.form.required') }]}
        label={t('private.location.create.fields.address')}
      >
        <Input size="large" disabled={editMode} placeholder={t('private.company.create.fields.streetPlaceholder')} />
      </Form.Item>

      <StyledAddressLine compact>
        <Form.Item name={['location', 'zipCode']} rules={[{ required: true, message: t('general.form.required') }]}>
          <Input size="large" disabled={editMode} placeholder={t('private.company.create.fields.zipCodePlaceholder')} />
        </Form.Item>
        &nbsp;
        <StyledAddressStreetLine name={['location', 'city']} rules={[{ required: true, message: t('general.form.required') }]}>
          <Input size="large" disabled={editMode} placeholder={t('private.company.create.fields.cityPlaceholder')} />
        </StyledAddressStreetLine>
      </StyledAddressLine>
    </>
  );
};

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  location?: WPKLocation;
  hideSave?: boolean;
  form: FormProps['form'];
}

export const LocationForm: React.FC<Props> = (props) => {
  const { location, hideSave, form } = props;
  const { t } = useTranslation();
  const editMode = false;

  return (
    <Form form={form} initialValues={{ location }} layout="vertical">
      <LocationFormItems editMode={editMode} />

      {!hideSave && (
        <Form.Item>
          <Button size="large" htmlType="submit" type="primary">
            {t('general.form.save')}
          </Button>
        </Form.Item>
      )}
    </Form>
  );
};
