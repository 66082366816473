import React from 'react';
import { Input, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { FormInputElementProps } from '.';

const StyledParagraph = styled.p`
  white-space: pre-wrap;
`;

export const Paragraph: React.FC<FormInputElementProps<string>> = (props) => {
  const { onValueChanged, type, value, isUserEditable, isAdminEditing, readonly, additional } = props;
  const { t } = useTranslation();

  if (readonly || (!isAdminEditing && !isUserEditable)) return <StyledParagraph>{value?.value}</StyledParagraph>;

  return (
    <Form name={value?.id ?? 'paragraph'} layout="vertical">
      <Form.Item label={isAdminEditing === true ? t(`formEditor.addButtons.${type}`) : undefined}>
        <Input.TextArea autoSize={{ minRows: 3 }} defaultValue={value?.value} onChange={(e) => onValueChanged(e.target.value)} />
        {additional}
      </Form.Item>
    </Form>
  );
};
