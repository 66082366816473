import { Layout } from 'antd';
import { PageHeaderProps } from 'antd/lib/page-header';
import { TabsProps } from 'antd/lib/tabs';
import React from 'react';
import styled from 'styled-components';
import colors from '../../styles/colors';
import { layoutSpacings } from '../../styles/spacings';
import Container from '../molecules/container';
import Footer from '../molecules/footer';
import Header from '../molecules/header';

const { Content } = Layout;

const StyledMainContainer = styled(Container)`
  min-height: 360px;
  margin-top: 40px;
  margin-bottom: 64px;

  & .ant-form {
    max-width: 640px;
    margin-right: auto;

    & > h4:nth-child(n + 2) {
      margin-top: 40px;
    }
  }
`;

export const StyledChildrenContainer = styled(Container)``;

const StyledContent = styled(Content)`
  ${layoutSpacings.marginRight}
  ${layoutSpacings.marginLeft}

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    min-height: inherit;
  }
`;

const StyledLayout = styled(Layout)`
  min-height: 100vh;
  background-color: ${colors.background};
`;

const StyledPageHeaderBackground = styled.div`
  background-color: ${colors.white};
`;

const StyledHeaderSpacer = styled.div`
  height: 64px;
`;

interface Props {
  pageHeader?: React.ReactElement<PageHeaderProps> | null;
  tabs?: TabsProps;
  skipLayout?: boolean;
}

const MainLayout: React.FC<Props> = (props) => {
  const { children, pageHeader, tabs, skipLayout } = props;

  const content = (
    <>
      {pageHeader && (
        <StyledPageHeaderBackground>
          <Container>{pageHeader}</Container>
        </StyledPageHeaderBackground>
      )}

      <StyledContent>
        <StyledMainContainer>
          {tabs}
          {children}
        </StyledMainContainer>
      </StyledContent>
    </>
  );

  if (skipLayout === true) return content;

  return (
    <StyledLayout>
      <Header />
      <StyledHeaderSpacer />
      {content}
      <Footer />
    </StyledLayout>
  );
};

export default MainLayout;
