import { Button, Form, Input } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerForm } from '../../../../types/firebase';


interface Props {
  customerForm: CustomerForm;
  readonly: boolean;
  onSave: (form: CustomerForm) => Promise<void>;
}

const FormSettings: React.FC<Props> = props => {
  const { customerForm, readonly, onSave } = props;
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);

  const saveFormData = (values: Partial<CustomerForm>) => {
    setIsSaving(true);

    return onSave({ ...customerForm, location: values['location'] })
      .finally(() => setIsSaving(false));
  }

  return <div>
    <Form
      form={form}
      layout="vertical"
      onFinish={saveFormData}
      initialValues={customerForm}
    >
      <Title level={4}>{t('formLocation.title')}</Title>
      <p>{t('formLocation.description')}</p>

      <Form.Item name="location">
        <Input size="large" placeholder={t('formLocation.title')} disabled={readonly} />
      </Form.Item>

      <Form.Item>
        <Button size="large" type="primary" htmlType="submit" loading={isSaving}>
          {t('general.form.save')}
        </Button>
      </Form.Item>
    </Form>
  </div>;
}

export default FormSettings;
