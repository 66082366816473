import { Form } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import MainLayout, { StyledChildrenContainer } from '../../../components/layouts/main';
import WillaxPageHeader from '../../../components/molecules/pageHeader';
import { admin } from '../../../routing/routes';
import { createProduct } from '../../../services/firebase/products';
import { useNotifications } from '../../../shared/notifications';
import { DuplicateSaveError } from '../../../types/errors';
import { Product } from '../../../types/firebase';
import ProductForm from './form';

export const ProductNew: React.FC<unknown> = (_props) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { notifyGeneralError, notifySaveDuplicateError, notifySaveSuccess } = useNotifications(t('admin.products.single'));

  const [form] = Form.useForm();

  form.setFieldsValue({ language: i18n.language });

  const executeSave = (values: Product) =>
    createProduct(values)
      .then(() => {
        notifySaveSuccess();
        return true;
      })
      .catch(DuplicateSaveError, () => {
        notifySaveDuplicateError();
        return false;
      })
      .catch((error) => {
        console.error(error);
        notifyGeneralError();
        return false;
      });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFormValuesChanged = (values: any) =>
    executeSave(values).then((success) => {
      if (success) history.push(admin.products.list());
    });

  const onSaveAndNew = async () => {
    try {
      await form.validateFields();

      const values = form.getFieldsValue() as Product;
      void executeSave(values).then((success) => {
        if (success) form.resetFields();
      });
    } catch (e) {
      // nothing to do here
    }
  };

  return (
    <MainLayout pageHeader={<ProductNewPageHeader />}>
      <StyledChildrenContainer>
        <ProductForm onFormValuesChanged={onFormValuesChanged} onSaveAndNew={onSaveAndNew} form={form} />
      </StyledChildrenContainer>
    </MainLayout>
  );
};

export default ProductNew;

const ProductNewPageHeader: React.FC<unknown> = (_props) => {
  const { t } = useTranslation();
  return (
    <WillaxPageHeader
      title={t('admin.products.empty.addButtonTitle')}
      routes={[
        { path: admin.products.list(), breadcrumbName: t('admin.header.products') },
        { path: admin.products.new(), breadcrumbName: t('admin.products.empty.addButtonTitle') },
      ]}
    />
  );
};
