import { GenericObject } from '../types/helper';
import { stripTrailingSlash } from './helpers';

const appendBasePathIfNeeded = (relativePath: string) => (
  includeBasePath: boolean = false,
  params: GenericObject = {},
  suffix?: string,
): string => {
  const baseURL = stripTrailingSlash(process.env.REACT_APP_APP_BASE_URL ?? '');
  const replacedPath = Object.keys(params).reduce((acc, key) => {
    return acc.replace(`:${key}`, params[key] as string);
  }, relativePath);
  return `${includeBasePath ? baseURL : ''}${replacedPath}${suffix ?? ''}`;
};

export const app = {
  index: appendBasePathIfNeeded('/'),
  profile: appendBasePathIfNeeded('/app/profile'),
  company: {
    edit: appendBasePathIfNeeded('/app/company'),
  },
  mandate: {
    list: appendBasePathIfNeeded('/app/mandates'),
    edit: appendBasePathIfNeeded(`/app/mandates/:handbookId/company/:companyId`),
    forms: {
      edit: appendBasePathIfNeeded('/app/mandates/:handbookId/company/:companyId/forms/:formId'),
    },
  },
  handbooks: {
    list: appendBasePathIfNeeded('/app/handbooks'),
    new: appendBasePathIfNeeded('/app/handbooks/new'),
    edit: appendBasePathIfNeeded(`/app/handbooks/:handbookId/company/:companyId`),
    forms: {
      edit: appendBasePathIfNeeded('/app/handbooks/:handbookId/company/:companyId/forms/:formId'),
    },
    print: appendBasePathIfNeeded('/print/:handbookId/company/:companyId/forms/:formId?'),
  },
};

export const admin = {
  products: {
    list: appendBasePathIfNeeded('/admin/products'),
    new: appendBasePathIfNeeded('/admin/products/new'),
    edit: appendBasePathIfNeeded('/admin/products/:productId'),
  },
  companies: {
    list: appendBasePathIfNeeded('/admin/companies'),
    show: appendBasePathIfNeeded('/admin/companies/:companyId'),
  },
  blueprints: {
    list: appendBasePathIfNeeded('/admin/blueprints'),
    new: appendBasePathIfNeeded('/admin/blueprints/new'),
    edit: appendBasePathIfNeeded(`/admin/blueprints/:blueprintId`),
    print: appendBasePathIfNeeded('/admin/blueprints/:blueprintId/print'),
  },
  users: {
    list: appendBasePathIfNeeded('/admin/users'),
  },
  documents: {
    list: appendBasePathIfNeeded('/admin/documents'),
    new: appendBasePathIfNeeded('/admin/documents/new'),
  },
};

export const authentication = {
  login: appendBasePathIfNeeded('/auth/login'),
  register: appendBasePathIfNeeded('/auth/register'),
  forgotPassword: appendBasePathIfNeeded('/auth/forgot-password'),
  verifyEmail: appendBasePathIfNeeded('/auth/verify'),
  verifyCallback: appendBasePathIfNeeded('/auth/callbacks'),
};
