import { PlusOutlined } from '@ant-design/icons';
import { Button, Tabs } from 'antd';
import { Store } from 'antd/lib/form/interface';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CompanyForm } from '..';
import MainLayout, { StyledChildrenContainer } from '../../../../components/layouts/main';
import LoadingIndicator from '../../../../components/molecules/loadingIndicator';
import WillaxPageHeader from '../../../../components/molecules/pageHeader';
import { StyledTabChildContainer, StyledTabContainer } from '../../../../components/molecules/tabs';
import { app } from '../../../../routing/routes';
import { deleteCompanyLogo, updateCompany } from '../../../../services/firebase/company';
import { UserContext } from '../../../../shared/contexts';
import { useNotifications } from '../../../../shared/notifications';
import { hasMandate, isCustomer } from '../../../../shared/user';
import { WpkRole } from '../../../../types/global';
import { LocationEditCreateModal } from '../location/createEditModal';
import { CompanyEditLocations } from './locations';

export const CompanyEdit: React.FC<unknown> = (_props) => {
  const { t } = useTranslation();
  const { company, firestoreUser } = useContext(UserContext);
  const history = useHistory();
  const { notifyGeneralError, notifySaveSuccess } = useNotifications(t('admin.blueprints.single'));
  const { notifyGeneralError: notifyGeneralErrorLogo, notifyDeletedSuccess: notifyDeleteSuccessLogo } = useNotifications(
    t('admin.documents.create.fields.logo.label'),
  );

  const [showAddLocationModal, setShowLocationModal] = useState(false);

  const onCompanyFormValuesChanged = (values: Store) => {
    if (!company) return Promise.resolve(false);
    const { files, ...companyRest } = values.company;
    const [fileToUse] = files ?? [];

    return updateCompany(company, { ...companyRest, file: fileToUse ? fileToUse.originFileObj : null })
      .then(notifySaveSuccess)
      .then(() => history.push(app.company.edit()))
      .then(() => true)
      .catch((e) => {
        console.error(e);
        notifyGeneralError();
        return false;
      });
  };

  const companyDeleteLogo = () => {
    if (!company) return;
    deleteCompanyLogo(company).then(notifyDeleteSuccessLogo).catch(notifyGeneralErrorLogo);
  };

  const onAddLocationCallback = () => setShowLocationModal(true);
  const hideModal = () => setShowLocationModal(false);

  return (
    <MainLayout
      pageHeader={
        <WillaxPageHeader
          extra={
            isCustomer(firestoreUser) && (
              <Button icon={<PlusOutlined />} size="large" type="primary" onClick={onAddLocationCallback}>
                {t('private.location.create.title')}
              </Button>
            )
          }
          title={<>{company?.name ?? ''}</>}
          routes={[]}
        />
      }
    >
      {company && showAddLocationModal && (
        <LocationEditCreateModal company={company} isModalVisible={true} onCancel={hideModal} onOk={hideModal} />
      )}

      {!company && (
        <StyledChildrenContainer>
          <LoadingIndicator />
        </StyledChildrenContainer>
      )}

      {!!company && hasMandate(firestoreUser) && (
        <StyledChildrenContainer>
          <CompanyForm company={company} onFormValuesChanged={onCompanyFormValuesChanged} onDeleteCompanyLogo={companyDeleteLogo} />
        </StyledChildrenContainer>
      )}

      {!!company && isCustomer(firestoreUser) && (
        <StyledChildrenContainer>
          <StyledTabContainer className="card-container">
            <Tabs type="card" defaultActiveKey="form">
              <Tabs.TabPane tab={t('private.customer.header.manufacturing')} key="form">
                <StyledTabChildContainer>
                  <CompanyEditLocations company={company} />
                </StyledTabChildContainer>
              </Tabs.TabPane>
              <Tabs.TabPane tab={t('private.customer.header.company')} key="attachments">
                <StyledTabChildContainer>
                  <CompanyForm company={company} onFormValuesChanged={onCompanyFormValuesChanged} onDeleteCompanyLogo={companyDeleteLogo} />
                </StyledTabChildContainer>
              </Tabs.TabPane>
            </Tabs>
          </StyledTabContainer>
        </StyledChildrenContainer>
      )}
    </MainLayout>
  );
};

export default CompanyEdit;
