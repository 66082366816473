export enum WpkRole {
  Admin = 'admin',
  Customer = 'customer',
  Consultant = 'consultant',
  Certifier = 'certifier',
  Instructor = 'instructor',
}

export const UserRoles = [WpkRole.Customer, WpkRole.Consultant, WpkRole.Certifier];

export const MandateRoles = [WpkRole.Certifier, WpkRole.Consultant, WpkRole.Instructor];
