import React, { useContext } from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import { app, authentication } from '../../../routing/routes';
import { companyIsComplete } from '../../../shared/company';
import { UserContext } from '../../../shared/contexts';
import { hasMandate, isCustomer, userIsAdmin } from '../../../shared/user';
import { WpkRole } from '../../../types/global';

interface Props<P> extends React.PropsWithChildren<RouteProps> {
  authorizeAdmin?: boolean;
  authorizeCustomer?: boolean;
  authorizeMandate?: boolean;
  component?: React.ComponentType<RouteComponentProps<P>> | React.ComponentType<P>;
}

export const PrivateRoute: React.FC<Props<unknown>> = (props) => {
  const { authorizeAdmin, authorizeCustomer, authorizeMandate, ...componentProps } = props;
  const { userInfo, company, firestoreUser } = useContext(UserContext);

  const isLoggedIn = !!userInfo;
  const emailVerified = userInfo?.firebaseUser?.emailVerified === true;
  const isCompanyComplete = companyIsComplete(company);
  const isUserActive = firestoreUser && firestoreUser.active;
  const isUserAdmin = userIsAdmin(userInfo?.idTokenResult);

  if (isLoggedIn) {
    if (!userInfo || !firestoreUser) return null;

    if (authorizeAdmin && !isUserAdmin) return <Redirect to={app.index()} />;
    if (authorizeCustomer && !isCustomer(firestoreUser)) return <Redirect to={app.index()} />;
    if (authorizeMandate && !hasMandate(firestoreUser)) {
      return <Redirect to={app.index()} />;
    }
  }

  return (
    <>
      {isLoggedIn && emailVerified && <Route {...componentProps} />}
      {isLoggedIn && !emailVerified && <Redirect to={authentication.verifyEmail()} />}
      {isLoggedIn && !isUserAdmin && company && firestoreUser && (!isCompanyComplete || !isUserActive) && <Redirect to={app.index()} />}

      {!isLoggedIn && <Redirect to={authentication.login()} />}
    </>
  );
};

export default PrivateRoute;
