import { RightOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import Title from 'antd/lib/typography/Title';
import { History } from 'history';
import { TFunction } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import StyledListHeader from '../../../components/atoms/listPageHeader';
import MainLayout, { StyledChildrenContainer } from '../../../components/layouts/main';
import EmptyMessage from '../../../components/molecules/emptyMessage';
import LoadingIndicator from '../../../components/molecules/loadingIndicator';
import SearchBar from '../../../components/molecules/searchBar';
import { StyledTabChildContainer } from '../../../components/molecules/tabs';
import { callables } from '../../../firebase/functions';
import { firestore } from '../../../firebase/init';
import { admin } from '../../../routing/routes';
import { Company, UnsubscribeFunction } from '../../../types/firebase';
import collections from '../../../types/shared/collections';
import { collection, onSnapshot, query, where } from 'firebase/firestore';

let unsubscribeCompanies: UnsubscribeFunction = null;

const CompanyList: React.FC<unknown> = (_props) => {
  const { t, i18n } = useTranslation();
  const [companies, setCompanies] = useState<Company[] | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoadingProductsExport, setIsLoadingProductsExport] = useState(false);
  const history = useHistory();
  const searching = !!searchTerm;
  const currentLang = i18n.language;

  useEffect(() => {
    const whereClauses = [];
    if (searchTerm && searchTerm.length > 0) {
      whereClauses.push(where('searchText', 'array-contains-any', searchTerm.toLowerCase().split(' ')));
    }

    unsubscribeCompanies && unsubscribeCompanies();
    unsubscribeCompanies = onSnapshot(
      query(collection(firestore, collections.companies), ...whereClauses, where('name', '>', '')),
      (snapshot) => {
        const docsData = snapshot.docs.map((doc) => doc.data() as Company);
        setCompanies(docsData);
      },
    );

    return function cleanup() {
      unsubscribeCompanies && unsubscribeCompanies();
    };
  }, [searchTerm]);

  const columns = createColumns(t, history);

  const handleProductsExport = async () => {
    setIsLoadingProductsExport(true);

    callables.admin
      .onProductsExport(currentLang)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        const base64xl: string = response.data.xl;
        const xlsxBinaryString = atob(base64xl);
        const xlsxBlob = new Blob([new Uint8Array(xlsxBinaryString.split('').map((ch) => ch.charCodeAt(0)))], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const xlsxUrl = URL.createObjectURL(xlsxBlob);
        window.open(xlsxUrl, '_blank');
      })
      .finally(() => setIsLoadingProductsExport(false));
  };

  return (
    <MainLayout
      pageHeader={
        <StyledListHeader>
          <Title level={4}>{t('admin.header.companies')}</Title>
          <SearchBar
            placeholderText={t('admin.companies.list.placeholder')}
            onSearchInput={setSearchTerm}
            extra={
              <Button size="large" type="primary" onClick={handleProductsExport} loading={isLoadingProductsExport}>
                {t('admin.companies.list.exportProducts')}
              </Button>
            }
          />
        </StyledListHeader>
      }
    >
      <StyledChildrenContainer>
        {typeof companies === 'undefined' && <LoadingIndicator />}

        {searching && companies && companies.length === 0 && (
          <EmptyMessage
            title={t('general.notFound.title', { item: t('admin.companies.single') })}
            description={t('general.notFound.description')}
            logo={<SearchOutlined />}
          />
        )}

        {companies && companies.length > 0 && (
          <Table
            pagination={false}
            dataSource={companies}
            columns={columns}
            locale={{ emptyText: t('general.emptyList') }}
            scroll={{ x: true }}
          />
        )}
      </StyledChildrenContainer>
    </MainLayout>
  );
};

export default CompanyList;

const createColumns = (t: TFunction, history: History): ColumnsType<Company> => [
  {
    title: t('admin.companies.list.header.companyName'),
    dataIndex: 'name',
    key: 'name',
    fixed: true,
  },
  {
    title: t('admin.companies.list.header.registrationNumber'),
    dataIndex: 'registrationNumber',
    key: 'registrationNumber',
    ellipsis: true,
  },
  {
    title: t('admin.companies.list.header.street'),
    dataIndex: 'street',
    key: 'street',
    ellipsis: true,
    render: (_text, company, _index) => <span>{company.address?.street}</span>,
  },
  {
    title: t('admin.companies.list.header.zipCode'),
    dataIndex: 'zipCode',
    key: 'zipCode',
    ellipsis: true,
    render: (_text, company, _index) => <span>{company.address?.zipCode}</span>,
  },
  {
    title: t('admin.companies.list.header.city'),
    dataIndex: 'city',
    key: 'city',
    ellipsis: true,
    render: (_text, company, _index) => <span>{company.address?.city}</span>,
  },
  {
    title: '',
    key: 'actions',
    render: (_text, company, _index) => {
      return (
        <div style={{ width: 80 }}>
          <Button
            type="primary"
            shape="circle"
            icon={<RightOutlined />}
            size="small"
            style={{ marginLeft: 24 }}
            onClick={() => history.push(admin.companies.show(false, { companyId: company.key }))}
          />
        </div>
      );
    },
    align: 'right',
  },
];
