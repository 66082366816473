import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Layout, Menu, Typography } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { app } from '../../../routing/routes';
import { logout } from '../../../services/firebase/auth';
import { ApplicationContext, UserContext } from '../../../shared/contexts';
import { displayName, userIsAdmin, hasMandate, isCustomer } from '../../../shared/user';
import colors from '../../../styles/colors';
import { WpkRole } from '../../../types/global';
import Container from '../container';
import UserContextMenu, { adminMenuItems, customerMenuItems, mandateMenuItems } from './components';

import { ReactComponent as WPKLogo } from './Logo-OnlineWPK.svg';
import { ReactComponent as VSSMLogo } from './Logo-VSSM.svg';

const StyledHeaderContainer = styled(Container)`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 260px minmax(0, auto) auto;
`;

const StyledMenu = styled(Menu)`
  grid-column: 2;
`;

const StyledPlaceholder = styled.div`
  grid-column: 2;
`;

const StyledAntHeader = styled(Layout.Header)`
  position: fixed;
  z-index: 100;
  width: 100%;
  padding: 0 24px;
`;

const StyledProfileLink = styled.a`
  color: ${colors.linkInactive};
  &:hover {
    color: ${colors.white};
  }
  height: 64px;
`;

const StyledLogoImage = styled(WPKLogo)`
  height: auto;
  fill: ${colors.white};
  margin-top: 1px;
`;

const StyledVSSMImage = styled(VSSMLogo)`
  width: 200px;

  &,
  path {
    fill: ${colors.white};
  }

  margin-left: 16px;
`;

const StyledProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const StyledLogoLink = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-right: 40px;
`;

const LayoutHeader: React.FC<unknown> = (_props) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { userInfo, firestoreUser } = useContext(UserContext);
  const { propagateLogout, availableLanguages } = useContext(ApplicationContext);
  const isAdmin = userIsAdmin(userInfo?.idTokenResult);
  const selectedKeys = location.pathname.split('/').splice(0, 3).join('/');

  const exit = () => {
    propagateLogout && propagateLogout();
    logout((success) => {
      if (!success) console.error('Unable to logout');
    });
  };

  const changeLanguage = (langKey: string) => void i18n.changeLanguage(langKey);

  return (
    <StyledAntHeader>
      <StyledHeaderContainer>
        <StyledLogoLink to={app.index()}>
          <StyledLogoImage />
        </StyledLogoLink>

        {firestoreUser && firestoreUser.active === true && (
          <StyledMenu theme="dark" mode="horizontal" selectedKeys={[selectedKeys]}>
            {isAdmin && adminMenuItems(t)}
            {!isAdmin && isCustomer(firestoreUser) && customerMenuItems(t)}
            {!isAdmin && hasMandate(firestoreUser) && mandateMenuItems(t)}
          </StyledMenu>
        )}

        {firestoreUser && !firestoreUser.active && <StyledPlaceholder></StyledPlaceholder>}

        <StyledProfileContainer>
          <Dropdown
            overlay={
              <UserContextMenu
                t={t}
                exit={exit}
                availableLanguages={availableLanguages}
                currentLanguage={i18n.language}
                changeLanguage={changeLanguage}
              />
            }
            trigger={['click']}
          >
            <StyledProfileLink href="#top" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
              {displayName(firestoreUser)} <DownOutlined />
            </StyledProfileLink>
          </Dropdown>
          <StyledVSSMImage />
        </StyledProfileContainer>
      </StyledHeaderContainer>
    </StyledAntHeader>
  );
};

export default LayoutHeader;
