import i18n from 'i18next';
import TimeAgo from 'javascript-time-ago';
import timeAgoDE from 'javascript-time-ago/locale/de.json';
import timeAgoEN from 'javascript-time-ago/locale/en.json';
import { initReactI18next } from 'react-i18next';
import { auth } from '../firebase/init';
import { noOp } from '../shared/functions';
import de from './de.json';
import fr from './fr.json';
import it from './it.json';

export const availableResourceLanguages = {
  de: {
    translation: de,
  },
  fr: {
    translation: fr,
  },
  it: {
    translation: it,
  },
};

const sessionStorageLanguage = sessionStorage.getItem('language');

if (sessionStorageLanguage) {
  auth.languageCode = sessionStorageLanguage;
}

const i18nBuilder = i18n.use(initReactI18next).init({
  resources: availableResourceLanguages,
  lng: sessionStorageLanguage ?? 'de',
  interpolation: {
    escapeValue: false,
  },
});

export const changeLanguage = (languageKey: string) => {
  sessionStorage.setItem('language', languageKey);
  i18n.changeLanguage(languageKey).finally(noOp);
  auth.languageCode = languageKey;
};

export default i18nBuilder;

TimeAgo.addLocale(timeAgoDE);
TimeAgo.addLocale(timeAgoEN);
