import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FirestoreDocument } from '../../../types/firebase';
import { DocumentList } from '.';
import { StyledPickerModal } from '../products';
import colors from '../../../styles/colors';

interface Props {
  visible: boolean;
  onOk: (documents: FirestoreDocument[]) => void;
  onCancel: () => void;
  language: string;
}

const DocumentPicker: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const { visible, onOk, onCancel, language } = props;
  const [selectedDocuments, setSelectedDocuments] = useState<FirestoreDocument[]>([]);

  const okClicked = () => onOk(selectedDocuments);
  const cancelClicked = () => onCancel();
  const onChange = (_selectedRowKeys: React.ReactText[], selectedRows: FirestoreDocument[]) => setSelectedDocuments(selectedRows);

  return (
    <StyledPickerModal
      title={false}
      centered
      visible={visible}
      onOk={okClicked}
      onCancel={cancelClicked}
      bodyStyle={{ padding: 0, backgroundColor: colors.background }}
      okText={t('general.form.choose')}
      cancelText={t('general.form.cancel')}
    >
      <DocumentList pickerMode pickerOnChange={onChange} pickerFilterLanguage={language} />
    </StyledPickerModal>
  );
};

export default DocumentPicker;
