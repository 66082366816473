import { Form, Modal, Typography } from 'antd';
import { Store } from 'antd/lib/form/interface';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { saveOnboardingData } from '../../../services/firebase/company';
import { useNotifications } from '../../../shared/notifications';
import { Company, OnboardingData } from '../../../types/firebase/collections/company';
import { CompanyFormItems } from '../company';
import { LocationFormItems } from '../company/location';

interface Props {
  company: Company;
}

export const CompanyOnboarding: React.FC<Props> = (props) => {
  const { company } = props;
  const { t } = useTranslation();
  const { notifySaveSuccess, notifyGeneralError } = useNotifications('');

  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);

  const onFinish = (values: Store) => {
    const location = values.location;
    const { files, ...companyRest } = values.company;
    const [fileToUse] = files ?? [];

    setIsSaving(true);
    saveOnboardingData(company, {
      company: { ...companyRest, file: fileToUse ? fileToUse.originFileObj : null },
      location,
    } as OnboardingData)
      .then(() => {
        notifySaveSuccess();
      })
      .catch((e) => {
        console.error(e);
        notifyGeneralError();
        return false;
      })
      .finally(() => setIsSaving(false));
  };

  const onSaveCallback = () =>
    form
      .validateFields()
      .then(onFinish)
      .catch((info) => {
        console.error('Validate Failed:', info);
      });

  return (
    <Modal
      visible
      cancelButtonProps={{ hidden: true }}
      okButtonProps={{ loading: isSaving }}
      closable={false}
      okText={t('general.form.save')}
      onOk={onSaveCallback}
    >
      <Typography.Title level={3}>{t('private.company.create.title')}</Typography.Title>
      <Form layout="vertical" form={form}>
        <CompanyFormItems />

        <LocationFormItems />
      </Form>
    </Modal>
  );
};
