import { EditOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { TFunction } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteButtonIcon } from '../../../../components/atoms/icons';
import { firestore } from '../../../../firebase/init';
import { deleteLocation } from '../../../../services/firebase/location';
import { companyGetRef } from '../../../../shared/company';
import { noOp } from '../../../../shared/functions';
import { locationIsInUse } from '../../../../shared/location';
import { useNotifications } from '../../../../shared/notifications';
import { Company } from '../../../../types/firebase/collections/company';
import { WPKLocation } from '../../../../types/firebase/collections/location';
import collections from '../../../../types/shared/collections';
import { LocationEditCreateModal } from '../location/createEditModal';

interface Props {
  company?: Company;
}

export const CompanyEditLocations: React.FC<Props> = (props) => {
  const { company } = props;
  const { t } = useTranslation();
  const { notifyDeleteConfirm, notifyDeletedSuccess, notifyGeneralError } = useNotifications(t('private.location.single'));

  const [locations, setLocations] = useState<WPKLocation[]>([]);
  const [editLocation, setEditLocation] = useState<WPKLocation | undefined>(undefined);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  useEffect(() => {
    if (!company) return;
    return onSnapshot(query(collection(firestore, companyGetRef(company).path, collections.locations), orderBy('name')), (snapshot) =>
      setLocations(snapshot.docs.map((d) => d.data()) as WPKLocation[]),
    );
  }, [company]);

  const showEditModal = (location: WPKLocation) => {
    setEditLocation(location);
    setIsEditModalVisible(true);
  };

  const onHideModalCallback = () => {
    setEditLocation(undefined);
    setIsEditModalVisible(false);
  };

  const onDeleteCallback = useCallback(
    (location: WPKLocation) => {
      if (!company) return;
      notifyDeleteConfirm(location, () => deleteLocation(company, location).then(notifyDeletedSuccess).catch(notifyGeneralError), noOp);
    },
    [notifyDeleteConfirm, company, notifyDeletedSuccess, notifyGeneralError],
  );

  const columns = createColumns(t, locations.length, showEditModal, onDeleteCallback);

  return (
    <>
      {company && editLocation && (
        <LocationEditCreateModal
          isModalVisible={isEditModalVisible}
          company={company}
          location={editLocation}
          onOk={onHideModalCallback}
          onCancel={onHideModalCallback}
        />
      )}

      {locations && company && (
        <Table
          dataSource={locations}
          columns={columns}
          pagination={false}
          locale={{ emptyText: t('general.emptyList') }}
          scroll={{ x: true }}
        />
      )}
      {/* <Row gutter={16}>
        {locations &&
          company &&
          locations.map((l, _index, allLocations) => (
            <Col span={8} key={l.key}>
              <LocationCard location={l} company={company} isDeletable={allLocations.length > 1 && !locationIsInUse(l)} />
            </Col>
          ))}
      </Row> */}
    </>
  );
};

const createColumns = (
  t: TFunction,
  locationCount: number,
  editFunction: (location: WPKLocation) => void,
  deleteConfirm: (location: WPKLocation) => void,
): ColumnsType<WPKLocation> => {
  return [
    {
      title: t('admin.companies.list.header.companyName'),
      dataIndex: 'name',
      key: 'name',
      fixed: true,
    },
    {
      title: t('admin.companies.list.header.street'),
      dataIndex: 'street',
      key: 'street',
      render: (_text, location, _index) => <span>{location.street}</span>,
    },
    {
      title: t('admin.companies.list.header.zipCode'),
      dataIndex: 'zipCode',
      key: 'zipCode',
      render: (_text, location, _index) => <span>{location.zipCode}</span>,
    },
    {
      title: t('admin.companies.list.header.city'),
      dataIndex: 'city',
      key: 'city',
      render: (_text, location, _index) => <span>{location.city}</span>,
    },
    {
      title: '',
      key: 'actions',
      align: 'right',
      render: (_text, location, _index) => {
        return (
          <div style={{ width: 80 }}>
            <Button type="ghost" shape="circle" icon={<EditOutlined />} size="small" onClick={() => editFunction?.(location)} />
            <Button
              style={{ marginLeft: 24 }}
              danger
              shape="circle"
              icon={<DeleteButtonIcon />}
              size="small"
              disabled={!(locationCount > 1 && !locationIsInUse(location))}
              onClick={() => deleteConfirm?.(location)}
            />
          </div>
        );
      },
    },
  ];
};
