import bluebird from 'bluebird';
import firebase from 'firebase/app';

import { WPKLocation } from '../../types/firebase/collections/location';
import { Company, OnboardingData } from '../../types/firebase/collections/company';
import { locationGetRef, createNewLocation } from '../../shared/location';
import { companyGetRef } from '../../shared/company';
import { firestore } from '../../firebase/init';
import collections from '../../types/shared/collections';
import { Timestamp, setDoc, runTransaction, collection, doc } from 'firebase/firestore';

export const updateLocation = (company: Company, location: WPKLocation, newData: OnboardingData['location']) => {
  const locationRef = locationGetRef(company, location);
  const updatePromise = setDoc(locationRef, { ...newData, updatedAt: Timestamp.now() }, { merge: true });
  return bluebird.resolve(updatePromise);
};

export const createLocation = (company: Company, newData: OnboardingData['location']) => {
  return bluebird.resolve(
    runTransaction(firestore, (t) => {
      const companyRef = companyGetRef(company);
      const locationRef = doc(collection(firestore, companyRef.path, collections.locations)); // TODO test me
      const newLocationData = createNewLocation(locationRef, newData);

      t.set(locationRef, newLocationData);

      return Promise.resolve();
    }),
  );
};

export const deleteLocation = (company: Company, location: WPKLocation) => {
  return bluebird.resolve(
    runTransaction(firestore, (t) => {
      const locationRef = locationGetRef(company, location);
      t.delete(locationRef);
      return Promise.resolve();
    }),
  );
};
