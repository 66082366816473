import bluebird from 'bluebird';
import { doc, setDoc } from 'firebase/firestore';
import { firestore } from '../../firebase/init';
import { userIdFromWpkUser } from '../../shared/user';
import { WPKUser } from '../../types/firebase';
import collections from '../../types/shared/collections';

export const firestoreUserPath = (id: string) => `/${collections.users}/${id}`;
const getUserRef = (user: WPKUser) => doc(firestore, firestoreUserPath(userIdFromWpkUser(user)));

export const updateUser = (user: WPKUser) => {
  const docRef = getUserRef(user);
  const updatePromise = setDoc(docRef, { ...user, updatedAt: new Date(), description: user.description ?? null }, { merge: true });
  return bluebird.resolve(updatePromise);
};
