import { Form } from 'antd';
import { Store } from 'antd/lib/form/interface';
import Modal from 'antd/lib/modal/Modal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LocationForm } from '.';
import { createLocation, updateLocation } from '../../../../services/firebase/location';
import { useNotifications } from '../../../../shared/notifications';
import { Company } from '../../../../types/firebase';
import { WPKLocation } from '../../../../types/firebase/collections/location';

interface Props {
  isModalVisible?: boolean;
  company: Company;
  location?: WPKLocation;
  onOk: () => void;
  onCancel: () => void;
}

export const LocationEditCreateModal: React.FC<Props> = (props) => {
  const { isModalVisible, location, company, onCancel, onOk } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { notifySaveSuccess, notifyGeneralError } = useNotifications(t('private.location.single'));

  const [isSaving, setIsSaving] = useState(false);

  const onSaveCallback = async (_store: Store) => {
    try {
      setIsSaving(true);
      const data = await form.validateFields();
      if (!location) {
        createLocation(company, data['location'])
          .then(notifySaveSuccess)
          .then(onOk)
          .catch(notifyGeneralError)
          .finally(() => setIsSaving(false));
      } else {
        updateLocation(company, location, data['location'])
          .then(notifySaveSuccess)
          .then(onOk)
          .catch(notifyGeneralError)
          .finally(() => setIsSaving(false));
      }
    } catch (error) {
      console.warn(error);
    }
  };

  const onCancelCallback = () => {
    form.resetFields();
    onCancel();
  };
  return (
    <Modal
      onOk={onSaveCallback}
      okButtonProps={{ loading: isSaving }}
      okText={t('general.form.save')}
      onCancel={onCancelCallback}
      visible={isModalVisible}
    >
      <LocationForm form={form} hideSave location={location} />
    </Modal>
  );
};
