import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { useDebouncedCallback } from '../../shared/hooks';
import { layoutSpacings } from '../../styles/spacings';

interface Props extends Pick<React.HTMLAttributes<unknown>, 'className' | 'style'> {
  placeholderText: string;
  onSearchInput: (val: string) => void;
  radioFilters?: React.ReactNode[];
  extra?: React.ReactNode;
}

const StyledExtra = styled.div`
  ${layoutSpacings.marginLeft}
`;

const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${layoutSpacings.marginTop}
`;

const SearchBar: React.FC<Props> = (props) => {
  const { placeholderText, radioFilters, extra, onSearchInput, ...htmlAttributes } = props;

  const [debouncedOnSearchInput] = useDebouncedCallback(onSearchInput, 250, { trailing: true });

  return (
    <>
      <StyledInputContainer {...htmlAttributes}>
        <Input
          size="large"
          onChange={(e) => debouncedOnSearchInput((e.target.value ?? '').trim())}
          placeholder={placeholderText}
          prefix={<SearchOutlined />}
        />
        <StyledExtra>{extra}</StyledExtra>
      </StyledInputContainer>
      <div>{radioFilters && radioFilters.map((radioFilter) => radioFilter)}</div>
    </>
  );
};

export default SearchBar;
