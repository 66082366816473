import { Form } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import MainLayout, { StyledChildrenContainer } from '../../../components/layouts/main';
import WillaxPageHeader from '../../../components/molecules/pageHeader';
import { admin } from '../../../routing/routes';
import { createAdminDocument } from '../../../services/firebase/documents';
import { UserContext } from '../../../shared/contexts';
import { useNotifications } from '../../../shared/notifications';
import { FirestoreDocument } from '../../../types/firebase';
import DocumentForm from './form';

export const DocumentNew: React.FC<unknown> = (_props) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { firestoreUser } = useContext(UserContext);
  const { notifyGeneralError, notifySaveSuccess } = useNotifications(t('admin.documents.single'));
  const [form] = Form.useForm();

  form.setFieldsValue({ language: i18n.language });

  const executeSave = (values: FirestoreDocument) =>
    createAdminDocument(values, firestoreUser)
      .then(() => {
        notifySaveSuccess();
        return true;
      })
      .catch((error) => {
        console.error(error);
        notifyGeneralError();
        return false;
      });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFormValuesChanged = (values: any) =>
    executeSave({ ...values, file: values.files[0].originFileObj }).then((success) => {
      if (success) history.push(admin.documents.list());
    });

  return (
    <MainLayout pageHeader={<DocumentNewPageHeader />}>
      <StyledChildrenContainer>
        <DocumentForm onFormValuesChanged={onFormValuesChanged} hideSaveAndNew form={form} />
      </StyledChildrenContainer>
    </MainLayout>
  );
};

export default DocumentNew;

const DocumentNewPageHeader: React.FC<unknown> = (_props) => {
  const { t } = useTranslation();
  return (
    <WillaxPageHeader
      title={t('admin.documents.empty.addButtonTitle')}
      routes={[
        { path: admin.documents.list(), breadcrumbName: t('admin.header.documents') },
        { path: admin.documents.new(), breadcrumbName: t('admin.documents.empty.addButtonTitle') },
      ]}
    />
  );
};
