import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import MainLayout, { StyledChildrenContainer } from '../../../components/layouts/main';
import { admin, app } from '../../../routing/routes';
import { companyIsComplete } from '../../../shared/company';
import { UserContext } from '../../../shared/contexts';
import { hasMandate, isCustomer, userIsAdmin } from '../../../shared/user';
import NotActive from './notActive';
import { CompanyOnboarding } from './onboarding';

const Welcome = () => {
  const { firestoreUser, company, userInfo } = useContext(UserContext);

  const isActive = firestoreUser && firestoreUser?.active === true;
  const companyComplete = companyIsComplete(company);
  const isAdmin = userIsAdmin(userInfo?.idTokenResult);

  return (
    <MainLayout>
      <StyledChildrenContainer>
        {!isAdmin && firestoreUser && !isActive && <NotActive />}
        {!isAdmin && isActive && !companyComplete && !!company && <CompanyOnboarding company={company} />}
        {!isAdmin && isActive && companyComplete && (
          <>
            {!isAdmin && isCustomer(firestoreUser) && <Redirect to={app.handbooks.list()} />}
            {!isAdmin && hasMandate(firestoreUser) && <Redirect to={app.mandate.list()} />}
          </>
        )}

        {isAdmin && <Redirect to={admin.companies.list()} />}
      </StyledChildrenContainer>
    </MainLayout>
  );
};

export default Welcome;
