import { Col, Radio, Row } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ApplicationContext } from '../../shared/contexts';
import { layoutSpacings } from '../../styles/spacings';
import { UserRoles } from '../../types/global';

export interface RadioItem {
  title: string;
  value: string;
}

interface Props {
  title: string;
  radioItems: RadioItem[];
  onItemSelected: (selected: string) => void;
  selectedValue?: string;
}

const StyledTitle = styled.span`
  font-weight: bold;
`;

const StyledRow = styled(Row)`
  ${layoutSpacings.marginTop}
`;

const RadioButtonFilter: React.FC<Props> = (props) => {
  const { title, radioItems, selectedValue } = props;

  const onChange = (e: RadioChangeEvent): void => props.onItemSelected(e.target.value);

  return (
    <StyledRow gutter={16}>
      <Col>
        <StyledTitle>{title}</StyledTitle>
      </Col>
      <Col>
        <Radio.Group onChange={onChange} value={selectedValue}>
          {radioItems &&
            radioItems.map((radioItem) => (
              <Radio key={radioItem.value} value={radioItem.value}>
                {radioItem.title}
              </Radio>
            ))}
        </Radio.Group>
      </Col>
    </StyledRow>
  );
};

export default RadioButtonFilter;

export const RadioButtonFilterDefaultValue = 'all';
export const LanguageRadioButtonFilter: React.FC<Omit<Props, 'title' | 'radioItems'>> = (props) => {
  const { availableLanguages } = useContext(ApplicationContext);
  const { t } = useTranslation();
  const [checkedValue, setCheckedValue] = useState(RadioButtonFilterDefaultValue);

  const languages = [{ title: t('general.form.all'), value: 'all' }].concat(
    availableLanguages.map((lang) => ({ title: t(`general.languages.names.${lang}`), value: lang })),
  );

  const internalOnItemSelected = (value: string) => {
    setCheckedValue(value);
    props.onItemSelected(value);
  };

  return (
    <RadioButtonFilter
      {...props}
      radioItems={languages}
      title={t('general.languages.title')}
      selectedValue={checkedValue}
      onItemSelected={internalOnItemSelected}
    />
  );
};

export const RoleRadioButtonFilter: React.FC<Omit<Props, 'title' | 'radioItems'>> = (props) => {
  const { t } = useTranslation();
  const [checkedValue, setCheckedValue] = useState(RadioButtonFilterDefaultValue);

  const roles = [{ title: t('general.form.all'), value: 'all' }].concat(
    UserRoles.map((role) => ({ title: t(`general.roles.names.${role}`), value: role })),
  );

  const internalOnItemSelected = (value: string) => {
    setCheckedValue(value);
    props.onItemSelected(value);
  };

  return (
    <RadioButtonFilter
      {...props}
      radioItems={roles}
      title={t('general.roles.title')}
      selectedValue={checkedValue}
      onItemSelected={internalOnItemSelected}
    />
  );
};
