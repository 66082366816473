import Bluebird from 'bluebird';
import md5 from 'crypto-js/md5';
import { collection, doc, DocumentData, DocumentReference, runTransaction, Timestamp } from 'firebase/firestore';
import { callables } from '../../firebase/functions';
import { firestore } from '../../firebase/init';
import { companyGetRef } from '../../shared/company';
import { handbookCommentGetRefById, handbookGetRef, handbookGetRefById } from '../../shared/handbooks';
import { locationGetRef } from '../../shared/location';
import { productGetRef } from '../../shared/products';
import { userGetRef, userGetRefById } from '../../shared/user';
import { Company, CustomerForm, Handbook, HandbookFormData, WPKFormComment, WPKUser } from '../../types/firebase';
import { WPKChangeHistoryEntry } from '../../types/firebase/collections/changeHistory';
import { HandbookStatus } from '../../types/firebase/collections/handbookTypes';
import collections from '../../types/shared/collections';

const cleanHandbook = (handbookData: HandbookFormData) => {
  const { products, consultant, location, certifier, ...handbook } = handbookData;
  return { handbook, rest: { products, consultant, location, certifier } };
};

export const createHandbook = (company: Company, handbookData: HandbookFormData, language: string) => {
  const {
    handbook,
    rest: { products, consultant, location, certifier },
  } = cleanHandbook(handbookData);

  if (products?.length === 0 || !location) {
    return Bluebird.reject('Nothing to create, missing data');
  }

  return callables.handbooks.create({
    handbookData: handbook,
    companyRefPath: companyGetRef(company).path,
    locationRefPath: locationGetRef(company, location).path,
    productPaths: products?.map((p) => productGetRef(p).path) ?? [],
    consultantRefPath: consultant ? userGetRef(consultant).path : undefined,
    certifierRefPath: certifier ? userGetRef(certifier).path : undefined,
    language,
  });
};

export const updateHandbook = (company: Company, handbook: Handbook, handbookData: HandbookFormData) => {
  return Bluebird.resolve(
    runTransaction(firestore, async (t) => {
      if (!handbookData.location) throw new Error('Location is not set');
      const handbookRef = handbookGetRef(company, handbook);

      // pick only allowed changes
      const updates: Pick<
        HandbookFormData,
        'locationRef' | 'consultantRef' | 'updatedAt' | 'certifierRef' | 'instructorRef' | 'name' | 'productRefs'
      > = {
        consultantRef: handbookData.consultant ? userGetRef(handbookData.consultant) : null,
        certifierRef: handbookData.certifier ? userGetRef(handbookData.certifier) : null,
        // ATTENTION: instructor is tied to the ceritifier, removing the ceritifier removes the instructor too
        instructorRef: handbookData.certifier && handbookData.instructorRef ? handbookData.instructorRef : null,
        locationRef: locationGetRef(company, handbookData.location),
        updatedAt: Timestamp.now(),
        name: handbookData.name,
        productRefs: handbookData.products?.map((p) => productGetRef(p)),
      };

      t.set(handbookRef, updates, { merge: true });
    }),
  );
};

export const updateHandbookCertification = (company: Company, handbook: Handbook, data: Pick<Handbook, 'certification'>) => {
  return Bluebird.resolve(
    runTransaction(firestore, async (t) => {
      const handbookRef = handbookGetRef(company, handbook);

      // pick only allowed changes
      const updates: Pick<HandbookFormData, 'certification' | 'updatedAt' | 'status'> = {
        ...data,
        status: data.certification ? HandbookStatus.CertApproved : HandbookStatus.CertRejected,
        updatedAt: Timestamp.now(),
      };

      t.set(handbookRef, updates, { merge: true });
    }),
  );
};

export const deleteHandbook = (company: Company, handbook: Handbook) => {
  const handbookRef = handbookGetRef(company, handbook);
  return callables.handbooks.delete({ handbookRefPath: handbookRef.path });
};

export const publishHandbook = (company: Company, handbook: Handbook) => {
  const handbookRef = handbookGetRef(company, handbook);
  return callables.handbooks.publish({ handbookRefPath: handbookRef.path });
};

export const deactivateHandbook = (company: Company, handbook: Handbook) => {
  const handbookRef = handbookGetRef(company, handbook);
  return callables.handbooks.deactivate({ handbookRefPath: handbookRef.path });
};

export const updateCustomerForm = (changedForm: Partial<CustomerForm>, formRef?: DocumentReference<DocumentData>) => {
  return Bluebird.resolve(
    runTransaction(firestore, async (t) => {
      if (!formRef) return;
      t.set(formRef, { ...changedForm, updatedAt: Timestamp.now() }, { merge: true });
    }),
  );
};

export const copyHandbook = (company: Company, handbook: Handbook, language: string) => {
  if (handbook.status === 'DRAFT') {
    return Bluebird.reject('Cannot copy a draft version');
  }

  const handbookRef = handbookGetRef(company, handbook);
  const companyRef = companyGetRef(company);

  return callables.handbooks.copy({
    companyRefPath: companyRef.path,
    handbookRefPath: handbookRef.path,
    language,
  });
};

export const createHandbookComment = (data: {
  companyId: string;
  handbookId: string;
  formKey: string;
  formTitle: string;
  sectionId: string;
  sectionTitle: string;
  sectionIndex: number;
  userId: string;
  author: string;
  text: string;
}) => {
  const { companyId, handbookId, userId, formKey, sectionId, text, author, sectionTitle, formTitle, sectionIndex } = data;

  return Bluebird.resolve(
    runTransaction(firestore, async (t) => {
      const handbookRef = handbookGetRefById(companyId, handbookId);
      const creatorRef = userGetRefById(userId);
      const newCommentRef = doc(collection(firestore, handbookRef.path, collections.comments)); // TODO test me

      const userDoc = (await t.get(creatorRef)).data() as WPKUser;
      const md5String = md5(userDoc.email).toString();

      const newComment: WPKFormComment = {
        key: newCommentRef.id,
        private: false,
        creatorRef,
        formKey,
        sectionId,
        text,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
        searchText: [],
        author,
        authorRole: userDoc.role,
        avatarUrl: `https://www.gravatar.com/avatar/${md5String}`,
        sectionTitle,
        formTitle,
        handbookId,
        companyId,
        sectionIndex,
        isDone: false,
      };

      t.set(newCommentRef, newComment);
    }),
  );
};

export const createHandbookChangeHistoryEntry = (data: {
  companyId: string;
  handbookId: string;
  formKey: string;
  formId: string;
  formTitle: string;
  userId: string;
  author: string;
  text: string;
}) => {
  const { companyId, handbookId, userId, formKey, text, author, formTitle, formId } = data;

  return Bluebird.resolve(
    runTransaction(firestore, async (t) => {
      const handbookRef = handbookGetRefById(companyId, handbookId);
      const creatorRef = userGetRefById(userId);
      const newChangeHistoryEntryRef = doc(collection(firestore, handbookRef.path, collections.changeHistory));

      const userDoc = (await t.get(creatorRef)).data() as WPKUser;
      const md5String = md5(userDoc.email).toString();

      const newChangeHistoryEntry: WPKChangeHistoryEntry = {
        key: newChangeHistoryEntryRef.id,
        creatorRef,
        formKey,
        formId,
        text,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
        searchText: [],
        author,
        authorRole: userDoc.role,
        avatarUrl: `https://www.gravatar.com/avatar/${md5String}`,
        formTitle,
        handbookId,
        companyId,
      };

      t.set(newChangeHistoryEntryRef, newChangeHistoryEntry);
    }),
  );
};

export const deleteHandbookComment = (companyId: string, handbookId: string, commentId: string) => {
  return Bluebird.resolve(
    runTransaction(firestore, async (t) => {
      const handbookCommentRef = handbookCommentGetRefById(companyId, handbookId, commentId);
      t.delete(handbookCommentRef);
    }),
  );
};

export const updateHandbookComment = (comment: WPKFormComment, newText: string) => {
  return Bluebird.resolve(
    runTransaction(firestore, async (t) => {
      const handbookComentRef = handbookCommentGetRefById(comment.companyId, comment.handbookId, comment.key);
      t.set(
        handbookComentRef,
        {
          text: newText,
          updatedAt: Timestamp.now(),
        } as Partial<WPKFormComment>,
        { merge: true },
      );
    }),
  );
};

export const markHandbookCommentsAsDone = (companyId: string, handbookId: string, comments: WPKFormComment[]) => {
  return Bluebird.resolve(
    runTransaction(firestore, async (t) => {
      comments.forEach((c) => {
        const handbookCommentRef = handbookCommentGetRefById(companyId, handbookId, c.key);
        t.set(handbookCommentRef, { isDone: true } as Partial<WPKFormComment>, { merge: true });
      });
    }),
  );
};

export const setHandbookInstructor = (firebaseUser: WPKUser, company: Company, handbook: Handbook) => {
  const instructorRef = userGetRef(firebaseUser);
  const handbookRef = handbookGetRef(company, handbook);
  const companyRef = companyGetRef(company);
  return callables.handbooks.setInstructor({
    instructorRefPath: instructorRef.path,
    handbookRefPath: handbookRef.path,
    companyRefPath: companyRef.path,
  });
};

export const removeHandbookInstructor = (company: Company, handbook: Handbook) => {
  const handbookRef = handbookGetRef(company, handbook);
  const companyRef = companyGetRef(company);
  return callables.handbooks.removeInstructor({ handbookRefPath: handbookRef.path, companyRefPath: companyRef.path });
};
