import React from 'react';
import { Route } from 'react-router-dom';
import { authentication } from '../../../routing/routes';
import Callbacks from './callbacks';
import Auth, { AuthFormMode } from './form';
import VerifyEmailMessage from './verifyEmailMessage';
import VerifyParams from './verifyQueryParams';

const publicRoutes = () => [
  <Route exact path={authentication.login()} key={authentication.login()} render={() => <Auth formMode={AuthFormMode.login} />} />,
  <Route exact path={authentication.register()} key={authentication.register()} render={() => <Auth formMode={AuthFormMode.register} />} />,
  <Route
    exact
    path={authentication.forgotPassword()}
    key={authentication.forgotPassword()}
    render={() => <Auth formMode={AuthFormMode.forgotPassword} />}
  />,
  <Route
    exact
    path={authentication.verifyCallback()}
    key={authentication.verifyCallback()}
    render={() => <VerifyParams params={['mode', 'oobCode']} component={Callbacks} />}
  />,
  <Route exact path={authentication.verifyEmail()} key={authentication.verifyEmail()} component={VerifyEmailMessage} />,
];

export default publicRoutes;
