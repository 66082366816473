import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';

export const TooltipSubstringText: React.FC<{ text?: string | null; textLength?: number; iconOnly?: boolean }> = ({
  text,
  textLength = 20,
  iconOnly,
}) => {
  if (!text) return null;

  const isCutOff = text && text.length > textLength;

  if (!isCutOff && !iconOnly) return <span>{text ?? ''}</span>;

  return (
    <Tooltip title={text}>
      {!iconOnly ? (
        <span>
          {text?.substring(0, textLength)}
          {isCutOff && '...'}
        </span>
      ) : (
        <InfoCircleOutlined style={{ fontSize: 16 }} />
      )}
    </Tooltip>
  );
};
