import { Button, Form } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { elementSpacing } from '../../styles/spacings';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  cancelUrl?: string;
  saveAndNew?: () => void;
  hideSaveAndNew?: boolean;
  isSaving?: boolean;
}

const StyledSaveAndNewButton = styled(Button)`
  margin-left: ${elementSpacing};
`;

const StyledContainer = styled.div`
  margin-top: 64px;
`;

const FormActions: React.FC<Props> = (props) => {
  const { cancelUrl, saveAndNew, hideSaveAndNew, isSaving, ...htmlProps } = props;
  const { t } = useTranslation();

  return (
    <StyledContainer {...htmlProps}>
      <Form.Item>
        <Button size="large" type="primary" htmlType="submit" loading={isSaving}>
          {t('general.form.save')}
        </Button>

        {!hideSaveAndNew && (
          <StyledSaveAndNewButton size="large" onClick={saveAndNew} type="default" loading={isSaving}>
            {t('general.form.saveAndNew')}
          </StyledSaveAndNewButton>
        )}
      </Form.Item>
      {cancelUrl && <Link to={cancelUrl}>{t('general.form.cancel')}</Link>}
    </StyledContainer>
  );
};

export default FormActions;
