import { Button, Col, Form, Input, notification, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { TFunction } from 'i18next';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import MainLayout, { StyledChildrenContainer } from '../../../components/layouts/main';
import LoadingIndicator from '../../../components/molecules/loadingIndicator';
import WillaxPageHeader from '../../../components/molecules/pageHeader';
import StatusRow from '../../../components/molecules/statusRow';
import { CommentsContext } from '../../../components/organisms/commentsEditor';
import { firestore } from '../../../firebase/init';
import { app } from '../../../routing/routes';
import {
  createHandbookChangeHistoryEntry,
  createHandbookComment,
  deleteHandbookComment,
  updateCustomerForm,
  updateHandbookComment,
} from '../../../services/firebase/handbooks';
import { companyGetRefById } from '../../../shared/company';
import { UserContext } from '../../../shared/contexts';
import { noOp } from '../../../shared/functions';
import { handbookFormGetRefById, handbookGetRefById } from '../../../shared/handbooks';
import { useNotifications } from '../../../shared/notifications';
import { displayName, mandateIsAllowedToWrite, userGetRef } from '../../../shared/user';
import { Blueprint, Company, CustomerForm, Handbook, WPKFormComment } from '../../../types/firebase';
import { HandbookStatus } from '../../../types/firebase/collections/handbookTypes';
import { blueprintIdFromBlueprint } from '../../../types/shared/blueprints';
import collections from '../../../types/shared/collections';
import { BlueprintEditContent } from '../../admin/blueprints';
import { collectionGroup, onSnapshot, query, where, orderBy, collection } from 'firebase/firestore';

interface URLParams {
  handbookId?: string;
  companyId?: string;
  formId?: string;
}

interface Props {
  isMandate?: boolean;
}

const HandbookFormEditorChangelog: React.FC<{ onSave: (text: string) => Promise<void> }> = ({ onSave }) => {
  const { t } = useTranslation();
  return (
    <Form onFinish={(values) => onSave(values['change'] ?? '')}>
      <Form.Item label="" name="change" rules={[{ required: true, message: t('general.form.required') }]}>
        <TextArea name="change" autoSize showCount maxLength={500} placeholder={t('changeHistory.inputPlaceholder')} />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          {t('general.form.save')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export const HandbookFormEditor: React.FC<Props> = (props) => {
  const { isMandate } = props;
  const { handbookId, companyId, formId } = useParams<URLParams>();
  const { t } = useTranslation();
  const { notifySaveSuccess, notifyGeneralError } = useNotifications(t('admin.blueprints.single'));
  const {
    notifySaveSuccess: notifySaveComment,
    notifyGeneralError: notifyErrorComment,
    notifyDeleteConfirm: notifyDeleteConfirmComment,
    notifyDeletedSuccess: notifyDeleteSuccessComment,
  } = useNotifications<string>(t('commentsEditor.single'));

  const { notifySaveSuccess: notifySaveChangeHistory, notifyGeneralError: notifyErrorChangeHistory } = useNotifications<string>(
    t('changeHistory.single'),
  );

  const { firestoreUser } = useContext(UserContext);

  const history = useHistory();

  const [company, setCompany] = useState<Company | undefined>(undefined);
  const [handbook, setHandbook] = useState<Handbook | undefined>(undefined);
  const [form, setForm] = useState<CustomerForm | undefined>(undefined);
  const [comments, setComments] = useState<WPKFormComment[] | undefined>(undefined);
  const [showChangeHistoryNotification, setShowChangeHistoryNotification] = useState(false);

  const readonly = handbook?.status !== 'DRAFT';
  const author = displayName(firestoreUser) ?? '';

  useEffect(() => {
    if (!handbookId || !companyId || !formId || !firestoreUser) return;
    const navigateBackToHandbook = () => history.push(app.handbooks.edit(false, { handbookId, companyId }));

    const companyRef = companyGetRefById(companyId);
    const handbookRef = handbookGetRefById(companyId, handbookId);
    const formRef = handbookFormGetRefById(companyId, handbookId, formId);

    const clearFunctions = [
      onSnapshot(companyRef, (snapshot) => setCompany(snapshot.data() as Company)),

      isMandate
        ? onSnapshot(
            query(
              collectionGroup(firestore, collections.handbooks),
              where('key', '==', handbookId),
              where(`${firestoreUser.role}Ref`, '==', userGetRef(firestoreUser)),
            ),
            (snapshot) => {
              if (snapshot.size === 1) {
                const handbook = snapshot.docs[0].data() as Handbook;
                if (handbook.status === HandbookStatus.Deactivated) return navigateBackToHandbook();
                setHandbook(handbook);
              } else {
                setHandbook(undefined);
                history.push(app.mandate.list());
              }
            },
          )
        : onSnapshot(handbookRef, (snapshot) => {
            const handbook = snapshot.data() as Handbook;
            if (handbook.status === HandbookStatus.Deactivated) return navigateBackToHandbook();
            setHandbook(handbook);
          }),

      onSnapshot(formRef, (snapshot) => setForm(snapshot.data() as CustomerForm)),
    ];

    return () => clearFunctions.forEach((f) => f());
  }, [handbookId, companyId, formId, setHandbook, setCompany, setForm, firestoreUser, isMandate, history]);

  useEffect(() => {
    if (!handbookId || !companyId || !form) return;

    const handbookRef = handbookGetRefById(companyId, handbookId);
    const commentsQuery = query(
      collection(firestore, handbookRef.path, collections.comments),
      where('formKey', '==', form.key),
      orderBy('createdAt'),
    );

    const clearFunctions = [onSnapshot(commentsQuery, (snapshot) => setComments(snapshot.docs.map((d) => d.data() as WPKFormComment)))];

    return () => clearFunctions.forEach((f) => f());
  }, [handbookId, companyId, form, setComments]);

  useEffect(() => {
    const { hash } = window.location;
    if (!form || !comments || !hash) return;

    // as soon as form and comments are set and loaded try to scroll once
    requestAnimationFrame(() => {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      window.history.replaceState(null, '', ' '); // remove the hash now, don't jump back to it after an update
    });
  }, [form, comments]);

  const onSaveChangeHistoryEntryCallback = useCallback(
    (text: string) => {
      if (!handbookId || !companyId || !formId || !form || !firestoreUser) return Promise.resolve();

      return createHandbookChangeHistoryEntry({
        companyId,
        handbookId,
        formKey: formId,
        userId: firestoreUser.key,
        author,
        formId: blueprintIdFromBlueprint(form),
        text,
        formTitle: form.title,
      })
        .then(notifySaveChangeHistory)
        .then(() => setShowChangeHistoryNotification(false))
        .catch((e) => {
          console.error(e);
          notifyErrorChangeHistory();
        });
    },
    [
      companyId,
      firestoreUser,
      form,
      formId,
      handbookId,
      author,
      notifySaveChangeHistory,
      notifyErrorChangeHistory,
      setShowChangeHistoryNotification,
    ],
  );

  useEffect(() => {
    if (showChangeHistoryNotification) {
      notification.open({
        key: 'changeHistory',
        duration: 0,
        message: t('changeHistory.inputTitle'),
        placement: 'bottomRight',
        description: <HandbookFormEditorChangelog onSave={onSaveChangeHistoryEntryCallback} />,
        style: {
          width: 450,
        },
      });
    } else {
      notification.close('changeHistory');
    }
  }, [showChangeHistoryNotification, t, onSaveChangeHistoryEntryCallback]);

  useEffect(() => {
    return () => notification.close('changeHistory');
  }, []);

  const onSaveCallback = (newFormData: Blueprint) => {
    if (!handbookId || !companyId || !formId) return Promise.resolve();

    if (!showChangeHistoryNotification) {
      setShowChangeHistoryNotification(true);
    }

    const formRef = handbookFormGetRefById(companyId, handbookId, formId);

    return updateCustomerForm(newFormData, formRef)
      .then(notifySaveSuccess)
      .catch((e) => {
        console.error(e);
        notifyGeneralError();
      });
  };

  const onSaveCommentCallback = async (sectionId: string, sectionIndex: number, sectionTitle: string, text: string) => {
    if (!handbookId || !companyId || !formId || !form || !firestoreUser) return false;

    return createHandbookComment({
      companyId,
      handbookId,
      formKey: formId,
      sectionId,
      userId: firestoreUser.key,
      author,
      text,
      formTitle: form.title,
      sectionTitle,
      sectionIndex,
    })
      .then(notifySaveComment)
      .then(() => true)
      .catch((e) => {
        console.error(e);
        notifyErrorComment();
        return false;
      });
  };

  const onDeleteComment = (commentId: string) => {
    if (!handbookId || !companyId) return;
    deleteHandbookComment(companyId, handbookId, commentId)
      .then(notifyDeleteSuccessComment)
      .catch((e) => {
        console.error(e);
        notifyErrorComment();
      });
  };

  const onDeleteCommentCallback = (_sectionId: string, commentId: string) => notifyDeleteConfirmComment(commentId, onDeleteComment, noOp);
  const onUpdateCommentCallback = (comment: WPKFormComment, newText: string) =>
    updateHandbookComment(comment, newText)
      .then(notifySaveComment)
      .then(() => true)
      .catch((e) => {
        console.error(e);
        notifyErrorComment();
        return false;
      });

  const routes = (() => {
    if (isMandate) {
      return {
        list: app.mandate.list,
        edit: app.mandate.edit,
      };
    }

    return {
      list: app.handbooks.list,
      edit: app.handbooks.edit,
    };
  })();

  const openPrint = () => window.open(app.handbooks.print(false, { companyId, handbookId, formId: form?.key }), '_blank');

  if (!firestoreUser) return null;
  const isAllowedToWrite = !isMandate || mandateIsAllowedToWrite(isMandate, firestoreUser);

  return (
    <MainLayout
      pageHeader={
        <WillaxPageHeader
          title={
            <StatusRow
              title={form?.title}
              statusToCheck={HandbookStatus.Draft}
              date={form?.updatedAt.toDate()}
              millis={form?.updatedAt.toMillis()}
              status={handbook?.status}
              translationPrefix="private.handbooks.status"
            />
          }
          extra={
            !!handbook &&
            !!form && (
              <Button size="large" type="ghost" target="_blank" onClick={openPrint} rel="noopener noreferrer">
                {t('private.handbooks.print')}
              </Button>
            )
          }
          routes={[
            { path: routes.list(), breadcrumbName: t('private.customer.header.handbooks') },
            { path: routes.edit(false, { handbookId, companyId }), breadcrumbName: handbook?.name ?? ' ' },
            { path: '', breadcrumbName: form?.title ?? ' ' },
          ]}
        />
      }
    >
      <StyledChildrenContainer>
        {(!company || !handbook || !form || !comments) && <LoadingIndicator />}

        {!!company && !!handbook && !!form && !!comments && (
          <CommentsContext.Provider
            value={{
              comments,
              onDeleteComment: onDeleteCommentCallback,
              onSaveComment: onSaveCommentCallback,
              onUpdateComment: onUpdateCommentCallback,
            }}
          >
            <BlueprintEditContent
              blueprint={form}
              isAdmin={false}
              readonly={readonly}
              onSave={onSaveCallback}
              isMandate={isMandate}
              handbook={handbook}
              company={company}
              isAllowedToWrite={isAllowedToWrite}
            />
          </CommentsContext.Provider>
        )}
      </StyledChildrenContainer>
    </MainLayout>
  );
};
