import firebase from 'firebase/app';
import { Timestamp } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import {
  BlueprintContent,
  BlueprintContentSection,
  BlueprintContentSectionBaseElement,
  ElementId,
  FormEditorInputType,
  FormEditorInputValue,
  SectionId,
} from '../../../types/firebase';

export type CollapsedSections = { [key: string]: boolean | undefined };

export interface ReducerState {
  content: BlueprintContent;
  collapsedSections: CollapsedSections;
  hasChanges: boolean;
}

export type BlueprintFormEditorActions =
  | { type: 'createSection' }
  | { type: 'removeSection'; payload: BlueprintContentSection }
  | { type: 'copySection'; payload: BlueprintContentSection }
  | { type: 'updateSection'; payload: BlueprintContentSection }
  | { type: 'moveSection'; payload: BlueprintContentSection[] }
  | { type: 'toggleCollapseSection'; payload: BlueprintContentSection }
  | { type: 'addSectionElement'; payload: { type: FormEditorInputType; sectionId: SectionId } }
  | { type: 'sectionElementEditableChanged'; payload: { sectionId: SectionId; elementId: ElementId; editable: boolean } }
  | {
      type: 'sectionElementValueChanged';
      payload: { sectionId: SectionId; elementId: ElementId; value: unknown; isAdminEditing?: boolean };
    }
  | { type: 'removeSectionElement'; payload: { sectionId: SectionId; elementId: ElementId } }
  | { type: 'formSaved' };

export const reducer = (state: ReducerState, action: BlueprintFormEditorActions): ReducerState => {
  switch (action.type) {
    case 'createSection':
      return adjustUpdatedSections({
        ...state,
        content: {
          ...state.content,
          sections: [...state.content.sections, { id: uuidv4(), title: '', elements: [] }],
        },
        hasChanges: true,
      });
    case 'removeSection': {
      const newState = {
        ...state,
        content: { ...state.content, sections: state.content.sections.filter((s) => s.id !== action.payload.id) },
      };

      const { newAdminValues, newCustomerValues } = removeAllValuesForSection(state, action.payload.id);
      newState.content.adminValues = newAdminValues;
      newState.content.customerValues = newCustomerValues;

      return adjustUpdatedSections(newState);
    }
    case 'copySection': {
      const indexOfCurrentSection = state.content.sections.indexOf(action.payload);
      const newSection = { ...action.payload, id: uuidv4() };
      const copiedSections = [...state.content.sections];
      copiedSections.splice(indexOfCurrentSection + 1, 0, newSection);
      return adjustUpdatedSections({ ...state, content: { ...state.content, sections: copiedSections } });
    }
    case 'updateSection': {
      const indexOfCurrentSection = state.content.sections.findIndex((s) => s.id === action.payload.id);
      if (indexOfCurrentSection === -1) return state;

      const copiedSections = [...state.content.sections];
      copiedSections[indexOfCurrentSection] = action.payload;
      return adjustUpdatedSections({ ...state, content: { ...state.content, sections: copiedSections } });
    }
    case 'moveSection': {
      const sections = action.payload;
      return adjustUpdatedSections({ ...state, content: { ...state.content, sections } });
    }
    case 'addSectionElement': {
      const { section, sectionIndex } = getSection(state, action.payload.sectionId);
      if (!section) return state;

      const newElement: BlueprintContentSectionBaseElement = {
        id: uuidv4(),
        updatedAt: Timestamp.now(),
        isUserEditable: false,
        type: action.payload.type,
      };

      const newState = { ...state };
      newState.content.sections[sectionIndex].elements = [...newState.content.sections[sectionIndex].elements, newElement];

      return adjustUpdatedSections(newState);
    }
    case 'removeSectionElement': {
      const { section, sectionIndex } = getSection(state, action.payload.sectionId);
      if (!section) return state;

      const newState = { ...state };
      newState.content.sections[sectionIndex] = { ...section, elements: section.elements.filter((e) => e.id !== action.payload.elementId) };

      const { newAdminValues } = removeAllValuesForElement(state, action.payload.elementId);
      newState.content.adminValues = newAdminValues;

      return adjustUpdatedSections(newState);
    }
    case 'sectionElementEditableChanged': {
      const { elementIndex, sectionIndex, element } = getElement(state, action.payload.sectionId, action.payload.elementId);
      if (!element) return state;

      const newState = { ...state };
      newState.content.sections[sectionIndex].elements[elementIndex] = { ...element, isUserEditable: action.payload.editable };
      return adjustUpdatedSections(newState);
    }
    case 'sectionElementValueChanged': {
      const { sectionId, elementId, value, isAdminEditing } = action.payload;
      const existingValue = getElementValue(state, elementId, isAdminEditing);
      const updatedValue: FormEditorInputValue = {
        ...(existingValue ?? { id: uuidv4(), sectionId }),
        value,
        updatedAt: Timestamp.now(),
      };

      const keyToUpdate = isAdminEditing === true ? 'adminValues' : 'customerValues';
      const newState = {
        ...state,
        content: { ...state.content, [keyToUpdate]: { ...state.content[keyToUpdate], [elementId]: updatedValue } },
      };

      return adjustUpdatedSections(newState);
    }
    case 'toggleCollapseSection': {
      const isCollapsed = state.collapsedSections[action.payload.id] === true;
      const newCollapsedSections = { ...state.collapsedSections, [action.payload.id]: !isCollapsed };
      return { ...state, collapsedSections: newCollapsedSections };
    }
    case 'formSaved': {
      return { ...state, hasChanges: false };
    }
  }
};

const removeAllValuesForElement = (state: ReducerState, elementId: ElementId) => {
  const newAdminValues = Object.keys(state.content.adminValues).reduce((acc, key) => {
    if (key === elementId) return acc;
    const value = state.content.adminValues[key];
    return { ...acc, [key]: value };
  }, {});

  const newCustomerValues = Object.keys(state.content.customerValues).reduce((acc, key) => {
    if (key === elementId) return acc;
    const value = state.content.adminValues[key];
    return { ...acc, [key]: value };
  }, {});

  return { newAdminValues, newCustomerValues };
};

const removeAllValuesForSection = (state: ReducerState, sectionId: SectionId) => {
  const newAdminValues = Object.keys(state.content.adminValues).reduce((acc, key) => {
    const value = state.content.adminValues[key];
    if (value?.sectionId === sectionId) return acc;
    return { ...acc, [key]: value };
  }, {});

  const newCustomerValues = Object.keys(state.content.customerValues).reduce((acc, key) => {
    const value = state.content.adminValues[key];
    if (value?.sectionId === sectionId) return acc;
    return { ...acc, [key]: value };
  }, {});

  return { newAdminValues, newCustomerValues };
};

const getSection = (state: ReducerState, sectionId: SectionId): { section?: BlueprintContentSection; sectionIndex: number } => {
  const sectionIndex = state.content.sections.findIndex((s) => s.id === sectionId);
  if (sectionIndex === -1) return { section: undefined, sectionIndex: -1 };
  return { section: state.content.sections[sectionIndex], sectionIndex };
};

const getElement = (
  state: ReducerState,
  sectionId: SectionId,
  elementId: ElementId,
): {
  section?: BlueprintContentSection;
  element?: BlueprintContentSectionBaseElement;
  sectionIndex: number;
  elementIndex: number;
} => {
  const notFound = { sectionIndex: -1, elementIndex: -1 };

  const { section, sectionIndex } = getSection(state, sectionId);
  if (!section) return notFound;

  const elementIndex = section.elements.findIndex((e) => e.id === elementId);
  if (elementIndex === -1) return notFound;

  return { section, element: section.elements[elementIndex], sectionIndex, elementIndex };
};

const getElementValue = (state: ReducerState, elementId: ElementId, isAdminEditing?: boolean): FormEditorInputValue | undefined => {
  return isAdminEditing === true ? state.content.adminValues[elementId] : state.content.customerValues[elementId];
};

const adjustUpdatedSections = (reducerState: ReducerState): ReducerState => ({
  ...reducerState,
  content: { ...reducerState.content, updatedAt: Timestamp.now() },
  hasChanges: true,
});
