import { initializeApp } from 'firebase/app';
import { initializeAnalytics, isSupported } from 'firebase/analytics';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { getAuth, connectAuthEmulator } from '@firebase/auth';
import { getStorage, connectStorageEmulator } from '@firebase/storage';
import { getFunctions, connectFunctionsEmulator } from '@firebase/functions';

if (!process.env.REACT_APP_FIREBASE_API_KEY) {
  throw new Error('Make sure to add a .env file during development or configure the env variables during the build');
}

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.NODE_ENV !== 'development' ? process.env.REACT_APP_FIREBASE_MEASUREMENTID : undefined,
};
// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

const initFirebaseAnalytics = async () => {
  if (await isSupported()) {
    console.info('Analytics is supported therefore activating it.');
    initializeAnalytics(firebaseApp);
  }
};

export const firestore = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);
export const auth = getAuth(firebaseApp);
export const functions = getFunctions(firebaseApp, 'europe-west6');
export const analytics = process.env.NODE_ENV !== 'development' ? initFirebaseAnalytics() : undefined;

// When developing functions locally it might be easier to test against a local database and functions. Uncomment the lines below to do that

if (window.location.hostname === 'localhost') {
  console.info('Using local emulator');
  connectFirestoreEmulator(firestore, 'localhost', 8080);
  connectFunctionsEmulator(functions, 'localhost', 5001);
  connectStorageEmulator(storage, 'localhost', 9199);
  connectAuthEmulator(auth, 'http://localhost:9090');
}
