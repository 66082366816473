import styled from 'styled-components';
import colors from '../../styles/colors';
import { layoutSpacings } from '../../styles/spacings';
import { StyledChildrenContainer } from '../layouts/main';

export const StyledTabContainer = styled.div`
  & > .ant-tabs-card .ant-tabs-content {
    margin-top: -16px;
  }
  & > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    background: #fff;
    padding: 16px;
  }
  & > .ant-tabs-card > .ant-tabs-nav::before {
    display: none;
  }
  & > .ant-tabs-card .ant-tabs-tab {
    border-color: transparent;
    background: transparent;
  }
  & > .ant-tabs-card .ant-tabs-tab-active {
    border-color: #fff;
    background: #fff;
  }
`;

export const StyledTabChildContainer = styled(StyledChildrenContainer)`
  background-color: ${colors.white};
  ${layoutSpacings.padding}
`;
