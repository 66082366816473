import { MoreOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import Title from 'antd/lib/typography/Title';
import { TFunction } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StyledListHeader from '../../../components/atoms/listPageHeader';
import { TooltipSubstringText } from '../../../components/atoms/tooltipText';
import MainLayout, { StyledChildrenContainer } from '../../../components/layouts/main';
import EmptyMessage from '../../../components/molecules/emptyMessage';
import LoadingIndicator from '../../../components/molecules/loadingIndicator';
import { RadioButtonFilterDefaultValue, RoleRadioButtonFilter } from '../../../components/molecules/radioButtonFilter';
import SearchBar from '../../../components/molecules/searchBar';
import { firestore } from '../../../firebase/init';
import { updateUser } from '../../../services/firebase/user';
import { UnsubscribeFunction, WPKUser } from '../../../types/firebase';
import { WpkRole } from '../../../types/global';
import collections from '../../../types/shared/collections';
import { where, onSnapshot, query, collection, orderBy } from 'firebase/firestore';

let unsubscribeUsers: UnsubscribeFunction = null;

const UserList: React.FC<unknown> = (_props) => {
  const { t } = useTranslation();

  const [users, setUsers] = useState<WPKUser[] | undefined>(undefined);
  const [selectedRole, setSelectedRole] = useState(RadioButtonFilterDefaultValue);
  const [searchTerm, setSearchTerm] = useState('');

  const searching = !!searchTerm || selectedRole !== RadioButtonFilterDefaultValue;

  useEffect(() => {
    const whereClauses = [];
    if (selectedRole !== RadioButtonFilterDefaultValue) {
      whereClauses.push(where('role', '==', selectedRole));
    }
    if (searchTerm && searchTerm.length > 0) {
      whereClauses.push(where('searchText', 'array-contains-any', searchTerm.toLowerCase().split(' ')));
    }

    unsubscribeUsers && unsubscribeUsers();
    unsubscribeUsers = onSnapshot(
      query(collection(firestore, collections.users), ...whereClauses, orderBy('companyName'), orderBy('lastName'), orderBy('firstName')),
      (snapshot) => {
        const docsData = snapshot.docs.map((doc) => doc.data() as WPKUser);
        setUsers(docsData);
      },
    );

    return function cleanup() {
      unsubscribeUsers && unsubscribeUsers();
    };
  }, [selectedRole, searchTerm]);

  const onChangeUserActive = (user: WPKUser, active: boolean) => {
    user.active = active;
    void updateUser(user);
  };

  const onChangeUserRole = (user: WPKUser, role: WpkRole) => {
    user.role = role;
    void updateUser(user);
  };

  const columns = createColumns(t, onChangeUserActive, onChangeUserRole);

  return (
    <MainLayout
      pageHeader={
        <StyledListHeader>
          <Title level={4}>{t('admin.header.users')}</Title>
          <SearchBar
            placeholderText={t('admin.users.list.placeholder')}
            onSearchInput={setSearchTerm}
            radioFilters={[<RoleRadioButtonFilter key="role" onItemSelected={setSelectedRole} />]}
          />
        </StyledListHeader>
      }
    >
      <StyledChildrenContainer>
        {typeof users === 'undefined' && <LoadingIndicator />}

        {searching && users && users.length === 0 && (
          <EmptyMessage
            title={t('general.notFound.title', { item: t('admin.users.single') })}
            description={t('general.notFound.description')}
            logo={<SearchOutlined />}
          />
        )}

        {users && users.length > 0 && (
          <Table
            pagination={false}
            dataSource={users}
            columns={columns}
            locale={{ emptyText: t('general.emptyList') }}
            scroll={{ x: true }}
          />
        )}
      </StyledChildrenContainer>
    </MainLayout>
  );
};

export default UserList;

const createColumns = (
  t: TFunction,
  changeUserActive: (user: WPKUser, active: boolean) => void,
  changeUserRole: (user: WPKUser, role: WpkRole) => void,
): ColumnsType<WPKUser> => [
  {
    title: t('admin.users.list.header.company'),
    dataIndex: 'company',
    key: 'company',
    render: (_text, user, _index) => <span key={user.key}>{user.companyName}</span>,
    fixed: true,
  },
  {
    title: t('admin.users.list.header.lastName'),
    dataIndex: 'lastName',
    key: 'lastName',
    fixed: true,
  },
  {
    title: t('admin.users.list.header.firstName'),
    dataIndex: 'firstName',
    key: 'firstName',
    fixed: true,
  },
  {
    title: t('admin.users.list.header.email'),
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Aktiv',
    dataIndex: 'active',
    key: 'active',
    render: (_text, user, _index) => (user.active ? <Tag color="green">Aktiv</Tag> : <Tag color="red">Deaktiviert</Tag>),
  },
  {
    title: t('admin.users.list.header.role'),
    key: 'role',
    dataIndex: 'role',
    render: (_text, user, _index) => (
      <Tag key={user.key} color={t(`admin.users.roleColors.${user.role}`)}>
        {t(`general.roles.names.${user.role}`)}
      </Tag>
    ),
  },
  {
    title: t('admin.documents.list.header.description'),
    key: 'description',
    render: (_text, document, _index) => <TooltipSubstringText text={document.description} />,
  },
  {
    title: t('admin.users.list.header.updatedAt'),
    dataIndex: 'lastLoginDate',
    key: 'lastLoginDate',
    render: (_text, user, _index) => (
      <span title={user.lastLoginDate?.toDate().toLocaleString()} key={user.key}>
        {user.lastLoginDate?.toDate().toLocaleDateString()}
      </span>
    ),
  },
  {
    title: '',
    key: 'actions',
    render: (_text, user, _index) => {
      if (user.role === WpkRole.Admin) return null;

      const menu = (
        <Menu>
          <Menu.Item>
            {user.active ? (
              <Button size="large" type="link" onClick={() => changeUserActive(user, false)}>
                {t('admin.users.list.contextMenu.disable')}
              </Button>
            ) : (
              <Button size="large" type="link" onClick={() => changeUserActive(user, true)}>
                {t('admin.users.list.contextMenu.activate')}
              </Button>
            )}
          </Menu.Item>
          {user.role !== WpkRole.Consultant && (
            <Menu.Item>
              <Button size="large" type="link" onClick={() => changeUserRole(user, WpkRole.Consultant)}>
                {t('admin.users.list.contextMenu.setAsConsultant')}
              </Button>
            </Menu.Item>
          )}
          {user.role !== WpkRole.Certifier && (
            <Menu.Item>
              <Button size="large" type="link" onClick={() => changeUserRole(user, WpkRole.Certifier)}>
                {t('admin.users.list.contextMenu.setAsCertifier')}
              </Button>
            </Menu.Item>
          )}
          {user.role !== WpkRole.Instructor && (
            <Menu.Item>
              <Button size="large" type="link" onClick={() => changeUserRole(user, WpkRole.Instructor)}>
                {t('admin.users.list.contextMenu.setAsInstructor')}
              </Button>
            </Menu.Item>
          )}
          {user.role !== WpkRole.Customer && (
            <Menu.Item>
              <Button size="large" type="link" onClick={() => changeUserRole(user, WpkRole.Customer)}>
                {t('admin.users.list.contextMenu.setAsCustomer')}
              </Button>
            </Menu.Item>
          )}
        </Menu>
      );
      return (
        <div style={{ width: 80 }}>
          <Dropdown overlay={menu} placement="bottomRight" trigger={['click']} disabled={false}>
            <Button type="ghost" shape="circle" icon={<MoreOutlined />} size="small" />
          </Dropdown>
        </div>
      );
    },
    align: 'right',
  },
];
