import React from 'react';
import * as Sentry from '@sentry/browser';
import 'react-app-polyfill/ie11';
import ReactDOM from 'react-dom';
import { auth, firestore } from './firebase/init';
import './i18n/i18n';
import App from './modules/app';
import * as serviceWorker from './serviceWorker';
import { UnsubscribeFunction } from './types/firebase';
import collections from './types/shared/collections';
import {  doc, onSnapshot } from 'firebase/firestore';
import { IdTokenResult } from '@firebase/auth';

let unsubscribeMetadata: UnsubscribeFunction = null;

if (process.env.REACT_APP_SENTRY_URL) {
  console.info('Activating Sentry.io');
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_URL, environment: process.env.REACT_APP_SENTRY_ENVIRONMENT });
}

auth.onAuthStateChanged(async (user) => {
  // Remove previous listener.
  if (!user && unsubscribeMetadata) {
    unsubscribeMetadata();
  }

  let idTokenResult: IdTokenResult | null = null;
  // On user login add new listener.
  if (user) {
    // Check if refresh is required.
    unsubscribeMetadata = onSnapshot(doc(firestore, collections.metaData, user.uid), () => {
      user.getIdToken(true).catch((e) => console.error(e));
    });
    idTokenResult = await user.getIdTokenResult();
  }

  const userInfo =
    !user || !idTokenResult
      ? undefined
      : {
          firebaseUser: user,
          idTokenResult,
        };

  const rootElement = document.getElementById('root');
  ReactDOM.render(<App userInfo={userInfo} />, rootElement);

  if (module.hot) {
    module.hot.accept('./modules/app', () => {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const NextApp = require('./modules/app').default;
      ReactDOM.render(<NextApp userInfo={userInfo} />, rootElement);
    });
  }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
