/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { PlusOutlined, RightOutlined, SearchOutlined, MoreOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Table, Tag, Dropdown, Menu, Modal, Select } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import Title from 'antd/lib/typography/Title';
import { History } from 'history';
import { TFunction } from 'i18next';
import { first, groupBy, sortBy } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import StyledListHeader from '../../../components/atoms/listPageHeader';
import MainLayout, { StyledChildrenContainer } from '../../../components/layouts/main';
import EmptyMessage from '../../../components/molecules/emptyMessage';
import { LanguageRadioButtonFilter, RadioButtonFilterDefaultValue } from '../../../components/molecules/radioButtonFilter';
import SearchBar from '../../../components/molecules/searchBar';
import { StyledTabChildContainer } from '../../../components/molecules/tabs';
import { firestore } from '../../../firebase/init';
import { app } from '../../../routing/routes';
import { removeHandbookInstructor, setHandbookInstructor } from '../../../services/firebase/handbooks';
import { ApplicationContext, UserContext } from '../../../shared/contexts';
import { displayName, userGetRef } from '../../../shared/user';
import { Company, Handbook, HandbookFormData, Product, WPKUser } from '../../../types/firebase';
import { WpkRole } from '../../../types/global';
import collections from '../../../types/shared/collections';
import { collectionGroup, where, query, orderBy, onSnapshot, collection, doc, getDoc } from 'firebase/firestore';

interface HandbookMandateListRow {
  company: Company;
  handbook?: HandbookFormData;
  key: string;
}

type WPKConsultant = WPKUser & { company: Company };
type ShowModalData = { show: boolean; company: Company | undefined; handbook: Handbook | undefined };

export const HandbookMandateList: React.FC<unknown> = (_props) => {
  const { t } = useTranslation();
  const [tableRows, setTableRows] = useState<HandbookMandateListRow[] | undefined>(undefined);

  const history = useHistory();
  const { firestoreUser } = useContext(UserContext);
  const { availableLanguages } = useContext(ApplicationContext);

  const [selectedLanguage, setSelectedLanguage] = useState(RadioButtonFilterDefaultValue);
  const [searchTerm, setSearchTerm] = useState('');
  const [showInstructorModal, setShowInstructorModal] = useState<ShowModalData>({ show: false, handbook: undefined, company: undefined });
  const [instructors, setInstructors] = useState<WPKConsultant[]>([]);
  const [selectedInstructorKey, setSelectedInstructorKey] = useState<string | undefined>(undefined);

  const searching = !!searchTerm || selectedLanguage !== RadioButtonFilterDefaultValue;

  useEffect(() => {
    if (!firestoreUser) return;

    const groupRef = collectionGroup(firestore, collections.handbooks);
    const whereClauses = [where(`${firestoreUser.role}Ref`, '==', userGetRef(firestoreUser))];

    if (selectedLanguage !== RadioButtonFilterDefaultValue) {
      whereClauses.push(where('language', '==', selectedLanguage));
    }
    if (searchTerm && searchTerm.length > 0) {
      whereClauses.push(where('searchText', 'array-contains-any', searchTerm.toLowerCase().split(' ')));
    }

    const cleanups = [
      onSnapshot(
        query(groupRef, ...whereClauses, orderBy('name'), orderBy('version.major'), orderBy('version.minor')),
        async (snapshot) => {
          const rows: HandbookMandateListRow[] = await Promise.all(
            snapshot.docs.map(async (handbookDoc) => {
              const handbook = handbookDoc.data() as HandbookFormData;
              const company = (await getDoc(doc(firestore, handbook.companyRef.path))).data() as Company;
              return {
                handbook,
                company,
                key: handbook.key,
              };
            }),
          );

          await Promise.all([
            ...rows.map(async (row) => {
              const products: Product[] = await Promise.all(
                row.handbook!.productRefs?.map(async (ref) => {
                  const product = (await getDoc(ref)).data() as Product;
                  return product;
                }) ?? [],
              );
              if (row.handbook!.instructorRef) {
                row.handbook!.instructor = (await getDoc(doc(firestore, row.handbook!.instructorRef.path))).data() as WPKUser;
              }
              row.handbook!.products = products;
              row.key = row.handbook!.key;
              return row;
            }),
          ]);

          setTableRows(rows);
        },
      ),

      onSnapshot(query(collection(firestore, collections.users), where('role', '==', WpkRole.Instructor)), async (snapshot) => {
        const insts = await Promise.all(
          snapshot.docs.map(async (ds) => {
            const user = ds.data() as WPKUser;
            const company = (await getDoc(doc(firestore, user.companyRef.path))).data() as Company;
            return { ...user, company } as WPKConsultant;
          }),
        );
        setInstructors(insts);
      }),
    ];

    return () => cleanups.forEach((c) => c());
  }, [selectedLanguage, availableLanguages, searchTerm, firestoreUser]);

  const columns = createColumns(t, history, setShowInstructorModal, firestoreUser);

  const groupedCompanies = groupBy(tableRows, (r) => r.company.key);
  const newTableRows = sortBy(
    Object.keys(groupedCompanies).map((company, index) => {
      const values = groupedCompanies[company];
      const row = values[0];
      return { company: row.company, key: `${index}-${row.company.key}`, children: values };
    }),
    (c) => c.company.name,
  );

  const searchEmpty = searching && tableRows && tableRows.length === 0 && (
    <EmptyMessage
      title={t('general.notFound.title', { item: t('private.handbooks.single') })}
      description={t('general.notFound.description')}
      buttonTitle={t('general.notFound.addButtonTitle', { item: t('private.handbooks.single') })}
      buttonIcon={<PlusOutlined />}
      to={app.handbooks.new()}
      logo={<SearchOutlined />}
    />
  );

  return (
    <MainLayout
      pageHeader={
        <StyledListHeader>
          <Title level={4}>{t('private.customer.header.handbooks')}</Title>
          <SearchBar
            placeholderText={t('private.handbooks.list.placeholder')}
            onSearchInput={setSearchTerm}
            radioFilters={[<LanguageRadioButtonFilter key="language" onItemSelected={setSelectedLanguage} />]}
          />
        </StyledListHeader>
      }
    >
      <StyledChildrenContainer>
        {searchEmpty}
        {!searchEmpty && (
          <StyledTabChildContainer>
            <Table
              pagination={false}
              dataSource={newTableRows}
              loading={!tableRows}
              columns={columns}
              locale={{ emptyText: t('general.notFound.title', { item: t('private.customer.header.handbooks') }) }}
              scroll={{ x: true }}
            />
          </StyledTabChildContainer>
        )}
      </StyledChildrenContainer>

      <Modal
        title={t('private.handbooks.list.actions.addInstructor')}
        visible={showInstructorModal.show}
        onOk={() => {
          // eslint-disable-next-line no-debugger
          debugger;
          const instructorToSet = selectedInstructorKey ? instructors?.find((i) => i.key === selectedInstructorKey) : first(instructors);
          if (instructorToSet && showInstructorModal.company && showInstructorModal.handbook) {
            return setHandbookInstructor(instructorToSet, showInstructorModal.company, showInstructorModal.handbook).finally(() =>
              setShowInstructorModal({ show: false, handbook: undefined, company: undefined }),
            );
          } else {
            setShowInstructorModal({ show: false, handbook: undefined, company: undefined });
          }
        }}
        onCancel={() => setShowInstructorModal({ show: false, handbook: undefined, company: undefined })}
      >
        <Select
          defaultValue={first(instructors)?.key}
          style={{ width: '100%' }}
          onChange={(instructorKey) => setSelectedInstructorKey(instructorKey)}
        >
          {instructors &&
            instructors.map((i) => (
              <Select.Option key={i.key} value={i.key}>
                {displayName(i)}
              </Select.Option>
            ))}
        </Select>
      </Modal>
    </MainLayout>
  );
};

export default HandbookMandateList;

const createColumns = (
  t: TFunction,
  history: History,
  setShowInstructorModal: (data: ShowModalData) => void,
  firestoreUser?: WPKUser,
): ColumnsType<HandbookMandateListRow> => [
  {
    title: t('private.handbooks.list.header.company'),
    key: 'name',
    render: (_text, { company }, _index) => company.name,
  },
  {
    title: t('private.handbooks.list.header.name'),
    key: 'name',
    render: (_text, { handbook }, _index) => handbook?.name,
  },
  {
    title: t('private.handbooks.list.header.enNorms'),
    key: 'enNorms',
    render: (_text, { handbook }, _index) => <span>{handbook?.products?.map((p) => p.enNorm).join(',')}</span>,
  },
  {
    title: t('private.handbooks.list.header.version'),
    key: 'version',
    dataIndex: 'version',
    align: 'right',
    render: (_text, { handbook }, _index) =>
      handbook && (
        <span>
          {handbook?.version.major}.{handbook?.version.minor}
        </span>
      ),
  },
  {
    title: t('admin.blueprints.list.header.language'),
    key: 'language',
    dataIndex: 'language',
    render: (_text, { handbook }, _index) => handbook && <span>{t(`general.languages.names.${handbook?.language}`)}</span>,
  },
  {
    title: t('admin.blueprints.list.header.status'),
    key: 'status',
    dataIndex: 'status',
    render: (_text, { handbook }, _index) => {
      if (!handbook) return null;

      const color = t(`private.handbooks.statusColor.${handbook?.status}`);
      return <Tag color={color}>{t(`private.handbooks.status.${handbook?.status}`)}</Tag>;
    },
  },
  {
    title: t('private.handbooks.list.header.updatedAt'),
    key: 'updatedAt',
    align: 'right',
    render: (_text, { handbook }, _index) => (
      <span title={handbook?.updatedAt.toDate().toLocaleString()}>{handbook?.updatedAt.toDate().toLocaleDateString()}</span>
    ),
  },
  {
    title: t('private.handbooks.list.header.instructor'),
    key: 'instructor',
    align: 'right',
    render: (_text, { handbook }, _index) => displayName(handbook?.instructor),
  },
  {
    title: '',
    key: 'actions',
    width: 120,
    render: (_text, { handbook, company }, _index) => {
      if (!handbook) return null;
      return (
        <>
          {firestoreUser?.role === WpkRole.Certifier && (
            <Dropdown
              overlay={
                <Menu>
                  {!handbook.instructorRef && (
                    <Menu.Item key="addRemoveInstructor">
                      <Button size="large" type="link" onClick={() => setShowInstructorModal({ show: true, company, handbook })}>
                        <PlusOutlined />
                        {t('private.handbooks.list.actions.addInstructor')}
                      </Button>
                    </Menu.Item>
                  )}
                  {handbook.instructorRef && (
                    <Menu.Item key="removeInstructor">
                      <Button size="large" type="link" danger onClick={() => removeHandbookInstructor(company, handbook)}>
                        <DeleteOutlined />
                        {t('private.handbooks.list.actions.removeInstructor')}
                      </Button>
                    </Menu.Item>
                  )}
                </Menu>
              }
              placement="bottomRight"
              trigger={['click']}
            >
              <Button type="ghost" shape="circle" icon={<MoreOutlined />} size="small" />
            </Dropdown>
          )}
          <Button
            type="primary"
            shape="circle"
            icon={<RightOutlined />}
            size="small"
            style={{ marginLeft: 24 }}
            onClick={() => history.push(app.mandate.edit(false, { handbookId: handbook?.key, companyId: company.key }))}
          />
        </>
      );
    },
    align: 'right',
  },
];
