import { Alert, Form, Input } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormInputElementProps } from '.';

export const HelpText: React.FC<FormInputElementProps<string>> = (props) => {
  const { onValueChanged, type, value, isUserEditable, isAdminEditing, readonly, additional } = props;
  const { t } = useTranslation();

  if (readonly || (!isAdminEditing && !isUserEditable)) {
    return <Alert style={{ marginBottom: 24 }} message={t('formEditor.elements.hint')} description={value?.value} type="info" />;
  }

  return (
    <Form name={value?.id ?? 'helpText'} layout="vertical">
      <Form.Item label={isAdminEditing === true ? t(`formEditor.addButtons.${type}`) : undefined}>
        <Input.TextArea autoSize={{ minRows: 3 }} defaultValue={value?.value} onChange={(e) => onValueChanged(e.target.value)} />
        {additional}
      </Form.Item>
    </Form>
  );
};
