import { MailOutlined } from '@ant-design/icons';
import { Alert, Card, Spin } from 'antd';
import styled from 'styled-components';
import breakpoints from '../../../styles/breakpoints';
import { layoutSpacings } from '../../../styles/spacings';

export const StyledCard = styled(Card)`
  max-width: 320px;
  min-height: 160px;
  margin: 0 auto;
  border: 1px solid rgba(215, 215, 215, 1);

  @media only screen and (min-width: ${breakpoints.tabletLandscape}) {
    min-width: 320px;
  }
`;

export const StyledSpin = styled(Spin)`
  margin: 0 auto;
`;

export const StyledMailOutline = styled(MailOutlined)`
  color: rgba(0, 0, 0, 0.25);
`;

export const StyledAlert = styled(Alert)`
  ${layoutSpacings.marginBottom}
`;

export const StyledActionsContainer = styled.div`
  display: 'flex';
  justify-content: 'space-between';
`;
