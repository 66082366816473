import { SaveOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useCallback, useEffect, useReducer } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { SortableContainer } from 'react-sortable-hoc';
import styled from 'styled-components';
import { arrayMove } from '../../../shared/array';
import { useDebouncedCallback } from '../../../shared/hooks';
import { Blueprint, BlueprintContentSection, BlueprintContentValues } from '../../../types/firebase';
import { BlueprintFormEditorActions, CollapsedSections, reducer } from './reducer';
import SortableSection from './section';

interface Props extends Pick<React.HTMLAttributes<unknown>, 'className' | 'style'> {
  onSave: (blueprint: Blueprint) => Promise<void>;
  blueprint: Blueprint;
  isAdminEditing?: boolean;
  readonly: boolean;
  isMandate?: boolean;
  isAllowedToWrite: boolean;
}

const StyledButton = styled(Button)`
  margin-top: 64px;
  margin-bottom: 24px;
`;

interface SortableListProps extends Pick<React.HTMLAttributes<unknown>, 'className' | 'style'> {
  sections: BlueprintContentSection[];
  collapsedSections: CollapsedSections;
  dispatch: (action: BlueprintFormEditorActions) => void;
  isAdminEditing?: boolean;
  adminValues: BlueprintContentValues;
  customerValues: BlueprintContentValues;
  readonly: boolean;
  isMandate?: boolean;
  isAllowedToWrite: boolean;
}

const SortableList = SortableContainer((props: SortableListProps) => {
  const {
    sections,
    dispatch,
    collapsedSections,
    isAdminEditing,
    customerValues,
    adminValues,
    readonly,
    isMandate,
    className,
    style,
    isAllowedToWrite
  } = props;
  return (
    <div>
      {sections.map((s, index) => (
        <SortableSection
          key={s.id}
          index={index}
          readonly={readonly}
          section={s}
          dispatch={dispatch}
          isCollapsed={isAdminEditing ? collapsedSections[s.id] : !collapsedSections[s.id]}
          isAdminEditing={isAdminEditing}
          adminValues={adminValues}
          customerValues={customerValues}
          sectionIndex={index}
          isMandate={isMandate}
          isAllowedToWrite={isAllowedToWrite}
          className={className}
          style={style}
        />
      ))}
    </div>
  );
});

const BlueprintFormEditor: React.FC<Props> = (props) => {
  const { blueprint, onSave, isAdminEditing, readonly, isMandate, className, style, isAllowedToWrite } = props;
  const { t } = useTranslation();

  const initialData = {
    content: blueprint.content,
    collapsedSections: blueprint.content.sections.reduce((acc, s) => ({ ...acc, [s.id]: true }), {}),
    hasChanges: false,
  };
  const [reducerState, dispatch] = useReducer(reducer, initialData);

  const [save] = useDebouncedCallback(
    (data: typeof initialData) => {
      if (blueprint.content.updatedAt?.seconds === data.content.updatedAt?.seconds) {
        return;
      }
      onSave({ ...blueprint, content: data.content }).finally(() => dispatch({ type: 'formSaved' }));
    },
    2500,
    { trailing: true },
  );

  useEffect(() => save(reducerState), [reducerState, save]);

  const addSection = useCallback(() => dispatch({ type: 'createSection' }), [dispatch]);
  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) =>
      dispatch({ type: 'moveSection', payload: arrayMove(reducerState.content.sections, oldIndex, newIndex) }),
    [dispatch, reducerState],
  );

  const portalNode = document.getElementById('save-indicator-portal');

  return (
    <div className="section-container">
      {reducerState.hasChanges && portalNode && createPortal(<SaveOutlined />, portalNode)}
      <SortableList
        useDragHandle
        readonly={readonly}
        sections={reducerState.content.sections}
        collapsedSections={reducerState.collapsedSections}
        dispatch={dispatch}
        isAdminEditing={isAdminEditing}
        onSortEnd={onSortEnd}
        adminValues={reducerState.content.adminValues}
        customerValues={reducerState.content.customerValues}
        isMandate={isMandate}
        isAllowedToWrite={isAllowedToWrite}
        className={className}
        style={{ ...style, marginBottom: 24 }}
      />
      {!readonly && isAdminEditing === true && (
        <StyledButton type="primary" onClick={addSection}>
          {t('formEditor.sections.add')}
        </StyledButton>
      )}
    </div>
  );
};

export default React.memo(BlueprintFormEditor);
