import { FileAddOutlined } from '@ant-design/icons';
import { Table, Tag, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import Title from 'antd/lib/typography/Title';
import { TFunction } from 'i18next';
import * as _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import MainLayout, { StyledChildrenContainer } from '../../../components/layouts/main';
import EmptyMessage from '../../../components/molecules/emptyMessage';
import LoadingIndicator from '../../../components/molecules/loadingIndicator';
import WillaxPageHeader from '../../../components/molecules/pageHeader';
import { firestore } from '../../../firebase/init';
import { admin } from '../../../routing/routes';
import { companyGetRef, firestoreCompanyPath } from '../../../shared/company';
import { useNotifications } from '../../../shared/notifications';
import { Company, HandbookFormData, Product } from '../../../types/firebase';
import { HandbookStatus } from '../../../types/firebase/collections/handbookTypes';
import collections from '../../../types/shared/collections';
import CompanyInfo from './companyInfo';
import { collection, doc, getDoc, getDocs, orderBy, query, where } from 'firebase/firestore';

interface URLParams {
  companyId?: string;
}

export const CompanyShow: React.FC<unknown> = (_props) => {
  const { t } = useTranslation();
  const { companyId } = useParams<URLParams>();
  const history = useHistory();
  const { notifyGeneralError } = useNotifications(t('admin.users.single'));

  const [company, setCompany] = useState<Company | undefined>(undefined);
  const [handBooks, setHandBooks] = useState<HandbookFormData[] | undefined>(undefined);

  useEffect(() => {
    const fetchData = async () => {
      const companyRef = doc(firestore, firestoreCompanyPath(companyId ?? 'will-never-match'));
      const company = (await getDoc(companyRef))?.data() as Company;
      if (!company) return history.push(admin.companies.list());

      const handBooks = (
        await getDocs(
          query(
            collection(firestore, companyRef.path, collections.handbooks),
            where('status', 'in', [
              HandbookStatus.Released,
              HandbookStatus.Draft,
              HandbookStatus.Init,
              HandbookStatus.CertRejected,
              HandbookStatus.CertApproved,
            ]),
            where('isArchived', '==', false),
            orderBy('createdAt', 'asc'),
          ),
        )
      ).docs.map((d) => d.data() as HandbookFormData);

      await Promise.all(
        handBooks.map(async (h) => {
          const products: Product[] = await Promise.all(
            h.productRefs?.map(async (ref) => {
              const product = (await getDoc(ref)).data() as Product;
              return product;
            }) ?? [],
          );
          h.products = products;
          return h;
        }),
      );

      setCompany(company);
      setHandBooks(handBooks);
    };

    void fetchData();
  }, [companyId, history, notifyGeneralError]);

  const expandedRowRender = (hb: HandbookFormData) => {
    const columns = createProductColums(t);

    return <Table columns={columns} dataSource={hb.products} pagination={false} locale={{ emptyText: t('general.emptyList') }} />;
  };

  const sumProductsCost = (pageData: readonly HandbookFormData[]) => {
    const products = _.flatMap(pageData, (pd) => pd.products);
    const costSum = _.sumBy(products, (p) => p?.price ?? 0);

    return (
      <tr>
        <th colSpan={2}>{t('private.company.products.total')}</th>
        <td>
          <Typography.Text>{`${costSum} CHF`}</Typography.Text>
        </td>
      </tr>
    );
  };

  const columns = createHandbookColumns(t);

  return (
    <MainLayout
      pageHeader={
        <WillaxPageHeader
          title={company?.name ?? ''}
          routes={[
            { path: admin.companies.list(), breadcrumbName: t('admin.header.companies') },
            { path: '', breadcrumbName: company ? company.name : '' },
          ]}
        />
      }
    >
      <StyledChildrenContainer>
        {!company && !handBooks && <LoadingIndicator />}

        {!!company && (
          <>
            <Title level={4}>{t('admin.users.list.header.company')}</Title>
            <CompanyInfo company={company} />
          </>
        )}

        {!!handBooks && <Title level={4}>{t('private.company.info.usedProducts')}</Title>}
        {handBooks && handBooks.length > 0 && (
          <Table
            className="components-table-demo-nested"
            columns={columns}
            expandable={{ expandedRowRender }}
            dataSource={handBooks}
            summary={sumProductsCost}
            pagination={false}
            locale={{ emptyText: t('general.emptyList') }}
            scroll={{ x: true }}
          />
        )}
        {handBooks && handBooks.length === 0 && (
          <EmptyMessage
            title={t('admin.products.empty.title')}
            description={t('admin.products.empty.description')}
            logo={<FileAddOutlined />}
            enableAddButton={false}
          />
        )}
      </StyledChildrenContainer>
    </MainLayout>
  );
};

export default CompanyShow;

const createHandbookColumns = (t: TFunction): ColumnsType<HandbookFormData> => [
  { title: 'Handbuch', dataIndex: 'name', key: 'name' },
  {
    title: t('admin.blueprints.list.header.language'),
    key: 'language',
    dataIndex: 'language',
    render: (_text, h, _index) => <span>{t(`general.languages.names.${h.language}`)}</span>,
  },
  {
    title: t('admin.blueprints.list.header.status'),
    key: 'status',
    dataIndex: 'status',
    render: (_text, h, _index) => {
      const color = t(`private.handbooks.statusColor.${h.status}`);
      return <Tag color={color}>{t(`private.handbooks.status.${h.status}`)}</Tag>;
    },
  },
  {
    title: t('admin.companies.list.header.createdAt'),
    key: 'createdAt',
    render: (_text, h, _index) => (
      <span title={h.createdAt?.toDate().toLocaleString()} key={h.key}>
        {h.createdAt?.toDate().toLocaleDateString()}
      </span>
    ),
  },
];

const createProductColums = (_t: TFunction): ColumnsType<Product> => [
  { title: 'Produkt', dataIndex: 'enNorm', key: 'enNorm' },
  { title: 'Variante', dataIndex: 'variant', key: 'variant' },
  { title: 'Name', dataIndex: 'name', key: 'name' },
  {
    title: 'Preis',
    dataIndex: 'price',
    key: 'price',
    render: (_text, product, _index) => <span key={product.key}>{`${product.price} CHF`}</span>,
  },
];
