import { Form, Input, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormInputElementProps } from '.';

export const Title: React.FC<FormInputElementProps<string>> = (props) => {
  const { onValueChanged, type, value, isAdminEditing, isUserEditable, readonly, additional } = props;
  const { t } = useTranslation();

  if (readonly || (!isAdminEditing && !isUserEditable))
    return <Typography.Paragraph style={{ fontSize: 16, fontWeight: 'bold' }}>{value?.value}</Typography.Paragraph>;

  return (
    <Form name={value?.id ?? 'title'} layout="vertical">
      <Form.Item label={t(`formEditor.addButtons.${type}`)}>
        <Input size="large" defaultValue={value?.value} onChange={(e) => onValueChanged(e.target.value)} />
        {additional}
      </Form.Item>
    </Form>
  );
};
