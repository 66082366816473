import { DeleteOutlined, DownloadOutlined, FileAddOutlined, MoreOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import Title from 'antd/lib/typography/Title';
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { TFunction } from 'i18next';
import { default as React, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import StyledListHeader from '../../../components/atoms/listPageHeader';
import { TooltipSubstringText } from '../../../components/atoms/tooltipText';
import MainLayout, { StyledChildrenContainer } from '../../../components/layouts/main';
import EmptyMessage from '../../../components/molecules/emptyMessage';
import LoadingIndicator from '../../../components/molecules/loadingIndicator';
import { LanguageRadioButtonFilter, RadioButtonFilterDefaultValue } from '../../../components/molecules/radioButtonFilter';
import SearchBar from '../../../components/molecules/searchBar';
import { firestore } from '../../../firebase/init';
import { admin } from '../../../routing/routes';
import { deleteAdminDocument } from '../../../services/firebase/documents';
import { ApplicationContext, UserContext } from '../../../shared/contexts';
import { documentIsInUse } from '../../../shared/documents';
import { noOp } from '../../../shared/functions';
import { useNotifications } from '../../../shared/notifications';
import { userIsAdmin } from '../../../shared/user';
import { CustomerAttachment, FirestoreDocument } from '../../../types/firebase';
import collections from '../../../types/shared/collections';
import { PickerProps } from '../types';

export const DocumentList: React.FC<PickerProps<FirestoreDocument>> = (props) => {
  const { pickerFilterLanguage, pickerOnChange, pickerMode } = props;

  const { t } = useTranslation();
  const [documents, setDocuments] = useState<FirestoreDocument[] | undefined>(undefined);
  const { availableLanguages } = useContext(ApplicationContext);
  const { userInfo } = useContext(UserContext);
  const { notifyDeletedSuccess, notifyGeneralError, notifyDeleteConfirm } = useNotifications<FirestoreDocument>(
    t('admin.documents.single'),
  );
  const [selectedLanguage, setSelectedLanguage] = useState(RadioButtonFilterDefaultValue);
  const [searchTerm, setSearchTerm] = useState('');

  const searching = !!searchTerm || selectedLanguage !== RadioButtonFilterDefaultValue;
  const isAdmin = userIsAdmin(userInfo?.idTokenResult);

  useEffect(() => {
    const whereClauses = [];
    if (selectedLanguage !== RadioButtonFilterDefaultValue || !!pickerFilterLanguage) {
      whereClauses.push(where('language', '==', pickerFilterLanguage || selectedLanguage));
    }
    if (searchTerm && searchTerm.length > 0) {
      whereClauses.push(where('searchText', 'array-contains-any', searchTerm.toLowerCase().split(' ')));
    }

    return onSnapshot(query(collection(firestore, collections.documents), ...whereClauses, orderBy('name')), (snapshot) => {
      const docsData = snapshot.docs.map((doc) => doc.data() as FirestoreDocument);
      setDocuments(docsData);
    });
  }, [selectedLanguage, availableLanguages, searchTerm, pickerFilterLanguage]);

  const onDeleteProduct = (document: FirestoreDocument) =>
    deleteAdminDocument(document)
      .then(notifyDeletedSuccess)
      .catch((e) => {
        console.error(e);
        notifyGeneralError();
      });

  const columns = createColumns(
    t,
    (document: FirestoreDocument) => notifyDeleteConfirm(document, onDeleteProduct, noOp),
    isAdmin,
    pickerMode,
  );

  return (
    <MainLayout
      skipLayout={pickerMode}
      pageHeader={
        <StyledListHeader>
          <Title level={4}>{t('admin.header.documents')}</Title>
          <SearchBar
            extra={[
              <Link key="new" to={admin.documents.new()}>
                <Button icon={<PlusOutlined />} size="large" type="primary">
                  {t('admin.documents.empty.addButtonTitle')}
                </Button>
              </Link>,
            ]}
            placeholderText={t('admin.documents.list.placeholder')}
            onSearchInput={setSearchTerm}
            radioFilters={pickerFilterLanguage ? [] : [<LanguageRadioButtonFilter key="language" onItemSelected={setSelectedLanguage} />]}
          />
        </StyledListHeader>
      }
    >
      <StyledChildrenContainer>
        {typeof documents === 'undefined' && <LoadingIndicator />}

        {searching && documents && documents.length === 0 && (
          <EmptyMessage
            title={t('general.notFound.title', { item: t('admin.documents.single') })}
            description={t('general.notFound.description')}
            buttonTitle={t('general.notFound.addButtonTitle', { item: t('admin.documents.single') })}
            buttonIcon={<PlusOutlined />}
            to={admin.documents.new()}
            logo={<SearchOutlined />}
            enableAddButton
          />
        )}

        {!searching && documents && documents.length === 0 && (
          <EmptyMessage
            title={t('admin.documents.empty.title')}
            description={t('admin.documents.empty.description')}
            buttonTitle={t('admin.documents.empty.addButtonTitle')}
            buttonIcon={<PlusOutlined />}
            to={admin.documents.new()}
            logo={<FileAddOutlined />}
            enableAddButton
          />
        )}

        {documents && documents.length > 0 && (
          <DocumentsTable documents={documents} columns={columns} pickerMode={pickerMode} pickerOnChange={pickerOnChange} />
        )}
      </StyledChildrenContainer>
    </MainLayout>
  );
};

export default DocumentList;

export const createColumns = (
  t: TFunction,
  showDeleteConfirm: (document: FirestoreDocument) => void,
  isAdmin: boolean,
  alwaysShowDelete?: boolean,
): ColumnsType<FirestoreDocument> => {
  const nonPickerOnlyColumns: ColumnsType<FirestoreDocument> = [
    {
      title: t('admin.documents.list.header.description'),
      key: 'description',
      render: (_text, document, _index) => <TooltipSubstringText text={document.description} />,
    },
    {
      title: '',
      key: 'actions',
      width: 120,
      render: (_text, document, _index) => {
        const menu = (
          <Menu>
            <Menu.Item>
              <Button size="large" type="link" danger onClick={() => showDeleteConfirm(document)}>
                <DeleteOutlined />
                {t('general.form.delete')}
              </Button>
            </Menu.Item>
          </Menu>
        );
        return (
          <div style={{ width: 80 }}>
            {isAdmin && (
              <Dropdown
                overlay={menu}
                placement="bottomRight"
                trigger={['click']}
                disabled={!alwaysShowDelete ? documentIsInUse(document) : false}
              >
                <Button type="ghost" shape="circle" icon={<MoreOutlined />} size="small" />
              </Dropdown>
            )}
            &nbsp;
            <Button
              style={{ marginLeft: 24 }}
              type="primary"
              shape="circle"
              icon={<DownloadOutlined />}
              size="small"
              target="_blank"
              onClick={() => window.open(document.downloadUrl, '_blank')}
              rel="noopener noreferrer"
            />
          </div>
        );
      },
      align: 'right',
    },
  ];

  const adminItems: ColumnsType<FirestoreDocument> =
    isAdmin === true
      ? [
          {
            title: t('admin.documents.list.header.adminDescription'),
            key: 'adminDescription',
            render: (_text, document, _index) => <TooltipSubstringText text={document.adminDescription} />,
          },
          {
            title: t('admin.documents.list.header.language'),
            key: 'language',
            render: (_text, document, _index) => <span>{t(`general.languages.names.${document.language}`)}</span>,
          },
          {
            title: t('admin.documents.list.header.inUse'),
            key: 'inUse',
            render: (_text, document, _index) => t(`general.form.inUse.${documentIsInUse(document)}`),
            align: 'left',
          },
        ]
      : [];

  return [
    {
      title: t('admin.documents.list.header.name'),
      dataIndex: 'name',
      key: 'name',
      fixed: true,
    },
    ...adminItems,
    ...nonPickerOnlyColumns,
  ];
};

export const createCustomerAttachmentColumns = (
  t: TFunction,
  onDelete: (attachment: CustomerAttachment) => void,
  readonly?: boolean,
): ColumnsType<CustomerAttachment> => {
  return [
    {
      title: t('admin.documents.list.header.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '',
      key: 'actions',
      width: 120,
      align: 'right',
      render: (_text, a, _index) => {
        const menu = (
          <Menu>
            <Menu.Item>
              <Button size="large" type="link" danger onClick={() => onDelete(a)}>
                <DeleteOutlined />
                {t('general.form.delete')}
              </Button>
            </Menu.Item>
          </Menu>
        );
        return (
          <>
            {!readonly && (
              <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
                <Button type="ghost" shape="circle" icon={<MoreOutlined />} size="small" />
              </Dropdown>
            )}

            <Button
              style={{ marginLeft: 24 }}
              type="primary"
              shape="circle"
              icon={<DownloadOutlined />}
              size="small"
              target="_blank"
              onClick={() => window.open(a.downloadUrl, '_blank')}
              rel="noopener noreferrer"
            />
          </>
        );
      },
    },
  ];
};

export const DocumentsTable: React.FC<{
  documents: FirestoreDocument[];
  columns: ColumnsType<FirestoreDocument>;
  pickerMode?: boolean;
  pickerOnChange?: (selectedRowKeys: React.ReactText[], selectedRows: FirestoreDocument[]) => void;
}> = (props) => {
  const { documents, columns, pickerMode, pickerOnChange } = props;
  const { t } = useTranslation();

  return (
    <Table
      dataSource={documents}
      columns={columns}
      rowSelection={pickerMode === true ? { onChange: pickerOnChange } : undefined}
      pagination={false}
      locale={{ emptyText: t('general.emptyList') }}
      scroll={{ x: true }}
    />
  );
};

export const CustomerDocumentsTable: React.FC<{
  attachments: CustomerAttachment[];
  columns: ColumnsType<CustomerAttachment>;
}> = (props) => {
  const { attachments, columns } = props;
  const { t } = useTranslation();
  return (
    <Table
      dataSource={attachments}
      columns={columns}
      pagination={false}
      locale={{ emptyText: t('general.emptyList') }}
      scroll={{ x: true }}
    />
  );
};
