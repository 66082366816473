import { FirestoreDocument } from '../types/firebase';
import collections from '../types/shared/collections';

export const firestoreDocumentPath = (id: string) => `/${collections.documents}/${id}`;
export const documentDocPathForDocument = (document: FirestoreDocument) => firestoreDocumentPath(document.key);
export const documentIsInUse = (document: FirestoreDocument) => {
  const blueprintKeys = Object.keys(document.blueprints);
  const referencedForms = blueprintKeys.filter((key) => document.blueprints[key] === true);
  return referencedForms.length > 0;
};
