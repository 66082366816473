import { Button, Typography } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PublicLayout from '../../../components/layouts/publicLayout';
import { auth } from '../../../firebase/init';
import { authentication } from '../../../routing/routes';
import { sendVerificationEmail } from '../../../services/firebase/auth';
import { StyledActionsContainer, StyledCard } from './sharedComponents';

enum FormState {
  init,
  verificationSentAgain,
  error,
}

const VerifyEmailMessage: React.FC<unknown> = (_props) => {
  const [formState, setFormState] = useState(FormState.init);
  const { t } = useTranslation();

  const resendEmail = () => {
    void sendVerificationEmail(auth.currentUser, (success) => setFormState(success ? FormState.verificationSentAgain : FormState.error));
  };

  return (
    <PublicLayout>
      <StyledCard>
        <Typography.Title level={3}>{t('public.login.verifyEmailCardTitle')}</Typography.Title>

        {formState === FormState.init && (
          <>
            <p>{t('public.login.verificationEmailSentText')}</p>
            <Button size="large" type="primary" key="console" onClick={resendEmail}>
              {t('public.login.resendVerificationEmailText')}
            </Button>
          </>
        )}

        {formState === FormState.verificationSentAgain && <p>{t('public.login.verificationEmailSentTextAgain')}</p>}

        {formState === FormState.error && <p>{t('public.login.verificationEmailError')}</p>}

        <StyledActionsContainer style={{ marginTop: 24 }}>
          <span>{t('public.login.wrongEmail')}</span>
          <Link to={authentication.register()}>{t('public.login.back')}</Link>
        </StyledActionsContainer>
      </StyledCard>
    </PublicLayout>
  );
};

export default VerifyEmailMessage;
