import { useForm } from 'antd/lib/form/Form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { HandbookForm } from '..';
import { app } from '../../../../routing/routes';
import { deactivateHandbook, updateHandbook } from '../../../../services/firebase/handbooks';
import { useNotifications } from '../../../../shared/notifications';
import { Company, Handbook, HandbookFormData } from '../../../../types/firebase';

interface Props {
  company: Company;
  handbook: Handbook;
  isMandate?: boolean;
}

export const HandbookSettings: React.FC<Props> = (props) => {
  const { company, handbook, isMandate } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const { notifySaveSuccess, notifyGeneralError } = useNotifications(t('admin.blueprints.single'));
  const [form] = useForm();

  const onFormValuesChangedCallback = (handbookFormData: HandbookFormData) =>
    updateHandbook(company, handbook, handbookFormData)
      .then(notifySaveSuccess)
      .catch((e) => {
        console.error(e);
        notifyGeneralError();
      });

  const onDeactivateHandbookCallback = () =>
    deactivateHandbook(company, handbook)
      .then(notifySaveSuccess)
      .then(() => history.push(app.handbooks.list()))
      .catch((e: Error) => {
        console.error(e);
        notifyGeneralError();
      });

  return (
    <HandbookForm
      onFormValuesChanged={onFormValuesChangedCallback}
      company={company}
      form={form}
      handbook={handbook}
      onDeactivate={onDeactivateHandbookCallback}
      isMandate={isMandate}
    />
  );
};
