import Title from 'antd/lib/typography/Title';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import StyledListHeader from '../../../components/atoms/listPageHeader';
import MainLayout, { StyledChildrenContainer } from '../../../components/layouts/main';
import { UserContext } from '../../../shared/contexts';
import NameChangeForm from './nameChangeForm';
import PasswordChangeForm from './passwordChangeForm';

const Profile = () => {
  const { t } = useTranslation();
  const { firestoreUser } = useContext(UserContext);

  return (
    <MainLayout
      pageHeader={
        <StyledListHeader>
          <Title level={4}>{t('private.profile.title')}</Title>
        </StyledListHeader>
      }
    >
      <StyledChildrenContainer>
        <NameChangeForm user={firestoreUser} />
        <PasswordChangeForm></PasswordChangeForm>
      </StyledChildrenContainer>
    </MainLayout>
  );
};

export default Profile;
