import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { StyledPrintHandbook } from '../../../components/atoms/print';
import LoadingIndicator from '../../../components/molecules/loadingIndicator';
import { StyledPrintForm } from '../../../components/organisms/formEditor/print';
import { admin } from '../../../routing/routes';
import { blueprintGetRefById } from '../../../shared/blueprint';
import { updateTabTitle } from '../../../shared/document';
import { Blueprint } from '../../../types/firebase';
import { blueprintIdFromBlueprint } from '../../../types/shared/blueprints';
import { getDoc } from 'firebase/firestore';

interface URLParams {
  blueprintId?: string;
}

export const BlueprintPrint: React.FC<unknown> = (_props) => {
  const { blueprintId } = useParams<URLParams>();
  const history = useHistory();

  const [blueprint, setBlueprint] = useState<Blueprint | undefined>(undefined);

  useEffect(() => {
    if (!blueprintId) return history.push(admin.blueprints.list());

    async function fetchData() {
      if (!blueprintId) return;
      // only fetch a single form
      const form = (await getDoc(blueprintGetRefById(blueprintId))).data() as Blueprint;
      setBlueprint(form);
    }

    void fetchData();
  }, [setBlueprint, blueprintId, history]);

  useEffect(() => {
    if (blueprint) {
      updateTabTitle(`${blueprint.title} (${blueprintIdFromBlueprint(blueprint)})`);
    }
  }, [blueprint]);

  if (!blueprint) {
    return <LoadingIndicator />;
  }

  return (
    <StyledPrintHandbook>
      <StyledPrintForm form={blueprint} />
    </StyledPrintHandbook>
  );
};
