import { Layout, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { availableResourceLanguages, changeLanguage } from '../../i18n/i18n';
import breakpoints from '../../styles/breakpoints';
import colors from '../../styles/colors';
import { layoutSpacings } from '../../styles/spacings';
import Container from './container';

const StyledFooter = styled(Layout.Footer)`
  background-color: ${colors.white};
  z-index: 100;
  padding: 24px;

  @media only screen and (min-width: 768px) {
    padding: 24px 50px;
  }
`;

const StyledLink = styled.a`
  ${layoutSpacings.marginLeft}
`;

const StyledFooterContainer = styled(Container)`
  & ${StyledLink}:first-child {
    margin-left: 0;
  }

  @media (min-width: ${breakpoints.tabletPortrait}) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const StyledLanguageDropdown = styled.div`
  margin: 16px 0;
`;

const Footer: React.FC<React.HtmlHTMLAttributes<HTMLDivElement>> = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <StyledFooter {...props}>
      <StyledFooterContainer>
        <span>{`© ${new Date().getFullYear()} ${t('public.appName')}`}</span>

        <StyledLanguageDropdown>
          <Select defaultValue={i18n.language} style={{ width: '180px' }} onChange={changeLanguage}>
            {Object.keys(availableResourceLanguages).map((l) => {
              return (
                <Select.Option value={l} key={l}>
                  {t(`general.languages.switcher.${l}`)}
                </Select.Option>
              );
            })}
          </Select>
        </StyledLanguageDropdown>

        <div>
          <StyledLink href={t('public.layout.links.agb')} target="_blank" rel="noopener noreferrer">
            {t('public.footer.termsAndConditions')}
          </StyledLink>
          <StyledLink href={t('public.layout.links.imprint')} target="_blank" rel="noopener noreferrer">
            {t('public.footer.privacyPolicy')}
          </StyledLink>
          <StyledLink href={t('public.layout.links.cookieInformation')} target="_blank" rel="noopener noreferrer">
            {t('public.footer.cookieInformation')}
          </StyledLink>
          <StyledLink href={t('public.layout.links.support')} target="_blank" rel="noopener noreferrer">
            {t('public.footer.support')}
          </StyledLink>
        </div>
      </StyledFooterContainer>
    </StyledFooter>
  );
};

export default Footer;
