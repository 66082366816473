import { DocumentData, DocumentReference, Timestamp } from 'firebase/firestore';
import { BaseCollectionObject, CustomerAttachment } from '.';
import { Product } from './product';

export type FormEditorInputType = 'title' | 'paragraph' | 'helpText' | 'listText' | 'listCheckbox' | 'input' | 'checkbox';

export interface BlueprintContentSectionBaseElement {
  id: string;
  isUserEditable: boolean;
  updatedAt: Timestamp;
  type: FormEditorInputType;
}

export type SectionId = string;
export type ElementId = string;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface FormEditorInputValue<T = any> {
  id: string;
  sectionId: SectionId;
  value: T; // yes, that's by design. every subtype should declare their own value types
  updatedAt: Timestamp;
}

export enum BlueprintType {
  AA = 'AA',
  CL = 'CL',
  PB = 'PB',
  FO = 'FO',
  RL = 'RL',
  VL = 'VL',
}

export interface BlueprintContentSection {
  id: string;
  title: string;
  elements: BlueprintContentSectionBaseElement[];
}

export type BlueprintContentValues = { [key: string]: FormEditorInputValue | undefined };

export interface BlueprintContent {
  updatedAt?: Timestamp;
  sections: BlueprintContentSection[];
  adminValues: BlueprintContentValues;
  customerValues: BlueprintContentValues;
}

export enum BlueprintStatus {
  Init = 'INIT',
  Draft = 'DRAFT',
  Released = 'RELEASED',
  Retired = 'RETIRED',
}

export interface Blueprint extends BaseCollectionObject {
  language: string;
  title: string;
  department: string;
  documentNr: number;
  type: BlueprintType;
  isRequired: boolean;
  status: BlueprintStatus;
  content: BlueprintContent;
  attachmentRefs?: DocumentReference<DocumentData>[];
  customerAttachments?: CustomerAttachment[];
  productRefs?: DocumentReference<DocumentData>[];
  products?: Product[];
  version?: {
    admin: number;
    customer: number;
  };
  isCurrent: boolean;
  previousHandbookRef: DocumentReference<DocumentData> | null;
  isActive: boolean;
  location?: string;
}

export interface CustomerForm extends Omit<Blueprint, 'version'> {
  version: {
    admin: number;
    customer: number;
  };
  originalKey: string;
}
