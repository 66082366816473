import { Button, Checkbox, Form, Input, Typography } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import PublicLayout from '../../../components/layouts/publicLayout';
import { app, authentication } from '../../../routing/routes';
import * as firebaseAuth from '../../../services/firebase/auth';
import { UserContext } from '../../../shared/contexts';
import { layoutSpacings } from '../../../styles/spacings';
import { StyledActionsContainer, StyledAlert, StyledCard, StyledMailOutline, StyledSpin } from './sharedComponents';

export enum AuthFormMode {
  login,
  forgotPassword,
  register,
}

interface Props {
  formMode: AuthFormMode;
}

enum FormFields {
  email = 'email',
  password = 'password',
  passwordConfirm = 'passwordConfirm',
  checkedTermsConditions = 'checkedTermsConditions',
}

enum FormState {
  showForm,
  loading,
  success,
  error,
}

let formRef: FormInstance | null = null;

const StyledLink = styled(Link)`
  text-align: right;
`;
const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & .ant-row {
    margin-bottom: 0;
  }

  ${layoutSpacings.marginBottom}
`;

const Auth: React.FC<Props> = (props) => {
  const { formMode } = props;
  const { t } = useTranslation();
  const { firestoreUser, company } = useContext(UserContext);
  const [formState, setFormState] = useState(FormState.showForm);

  // reset the fields and state in case the mode changes
  useEffect(() => {
    formRef?.resetFields();
    setFormState(FormState.showForm);
  }, [formMode]);

  // callbacks
  const authCallback: firebaseAuth.AuthCallback = (success) => {
    setFormState(success ? FormState.success : FormState.error);
  };
  const loginEmailHandler = (values: { email?: string; password?: string }) => {
    const { [FormFields.email]: email, [FormFields.password]: password } = values;
    if (!email) return;

    setFormState(FormState.loading);

    switch (formMode) {
      case AuthFormMode.login:
        !!password && firebaseAuth.login(email, password, authCallback);
        break;
      case AuthFormMode.register:
        !!password && firebaseAuth.register(email, password, authCallback);
        break;
      case AuthFormMode.forgotPassword:
        firebaseAuth.sendResetEmail(email, authCallback);
        break;
    }
  };

  const formModeTitle = {
    [AuthFormMode.login]: t('public.login.loginTitle'),
    [AuthFormMode.register]: t('public.login.registerTitle'),
    [AuthFormMode.forgotPassword]: t('public.login.forgotPasswordTitle'),
  };

  return (
    <PublicLayout>
      <StyledCard>
        <Typography.Title level={3}>{formModeTitle[formMode]}</Typography.Title>

        {[FormState.loading, FormState.success].indexOf(formState) === -1 && (
          <Form onFinish={loginEmailHandler} ref={(ref) => (formRef = ref)}>
            {formState === FormState.error && (
              <>
                {formMode === AuthFormMode.login && <StyledAlert type="error" message={t('public.login.loginErrorMessage')} />}
                {formMode === AuthFormMode.register && <StyledAlert type="error" message={t('public.login.registerErrorMessage')} />}
                {formMode === AuthFormMode.forgotPassword && (
                  <StyledAlert type="error" message={t('public.login.sendingEmailErrorMessage')} />
                )}
              </>
            )}

            <Form.Item name={FormFields.email} rules={[{ required: true, message: t('public.login.emailRequired') }]}>
              <Input size="large" prefix={<StyledMailOutline />} type="email" placeholder="Email" />
            </Form.Item>

            {formMode !== AuthFormMode.forgotPassword && (
              <Form.Item name={FormFields.password} rules={[{ required: true, message: t('public.login.passwordRequired') }]}>
                <Input.Password size="large" placeholder={t('public.login.password')} />
              </Form.Item>
            )}

            {formMode === AuthFormMode.register && (
              <Form.Item
                name={FormFields.passwordConfirm}
                dependencies={[FormFields.password]}
                rules={[
                  {
                    required: true,
                    message: t('public.login.passwordRequired'),
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue(FormFields.password) === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(t('public.login.passwordsNotMatching'));
                    },
                  }),
                ]}
              >
                <Input.Password size="large" placeholder={t('public.login.passwordConfirm')} />
              </Form.Item>
            )}
            {formMode === AuthFormMode.register && (
              <Form.Item
                name={FormFields.checkedTermsConditions}
                valuePropName="checked"
                rules={[
                  { validator: (_, value) => (value ? Promise.resolve() : Promise.reject(t('public.login.acceptTermsConditionError'))) },
                ]}
              >
                <Checkbox>
                  <span dangerouslySetInnerHTML={{ __html: t('public.login.acceptTermsConditionText') }}></span>
                </Checkbox>
              </Form.Item>
            )}
            {formMode === AuthFormMode.forgotPassword && <p>{t('public.login.sendEmailText')}</p>}

            <StyledButtonContainer>
              <Form.Item>
                <Button size="large" type="primary" htmlType="submit">
                  {formMode === AuthFormMode.login && t('public.login.login')}
                  {formMode === AuthFormMode.register && t('public.login.register')}
                  {formMode === AuthFormMode.forgotPassword && t('public.login.sendPasswordResetEmailText')}
                </Button>
              </Form.Item>

              {formMode === AuthFormMode.login ? (
                <StyledLink to={authentication.forgotPassword()}>{t('public.login.forgotPassword')}</StyledLink>
              ) : null}

              {formMode === AuthFormMode.forgotPassword && <Link to={authentication.login()}>{t('public.login.backToLogin')}</Link>}
            </StyledButtonContainer>

            <StyledActionsContainer>
              {formMode === AuthFormMode.register && (
                <>
                  <span>{t('public.login.yesAccount')}</span>
                  <Link to={authentication.login()}>{t('public.login.login')}</Link>
                </>
              )}
              {formMode === AuthFormMode.login && (
                <>
                  <span>{t('public.login.noAccount')}</span>
                  <Link to={authentication.register()}>{t('public.login.register')}</Link>
                </>
              )}
            </StyledActionsContainer>
          </Form>
        )}

        {formState === FormState.loading && <StyledSpin />}
        {formState === FormState.success && (
          <>
            {formMode === AuthFormMode.login && !!firestoreUser && !!company && <Redirect to={app.index()} />}
            {formMode === AuthFormMode.register && <Redirect to={authentication.verifyEmail()} />}
            {formMode === AuthFormMode.forgotPassword && (
              <>
                <p>{t('public.login.checkInboxText')}</p>

                <Button size="large" type="primary">
                  <Link to={authentication.login()}>{t('public.login.backToLogin')}</Link>
                </Button>
              </>
            )}
          </>
        )}
      </StyledCard>
    </PublicLayout>
  );
};

export default Auth;
