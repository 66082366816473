import React, { useState } from 'react';
import TimeAgo from 'javascript-time-ago';
import { useTranslation } from 'react-i18next';
import { useInterval } from '../../shared/hooks';

interface Props {
  milliSeconds?: number;
  label?: string;
  small?: boolean;
}

const LastUpdatedAt: React.FC<Props> = ({ milliSeconds, label, small }) => {
  const { i18n } = useTranslation();
  const timeAgo = new TimeAgo(i18n.language);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_internalClock, setInternalClock] = useState(0);
  useInterval(() => setInternalClock(Date.now()), 1000 * 60);

  if (!milliSeconds) return null;

  const content = (
    <>
      {label}&nbsp;
      {timeAgo.format(milliSeconds)}
    </>
  );

  return small === true ? <small>{content}</small> : <span>{content}</span>;
};

export default React.memo(LastUpdatedAt);
