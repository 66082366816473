import { ArrowRightOutlined } from '@ant-design/icons';
import { Layout, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';
import colors from '../../styles/colors';
import { layoutSpacings } from '../../styles/spacings';
import Footer from '../molecules/footer';

import { ReactComponent as Logo } from '../../components/molecules/header/Logo-OnlineWPK.svg';

const { Content } = Layout;

const StyledContent = styled(Content)`
  ${layoutSpacings.margin}

  @media only screen and (min-width: ${breakpoints.tabletPortrait}) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

const StyledLayout = styled(Layout)`
  background-color: ${colors.background};

  @media only screen and (min-width: ${breakpoints.tabletPortrait}) {
    height: 100vh;
  }
`;

const StyledText = styled.div`
  @media only screen and (min-width: ${breakpoints.tabletPortrait}) {
    max-width: 340px;
  }

  @media only screen and (min-width: ${breakpoints.tabletLandscape}) {
    max-width: 480px;
  }
`;

const StyledChildren = styled.div`
  ${layoutSpacings.marginTop}
  @media only screen and (min-width: ${breakpoints.tabletLandscape}) {
    ${layoutSpacings.marginLeft}
    margin-top: 0;
  }
`;

const StyledLogo = styled(Logo)`
  display: block;
  margin-bottom: 32px;

  max-width: 320px;
`;

const StyledFooter = styled(Footer)``;

const PublicLayout: React.FC<unknown> = (props) => {
  const { t } = useTranslation();
  const { children } = props;

  const descriptionListItems: string[] = t('public.layout.listItems', { returnObjects: true });

  const DescriptionList = descriptionListItems && (
    <ul>
      {descriptionListItems.map((li, index) => (
        <li key={index}>{li}</li>
      ))}
    </ul>
  );

  return (
    <StyledLayout>
      <StyledContent>
        <StyledText>
          <StyledLogo />
          <Typography.Paragraph style={{ whiteSpace: 'pre-wrap' }}>{t('public.layout.text')}</Typography.Paragraph>
          {DescriptionList}
          <a href={t('public.layout.links.moreInformation')} target="_blank" rel="noopener noreferrer">
            <ArrowRightOutlined /> {t('public.layout.moreInformation')}
          </a>
        </StyledText>

        <StyledChildren>{children}</StyledChildren>
      </StyledContent>
      <StyledFooter />
    </StyledLayout>
  );
};

export default PublicLayout;
