import { PageHeader } from 'antd';
import { BreadcrumbProps } from 'antd/lib/breadcrumb';
import { PageHeaderProps } from 'antd/lib/page-header';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';

interface Props {
  title: React.ReactElement | string;
  routes: BreadcrumbProps['routes'];
  extra?: PageHeaderProps['extra'];
}

const StyledPageHeader = styled(PageHeader)`
  padding-top: 24px !important;
  padding-bottom: 24px !important;

  @media only screen and (min-width: ${breakpoints.desktopSmall}) {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
`;

const WillaxPageHeader: React.FC<Props> = (props) => {
  const { title, routes, extra } = props;

  return (
    <StyledPageHeader
      title={title}
      ghost={false}
      extra={extra}
      breadcrumb={{
        routes,
        itemRender: (route, params, routes, _paths) => {
          const last = routes.indexOf(route) === routes.length - 1;
          return last ? <span>{route.breadcrumbName}</span> : <Link to={route.path}>{route.breadcrumbName}</Link>;
        },
      }}
    ></StyledPageHeader>
  );
};

export default WillaxPageHeader;
