import { ArrowsAltOutlined, DeleteOutlined, DragOutlined, ShrinkOutlined } from '@ant-design/icons';
import { Card, Form, Input } from 'antd';
import debounce from 'lodash/debounce';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import styled from 'styled-components';
import { noOp } from '../../../shared/functions';
import { useNotifications } from '../../../shared/notifications';
import colors from '../../../styles/colors';
import { layoutSpacings } from '../../../styles/spacings';
import { BlueprintContentSection, BlueprintContentValues, ElementId, FormEditorInputType } from '../../../types/firebase';
import CommentsEditor, { CommentsContext } from '../commentsEditor';
import AddButtonGroup from './addButtonGroup';
import { BlueprintFormEditorActions } from './reducer';
import SectionElement from './sectionElement';

interface Props extends Pick<React.HTMLAttributes<unknown>, 'className' | 'style'> {
  section: BlueprintContentSection;
  sectionIndex: number;
  dispatch: (action: BlueprintFormEditorActions) => void;
  isCollapsed?: boolean;
  isAdminEditing?: boolean;
  adminValues: BlueprintContentValues;
  customerValues: BlueprintContentValues;
  readonly: boolean;
  isMandate?: boolean;
  isAllowedToWrite: boolean;
}

const StyledActionButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  & > *:nth-child(n + 2) {
    ${layoutSpacings.marginLeft}
  }
`;

const StyledDeleteOutlined = styled(DeleteOutlined)`
  color: ${colors.red};
`;

const SortHandle = SortableHandle(() => {
  return <DragOutlined style={{ cursor: 'pointer' }} />;
});

const StyledCard = styled(Card)`
  .ant-card-body {
    background-color: ${colors.background};
    padding: 24px;
  }

  .ant-card-head-title {
    font-size: 18px;
    font-weight: bold;
  }

  .ant-form {
    max-width: 100%;
  }

  .ant-form-item {
    margin-bottom: 32px;
  }
`;

const FormSection: React.FC<Props> = (props) => {
  const {
    section,
    sectionIndex,
    dispatch,
    isCollapsed,
    isAdminEditing,
    adminValues,
    customerValues,
    readonly,
    isMandate,
    isAllowedToWrite,
    ...htmlStyles
  } = props;
  const { t } = useTranslation();
  const { notifyDeleteConfirm } = useNotifications(t('formEditor.sections.single'));
  const { onSaveComment } = useContext(CommentsContext);

  const buttonObjects = (['title', 'paragraph', 'helpText', 'input', 'listText', 'listCheckbox'] as FormEditorInputType[]).map(
    (type: FormEditorInputType) => ({
      type,
      label: t(`formEditor.addButtons.${type}`),
    }),
  );

  const onDelete = useCallback(() => {
    notifyDeleteConfirm(section, () => dispatch({ type: 'removeSection', payload: section }), noOp);
  }, [dispatch, section, notifyDeleteConfirm]);

  // not doing copy for now
  // const onCopy = useCallback(() => dispatch({ type: 'copySection', payload: section }), [dispatch, section]);
  const onToggleCollapse = useCallback(() => dispatch({ type: 'toggleCollapseSection', payload: section }), [dispatch, section]);
  const onAddButtonClicked = useCallback(
    (type: FormEditorInputType) => dispatch({ type: 'addSectionElement', payload: { type, sectionId: section.id } }),
    [dispatch, section],
  );
  const onDeleteElementClicked = useCallback(
    (elementId: ElementId) => dispatch({ type: 'removeSectionElement', payload: { elementId, sectionId: section.id } }),
    [dispatch, section],
  );
  const onElementEditableChanged = useCallback(
    (elementId: ElementId, editable: boolean) =>
      dispatch({ type: 'sectionElementEditableChanged', payload: { sectionId: section.id, elementId, editable } }),
    [dispatch, section],
  );
  const onElelementValueChanged = useCallback(
    (elementId: ElementId, value: unknown) =>
      dispatch({ type: 'sectionElementValueChanged', payload: { sectionId: section.id, elementId, value, isAdminEditing } }),
    [dispatch, section, isAdminEditing],
  );
  const onTitleChange = debounce((title: string) => dispatch({ type: 'updateSection', payload: { ...section, title } }), 250, {
    trailing: true,
  });

  return (
    <StyledCard
      id={props.section.id}
      type="inner"
      title={props.section.title || t('formEditor.sections.add')}
      extra={
        <StyledActionButtonContainer>
          {!readonly && isAdminEditing && <StyledDeleteOutlined onClick={onDelete} />}
          {/* <CopyOutlined onClick={onCopy} /> */}
          {isCollapsed ? <ArrowsAltOutlined onClick={onToggleCollapse} /> : <ShrinkOutlined onClick={onToggleCollapse} />}
          {!readonly && isAdminEditing && <SortHandle />}
        </StyledActionButtonContainer>
      }
      {...htmlStyles}
    >
      {!isCollapsed && (
        <>
          <Form layout="vertical">
            {!readonly && isAdminEditing === true && (
              <Form.Item label={t('formEditor.sections.fields.title')}>
                <Input
                  size="large"
                  defaultValue={section.title}
                  onChange={(e) => onTitleChange(e.target.value)}
                  placeholder={t('formEditor.sections.add')}
                />
              </Form.Item>
            )}
          </Form>

          {section.elements.map((e) => {
            return (
              <React.Fragment key={e.id}>
                <SectionElement
                  element={e}
                  readonly={(isMandate && !isAllowedToWrite) || readonly}
                  onDeleteElement={onDeleteElementClicked}
                  onElementEditableChanged={onElementEditableChanged}
                  isAdminEditing={readonly ? false : isAdminEditing}
                  onValueChanged={onElelementValueChanged}
                  adminValue={adminValues[e.id]}
                  customerValue={customerValues[e.id]}
                />
              </React.Fragment>
            );
          })}

          {!isAdminEditing && onSaveComment && (
            <CommentsEditor readonly={readonly} sectionId={section.id} sectionTitle={section.title} sectionIndex={sectionIndex} />
          )}

          {!readonly && isAdminEditing === true && <AddButtonGroup onButtonClick={onAddButtonClicked} buttons={buttonObjects} />}
        </>
      )}
    </StyledCard>
  );
};

export default SortableElement(FormSection);
