import { Form } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import MainLayout, { StyledChildrenContainer } from '../../../components/layouts/main';
import WillaxPageHeader from '../../../components/molecules/pageHeader';
import { app } from '../../../routing/routes';
import { createHandbook } from '../../../services/firebase/handbooks';
import { UserContext } from '../../../shared/contexts';
import { useNotifications } from '../../../shared/notifications';
import { HandbookFormData } from '../../../types/firebase';
import HandbookForm from './form';

export const HandbookNew: React.FC<unknown> = (_props) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { company } = useContext(UserContext);
  const { notifyGeneralError, notifySaveSuccess } = useNotifications(t('private.handbooks.single'));
  const [form] = Form.useForm();
  form.setFieldsValue({ language: i18n.language });

  const executeSave = (values?: HandbookFormData) => {
    if (!company || !values) return Promise.resolve(false);

    return createHandbook(company, values, i18n.language)
      .then(() => {
        notifySaveSuccess();
        return true;
      })
      .catch((error: Error) => {
        console.error(error);
        notifyGeneralError();
        return false;
      });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFormValuesChanged = (values: any) =>
    executeSave(values).then((success: boolean) => {
      if (success) history.push(app.handbooks.list());
    });

  return (
    <MainLayout
      pageHeader={
        <WillaxPageHeader
          title={t('private.handbooks.empty.addButtonTitle')}
          routes={[
            { path: app.handbooks.list(), breadcrumbName: t('private.customer.header.handbooks') },
            { path: app.handbooks.new(), breadcrumbName: t('private.handbooks.single') },
          ]}
        />
      }
    >
      <StyledChildrenContainer>
        {company && <HandbookForm company={company} onFormValuesChanged={onFormValuesChanged} form={form} />}
      </StyledChildrenContainer>
    </MainLayout>
  );
};

export default HandbookNew;
