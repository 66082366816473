import { Form, Input, Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FormInputElementProps } from '.';
import { StyledNumberInput } from '../../../atoms/inputs';

type InputType = 'number' | 'email' | 'text';
type InputValue = { type: InputType; text: string | number | null; label: string };

const StyledForm = styled(Form)`
  .user-editeable {
    display: none;
  }
`;

export const VariantInput: React.FC<FormInputElementProps<InputValue>> = (props) => {
  const { onValueChanged, type, value, isAdminEditing, readonly, additional } = props;
  const { t } = useTranslation();

  const existingData = (): InputValue => (value?.value ? value.value : { type: 'text', text: '', label: '' });
  const radioValueChanged = (e: RadioChangeEvent) => onValueChanged({ ...existingData(), type: e.target.value });
  const inputValueChanged = (newValue: string | number | undefined) => onValueChanged({ ...existingData(), text: newValue ?? '' });
  const labelValueChanged = (newValue: string | undefined) => onValueChanged({ ...existingData(), label: newValue ?? '' });

  if (!isAdminEditing) {
    const formItem = (() => {
      switch (value?.value.type) {
        case 'text':
        case 'email':
          return (
            <Form.Item label={value?.value.label}>
              <Input
                size="large"
                onChange={(e) => inputValueChanged(e.target.value)}
                type={value?.value.type}
                defaultValue={value?.value.text ?? ''}
                disabled={readonly}
              />
            </Form.Item>
          );
        case 'number': {
          const defaultValue = parseFloat((value.value.text || '0').toString());
          return (
            <Form.Item label={value?.value.label}>
              <StyledNumberInput size="large" onChange={inputValueChanged} defaultValue={defaultValue} disabled={readonly} />
            </Form.Item>
          );
        }
      }
    })();

    return <StyledForm layout="vertical">{formItem}</StyledForm>;
  }

  return (
    <StyledForm name={value?.id ?? 'variantInput'} layout="vertical">
      <Form.Item label={t(`formEditor.addButtons.${type}`)}>
        <Form.Item label={t(`formEditor.elements.label`)}>
          <Input size="large" onChange={(e) => labelValueChanged(e.target.value)} defaultValue={value?.value.label ?? ''} />
        </Form.Item>

        <Radio.Group onChange={radioValueChanged} defaultValue={value?.value.type}>
          <Radio.Button value="text">{t('formEditor.elements.text')}</Radio.Button>
          <Radio.Button value="number">{t('formEditor.elements.number')}</Radio.Button>
          <Radio.Button value="email">{t('formEditor.elements.email')}</Radio.Button>
        </Radio.Group>
      </Form.Item>
      {additional}
    </StyledForm>
  );
};
