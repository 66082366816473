import { InboxOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, Tooltip, Upload } from 'antd';
import { Store } from 'antd/lib/form/interface';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { DeleteButtonIcon } from '../../../components/atoms/icons';
import { acceptedImageFiles } from '../../../services/firebase/documents';
import { alwaysFalse } from '../../../shared/functions';
import { Company } from '../../../types/firebase';

interface ItemProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  editMode?: boolean;
  logoUrl?: string | null;
  onDeleteCompanyLogo?: () => void;
}

export const StyledAddressLine = styled(Input.Group)`
  display: flex !important;
  flex-direction: row;
  justify-content: flex-start;
`;

export const StyledAddressStreetLine = styled(Form.Item)`
  flex: 1 100%;
`;

const StyledImage = styled.img`
  width: 60%;
  height: auto;
`;

const StyledLogo = styled.div`
  margin-bottom: 24px;
`;

export const CompanyFormItems: React.FC<ItemProps> = (props) => {
  const { editMode, logoUrl, onDeleteCompanyLogo } = props;
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const limitFile = (e: any) => e.fileList.slice(-1);

  return (
    <>
      <Form.Item
        name={['company', 'name']}
        rules={[{ required: true, message: t('general.form.required') }]}
        label={t('private.company.create.fields.name')}
      >
        <Input size="large" disabled={editMode} />
      </Form.Item>

      <Form.Item
        name={['company', 'registrationNumber']}
        rules={[{ required: true, message: t('general.form.required') }]}
        label={t('private.company.create.fields.registrationNumber')}
      >
        <Input size="large" disabled={editMode} />
      </Form.Item>

      <Form.Item
        name={['company', 'website']}
        rules={[{ required: true, message: t('general.form.required') }]}
        label={t('private.company.create.fields.website')}
      >
        <Input type="url" size="large" disabled={editMode} required />
      </Form.Item>

      {!!logoUrl && (
        <StyledLogo>
          <p>
            <b>{t('admin.documents.create.fields.logo.label')}</b>&nbsp;
            {onDeleteCompanyLogo && <DeleteButtonIcon style={{ cursor: 'pointer' }} onClick={onDeleteCompanyLogo} />}
          </p>
          <StyledImage src={logoUrl} alt="company logo" />
        </StyledLogo>
      )}

      {!logoUrl && (
        <Form.Item label={<span>{t('private.company.create.fields.logo')}</span>}>
          <Form.Item
            name={['company', 'files']}
            valuePropName="fileList"
            getValueFromEvent={limitFile}
            noStyle
            rules={[{ required: false, message: t('general.form.required') }]}
          >
            <Upload.Dragger name="files" beforeUpload={alwaysFalse} accept={acceptedImageFiles.join(',')} disabled={editMode}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">{t('admin.documents.create.fields.file.dragText')}</p>
              <p className="ant-upload-hint">{t('admin.documents.create.fields.file.introduction')}</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
      )}

      <Form.Item
        name={['company', 'address', 'street']}
        rules={[{ required: true, message: t('general.form.required') }]}
        label={
          <span>
            {t('private.company.create.fields.address')}&nbsp;
            <Tooltip title={t('private.company.create.fields.addressHint')}>
              <QuestionCircleOutlined />
            </Tooltip>
          </span>
        }
      >
        <Input size="large" disabled={editMode} placeholder={t('private.company.create.fields.streetPlaceholder')} />
      </Form.Item>

      <StyledAddressLine compact>
        <Form.Item name={['company', 'address', 'zipCode']} rules={[{ required: true, message: t('general.form.required') }]}>
          <Input size="large" disabled={editMode} placeholder={t('private.company.create.fields.zipCodePlaceholder')} />
        </Form.Item>
        &nbsp;
        <StyledAddressStreetLine name={['company', 'address', 'city']} rules={[{ required: true, message: t('general.form.required') }]}>
          <Input size="large" disabled={editMode} placeholder={t('private.company.create.fields.cityPlaceholder')} />
        </StyledAddressStreetLine>
      </StyledAddressLine>
    </>
  );
};

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFormValuesChanged: (values: any) => Promise<boolean>;
  company?: Company;
  onDeleteCompanyLogo?: () => void;
}

const StyledFormItem = styled(Form.Item)`
  margin-top: 64px;
`;

export const CompanyForm: React.FC<Props> = (props) => {
  const { company, onFormValuesChanged, onDeleteCompanyLogo } = props;
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);

  const onFinished = (store: Store) => {
    setIsSaving(true);
    void onFormValuesChanged(store)
      .then((success) => {
        if (success) {
          form.resetFields(['company', ['files']]);
        }
      })
      .finally(() => setIsSaving(false));
  };

  useEffect(() => {
    form.setFieldsValue({ company });
  }, [company, form]);

  return (
    <Form form={form} initialValues={{ company }} layout="vertical" onFinish={onFinished}>
      <CompanyFormItems editMode={false} logoUrl={company?.logoURL} onDeleteCompanyLogo={onDeleteCompanyLogo} />

      <StyledFormItem>
        <Button size="large" htmlType="submit" type="primary" loading={isSaving}>
          {t('general.form.save')}
        </Button>
      </StyledFormItem>
    </Form>
  );
};
