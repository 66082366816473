import React from 'react';
import { HandbookPrintView } from '../../components/organisms/formEditor/print';
import PrivateRoute from '../../components/organisms/router/privateRoute';
import { app } from '../../routing/routes';
import { CompanyEdit } from './company';
import { HandbookEdit, HandbookFormEditor, HandbookList, HandbookMandateList, HandbookNew } from './handbooks';
import Profile from './profile';
import Welcome from './welcome';

const applicationRoutes = () => {
  return [
    <PrivateRoute exact path={app.index()} key={app.index()} component={Welcome} />,

    <PrivateRoute exact path={app.profile()} key={app.profile()} component={Profile} />,

    <PrivateRoute exact path={app.company.edit()} key={app.company.edit()} component={CompanyEdit} />,

    <PrivateRoute authorizeMandate exact path={app.mandate.list()} key={app.mandate.list()} component={HandbookMandateList} />,
    <PrivateRoute authorizeMandate exact path={app.mandate.edit()} key={app.mandate.edit()} render={() => <HandbookEdit isMandate />} />,
    <PrivateRoute
      authorizeMandate
      exact
      path={app.mandate.forms.edit()}
      key={app.mandate.forms.edit()}
      render={() => <HandbookFormEditor isMandate />}
    />,

    <PrivateRoute authorizeCustomer exact path={app.handbooks.list()} key={app.handbooks.list()} component={HandbookList} />,
    <PrivateRoute authorizeCustomer exact path={app.handbooks.new()} key={app.handbooks.new()} component={HandbookNew} />,
    <PrivateRoute authorizeCustomer exact path={app.handbooks.edit()} key={app.handbooks.edit()} component={HandbookEdit} />,
    <PrivateRoute
      authorizeCustomer
      exact
      path={app.handbooks.forms.edit()}
      key={app.handbooks.forms.edit()}
      component={HandbookFormEditor}
    />,

    <PrivateRoute exact path={app.handbooks.print()} key={app.handbooks.print()} component={HandbookPrintView} />,
  ];
};

export default applicationRoutes;
