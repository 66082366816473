import breakpoints from './breakpoints';

export const layoutSpacingMap = {
  [breakpoints.mobile]: '24px',
  [breakpoints.tabletPortrait]: '24px',
  [breakpoints.tabletLandscape]: '24px',
  [breakpoints.desktopSmall]: '24px',
  [breakpoints.desktopLarge]: '24px',
};

export const elementSpacing = '24px';

const generateValueForAllBreakpoints = (property: string) => {
  return `${property}: ${layoutSpacingMap[breakpoints.mobile]};`;
};

export const layoutSpacings = {
  margin: generateValueForAllBreakpoints('margin'),
  marginTop: generateValueForAllBreakpoints('margin-top'),
  marginLeft: generateValueForAllBreakpoints('margin-left'),
  marginRight: generateValueForAllBreakpoints('margin-right'),
  marginBottom: generateValueForAllBreakpoints('margin-bottom'),

  padding: generateValueForAllBreakpoints('padding'),
  paddingTop: generateValueForAllBreakpoints('padding-top'),
  paddingLeft: generateValueForAllBreakpoints('padding-left'),
  paddingRight: generateValueForAllBreakpoints('padding-right'),
  paddingBottom: generateValueForAllBreakpoints('padding-bottom'),
};
