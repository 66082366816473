import { Tag } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import LastUpdatedAt from './lastUpdatedAt';

interface Props {
  title?: string;
  status?: string;
  statusToCheck?: string;
  date?: Date;
  millis?: number;
  translationPrefix: string;
}

const StyledTag = styled(Tag)`
  margin-left: 16px;
`;

const StyledStatusRow = styled.div`
  small {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.45);
  }

  svg {
    fill: rgba(0, 0, 0, 0.45);
  }
`;

const StyledIndicator = styled.span`
  display: inline-block;
  margin-left: 16px;
  font-size: 16px;

  transform: scale(1);
  animation: pulse 2s infinite;

  @keyframes pulse {
    0% {
      transform: scale(0.75);
    }

    70% {
      transform: scale(1);
    }

    100% {
      transform: scale(0.75);
    }
  }
`;

const StatusRow: React.FC<Props> = (props) => {
  const { title, statusToCheck, status, date, millis, translationPrefix } = props;
  const { t } = useTranslation();

  return (
    <StyledStatusRow>
      {status && (
        <div>
          {title ?? ''}&nbsp;
          <StyledTag color={t(`${translationPrefix}Color.${status ?? ''}`)}>{t(`${translationPrefix}.${status ?? ''}`)}</StyledTag>
          &nbsp;
          {status === statusToCheck && <LastUpdatedAt small label={t('formEditor.general.lastSavedAt')} milliSeconds={millis} />}
          {status !== statusToCheck && <small>{t(`general.${status?.toLowerCase() ?? ''}At`, { date: date?.toLocaleDateString() })}</small>}
          <StyledIndicator id="save-indicator-portal" />
        </div>
      )}
    </StyledStatusRow>
  );
};

export default StatusRow;
