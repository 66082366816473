import { WarningOutlined } from '@ant-design/icons';
import { Button, Modal, Tabs } from 'antd';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import MainLayout, { StyledChildrenContainer } from '../../../../components/layouts/main';
import LoadingIndicator from '../../../../components/molecules/loadingIndicator';
import WillaxPageHeader from '../../../../components/molecules/pageHeader';
import StatusRow from '../../../../components/molecules/statusRow';
import { StyledTabChildContainer, StyledTabContainer } from '../../../../components/molecules/tabs';
import BlueprintFormEditor from '../../../../components/organisms/formEditor';
import { firestore } from '../../../../firebase/init';
import { admin } from '../../../../routing/routes';
import { publishBlueprint, updateBlueprint } from '../../../../services/firebase/blueprints';
import { UserContext } from '../../../../shared/contexts';
import { useNotifications } from '../../../../shared/notifications';
import { userIsAdmin } from '../../../../shared/user';
import { Blueprint, BlueprintStatus, Company, CustomerForm, Handbook, Product } from '../../../../types/firebase';
import { blueprintIdFromBlueprint, firestoreBlueprintPath } from '../../../../types/shared/blueprints';
import BlueprintAttachment from './attachments';
import FormSettings from './formSettings';
import BlueprintSettings from './settings';

interface URLParams {
  blueprintId?: string;
}

export const BlueprintEdit: React.FC<unknown> = (_props) => {
  const { blueprintId } = useParams<URLParams>();
  const history = useHistory();
  const { t } = useTranslation();
  const { userInfo } = useContext(UserContext);
  const { notifyGeneralError, notifySaveSuccess } = useNotifications(t('admin.blueprints.single'));
  const [blueprint, setBlueprint] = useState<Blueprint | undefined>(undefined);
  const isAdmin = userIsAdmin(userInfo?.idTokenResult);
  const readonly = blueprint?.status !== 'DRAFT';

  useEffect(() => {
    return onSnapshot(doc(firestore, firestoreBlueprintPath(blueprintId ?? 'will-never-match')), async (snapshot) => {
      if (!snapshot.exists()) history.push(admin.blueprints.list());

      const blueprint = snapshot.data() as Blueprint;
      if (blueprint?.productRefs) {
        blueprint.products = await Promise.all(blueprint.productRefs?.map(async (pr) => (await getDoc(pr)).data() as Product));
      }

      setBlueprint(blueprint);
    });
  }, [blueprintId, history, notifyGeneralError]);

  const onBlueprintContentSave = useCallback(
    (updatedBlueprint: Blueprint) => updateBlueprint(updatedBlueprint).then(notifySaveSuccess).catch(notifyGeneralError),
    [notifySaveSuccess, notifyGeneralError],
  );

  const onBlueprintPublish = useCallback(() => {
    if (!blueprint) return Promise.resolve();

    return publishBlueprint(blueprint)
      .then(notifySaveSuccess)
      .then(() => history.push(admin.blueprints.list()))
      .catch((e: Error) => {
        console.error(e);
        notifyGeneralError();
      });
  }, [blueprint, notifyGeneralError, notifySaveSuccess, history]);

  const onBlueprintPublishCallback = () => {
    Modal.confirm({
      title: t('admin.blueprints.publishConfirm.title'),
      icon: <WarningOutlined />,
      content: t('admin.blueprints.publishConfirm.description'),
      okText: t('general.form.ok'),
      cancelText: t('general.form.cancel'),
      onOk: onBlueprintPublish,
    });
  };

  const openPrint = () => window.open(admin.blueprints.print(false, { blueprintId }), '_blank');

  return (
    <MainLayout
      pageHeader={
        <WillaxPageHeader
          title={
            <StatusRow
              title={blueprint?.title}
              statusToCheck={BlueprintStatus.Draft}
              date={blueprint?.updatedAt.toDate()}
              millis={blueprint?.updatedAt.toMillis()}
              status={blueprint?.status}
              translationPrefix="admin.blueprints.status"
            />
          }
          extra={
            <>
              {blueprint?.status === 'DRAFT' && (
                <Button size="large" type="primary" onClick={onBlueprintPublishCallback}>
                  {t('admin.blueprints.publish')}
                </Button>
              )}
              <Button size="large" type="ghost" target="_blank" onClick={openPrint} rel="noopener noreferrer">
                {t('private.handbooks.print')}
              </Button>
            </>
          }
          routes={[
            { path: admin.blueprints.list(), breadcrumbName: t('admin.header.blueprints') },
            { path: '', breadcrumbName: blueprint ? blueprintIdFromBlueprint(blueprint) : '' },
          ]}
        />
      }
    >
      {!blueprint && (
        <StyledChildrenContainer>
          <LoadingIndicator />
        </StyledChildrenContainer>
      )}

      {!!blueprint && (
        <StyledChildrenContainer>
          <BlueprintEditContent
            blueprint={blueprint}
            onSave={onBlueprintContentSave}
            isAdmin={isAdmin}
            readonly={readonly}
            isAllowedToWrite
          />
        </StyledChildrenContainer>
      )}
    </MainLayout>
  );
};

export default BlueprintEdit;

interface ContentProps {
  blueprint: Blueprint;
  onSave: (blueprint: Blueprint) => Promise<void>;
  isAdmin: boolean;
  readonly: boolean;
  isMandate?: boolean;
  isAllowedToWrite: boolean;
  company?: Company;
  handbook?: Handbook;
}

export const BlueprintEditContent: React.FC<ContentProps> = (props) => {
  const { blueprint, onSave, isAdmin, readonly, isMandate, company, handbook, isAllowedToWrite } = props;
  const { t } = useTranslation();
  return (
    <StyledTabContainer className="card-container">
      <Tabs type="card" defaultActiveKey="form">
        <Tabs.TabPane tab={t('admin.blueprints.edit.tabs.form')} key="form">
          <StyledTabChildContainer>
            <BlueprintFormEditor
              blueprint={blueprint}
              onSave={onSave}
              isAdminEditing={isAdmin}
              readonly={readonly}
              isMandate={isMandate}
              isAllowedToWrite={isAllowedToWrite}
            />
          </StyledTabChildContainer>
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('admin.blueprints.edit.tabs.attachments')} key="attachments">
          <StyledTabChildContainer>
            <BlueprintAttachment company={company} handbook={handbook} blueprint={blueprint} isAdminEditing={isAdmin} readonly={readonly} />
          </StyledTabChildContainer>
        </Tabs.TabPane>
        {isAdmin && (
          <Tabs.TabPane tab={t('admin.blueprints.edit.tabs.settings')} key="settings">
            <StyledTabChildContainer>
              <BlueprintSettings blueprint={blueprint} />
            </StyledTabChildContainer>
          </Tabs.TabPane>
        )}
        {!isAdmin && (
          <Tabs.TabPane tab={t('admin.blueprints.edit.tabs.settings')} key="settings">
            <StyledTabChildContainer>
              <FormSettings customerForm={blueprint as CustomerForm} readonly={readonly} onSave={onSave} />
            </StyledTabChildContainer>
          </Tabs.TabPane>
        )}
      </Tabs>
    </StyledTabContainer>
  );
};
