import { doc } from 'firebase/firestore';
import { firestore } from '../firebase/init';
import { Product } from '../types/firebase';
import collections from '../types/shared/collections';

export const productDocPathForProduct = (product: Product) => `${collections.products}/${productIdFromProduct(product)}`;
export const productIdFromProduct = (product: Product) => {
  return `${product.enNorm}-${product.language}-${product.variant ?? ''}-${product.version ? `v${product.version}` : ''}`;
};
export const productIsInUse = (product: Product) => {
  const blueprintKeys = Object.keys(product.blueprints ?? {});
  const referencedForms = blueprintKeys.filter((key) => product.blueprints[key] === true);
  return referencedForms.length > 0;
};

export const productsInUsePublished = (product: Product) => {
  const blueprintKeys = Object.keys(product.blueprintsPublished ?? {});
  const referencedForms = blueprintKeys.filter((key) => product.blueprintsPublished[key] === true);
  return referencedForms.length > 0;
};

export const firestoreProductPath = (id: string) => `/${collections.products}/${id}`;
export const productGetRef = (product: Product) => doc(firestore, firestoreProductPath(productIdFromProduct(product)));
