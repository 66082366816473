import { DocumentReference, DocumentData, Timestamp, getDoc } from 'firebase/firestore';
import { BaseCollectionObject, Product, WPKLocation, WPKUser } from '.';
import { HandbookStatus } from './handbookTypes';

export interface Handbook extends BaseCollectionObject {
  name: string;
  status: HandbookStatus;
  language: string;
  locationRef?: DocumentReference<DocumentData> | null;
  productRefs?: DocumentReference<DocumentData>[] | null;
  consultantRef?: DocumentReference<DocumentData> | null;
  certifierRef?: DocumentReference<DocumentData> | null;
  instructorRef?: DocumentReference<DocumentData> | null;
  companyRef: DocumentReference<DocumentData>;
  nextHandbookRef?: DocumentReference<DocumentData> | null;
  previousHandbookRef?: DocumentReference<DocumentData> | null;
  isArchived?: boolean;
  version: {
    major: number;
    minor: number;
  };
  certification: HandbookCertification | null;
  constentGiven?: boolean;
}

export interface HandbookCertification {
  validUntil?: Timestamp | null;
  certificationNumber: string;
}

export interface HandbookFormData extends Handbook {
  products?: Product[];
  location?: WPKLocation;
  consultant?: WPKUser;
  certifier?: WPKUser;
  instructor?: WPKUser;
}

export const handbookToHandbookFormData = async (handbook?: Handbook): Promise<HandbookFormData | undefined> => {
  if (!handbook) return undefined;

  const products: Product[] = await (async () => {
    if (!handbook.productRefs) return [] as Product[];

    const loadedProducts = (await Promise.all(handbook.productRefs?.map(getDoc))).map((d) => d.data() as Product);
    return loadedProducts;
  })();

  const formData: HandbookFormData = {
    ...handbook,
    location: handbook.locationRef ? ((await getDoc(handbook.locationRef)).data() as WPKLocation) : undefined,
    products,
    consultant: handbook.consultantRef ? ((await getDoc(handbook.consultantRef)).data() as WPKUser) : undefined,
    certifier: handbook.certifierRef ? ((await getDoc(handbook.certifierRef)).data() as WPKUser) : undefined,
  };
  return formData;
};
