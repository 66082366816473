import { Typography } from 'antd';
import styled from 'styled-components';
import colors from '../../styles/colors';
import { layoutSpacings } from '../../styles/spacings';
import { StyledChildrenContainer } from '../layouts/main';

export const StyledHandbookHeader = styled.div`
  @media print {
    page-break-after: always;

    & > * {
      display: block;
    }
  }
`;

export const StyledFormContainer = styled.div`
  @media print {
    page-break-after: always;
  }
`;

export const StyledFormHeader = styled.div<{ backgroundColor: string }>`
  @media print {
    background-color: ${(props) => props.backgroundColor};
    ${layoutSpacings.padding}
    padding-left: 12px;
    padding-right: 12px;
  }
`;

export const StyledFormSubHeader = styled.div`
  @media print {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    margin-top: 0.3cm;
  }
`;

export const StyledFormTitle = styled(Typography.Title)`
  color: ${colors.white} !important;
  margin-bottom: 0 !important;
`;

export const StyledPrintHandbook = styled.div`
  @media print {
    ${StyledChildrenContainer} {
      display: block;
    }

    .ant-alert,
    .unchecked-item {
      display: none;
    }

    .ant-card {
      page-break-inside: avoid;
      margin-top: 0.5cm;
    }

    .ant-card-head {
      background-color: ${colors.deactivated};
      padding: 0 12px;
    }

    .ant-card-head-title {
      font-size: 12pt;
    }

    .ant-card-extra {
      display: none;
    }

    .ant-card-body {
      background-color: ${colors.white};
      padding: 12px;

      p,
      textarea,
      input {
        font-size: 10pt;
      }
      .ant-typography {
        font-size: 10pt;
        font-weight: bold;
      }
      .form-checkbox-input {
        margin-left: 24px !important;
        font-size: 10pt;
        color: rgba(0, 0, 0, 0.65);
      }
    }

    .ant-checkbox-wrapper {
      margin-right: -16px;
    }

    li > .ant-row,
    .ant-row.ant-form-item {
      margin-bottom: 0;
    }

    .ant-input,
    .ant-input-disabled {
      &,
      &:focus {
        background-color: transparent;
        border: none;
      }
    }

    .ant-input.ant-input-lg.ant-input-disabled {
      padding-left: 0;
    }

    input[disabled] {
      color: black;
    }

    .ant-checkbox-wrapper-disabled + span {
      margin-left: 24px !important;
    }
  }
`;

export const StyledImage = styled.img`
  width: 100%;
  height: auto;
`;

export const StyledImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  img {
    max-width: 6cm;
    max-height: 4cm;
    height: auto;
    width: auto;
  }
`;

export const StyledAddress = styled.address`
  margin-top: 1cm;
`;

export const StyledMainTitle = styled(Typography.Title)`
  margin-top: 1.5cm;
`;

export const StyledFooterLogo = styled.img`
  margin-top: 1.5cm;
  width: 100%;
  height: auto;
`;
