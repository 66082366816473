import { Button, Form, Input } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as firebaseAuth from '../../../services/firebase/auth';
import { useNotifications } from '../../../shared/notifications';

const PasswordChangeForm: React.FC<unknown> = (_props) => {
  const { t } = useTranslation();
  const [, forceUpdate] = useState('');
  const [form] = Form.useForm();

  useEffect(() => {
    forceUpdate('');
  }, []);

  const { notifyGeneralError, notifySaveSuccess } = useNotifications(t('public.login.password'));

  const changePassword = (values: { newPassword?: string; newPasswordRepeat?: string }) => {
    if (values.newPassword) {
      firebaseAuth.changePassword(values.newPassword?.valueOf(), (success) => {
        if (success) {
          notifySaveSuccess();
          form.resetFields();
        } else {
          notifyGeneralError();
        }
      });
    }
  };

  return (
    <Form form={form} onFinish={changePassword} layout="vertical">
      <Title level={3}>{t('private.profile.password.change')}</Title>

      <Form.Item
        name="newPassword"
        rules={[{ required: true, message: t('general.form.required') }]}
        label={<span>{t('private.profile.password.newPassword')}</span>}
      >
        <Input.Password size="large"></Input.Password>
      </Form.Item>

      <Form.Item
        name="newPasswordRepeat"
        dependencies={['newPassword']}
        label={<span>{t('private.profile.password.newPasswordRepeat')}</span>}
        rules={[
          {
            required: true,
            message: t('public.login.passwordRequired'),
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve();
              }
              return Promise.reject(t('public.login.passwordsNotMatching'));
            },
          }),
        ]}
      >
        <Input.Password size="large" />
      </Form.Item>

      <Form.Item shouldUpdate={true}>
        {() => (
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            disabled={!form.isFieldsTouched(true) || !!form.getFieldsError().filter(({ errors }) => errors.length).length}
          >
            {t('general.form.save')}
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};

export default PasswordChangeForm;
