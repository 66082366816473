import React from 'react';

import { Redirect } from 'react-router-dom';
import { authentication } from '../../routing/routes';

const FourOhFour: React.FC<unknown> = (_props) => {
  return <Redirect to={authentication.login()} />;
};

export default FourOhFour;
