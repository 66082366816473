import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { firestore } from '../firebase/init';
import { availableResourceLanguages } from '../i18n/i18n';
import { ApplicationContext, UserContext } from '../shared/contexts';
import { UnsubscribeFunction } from '../types/firebase';
import { WPKUser } from '../types/firebase';
import { Company } from '../types/firebase/collections/company';
import adminRoutes from './admin';
import applicationRoutes from './application';
import FourOhFour from './public/404';
import loginRoutes from './public/login';
import collections from '../types/shared/collections';
import './app.less';
import { IdTokenResult, User } from 'firebase/auth';
import { doc, onSnapshot } from 'firebase/firestore';

interface Props extends WithTranslation {
  userInfo?: {
    firebaseUser: User;
    idTokenResult: IdTokenResult;
  };
}

let unsubscribeUser: UnsubscribeFunction = null;
let unsubscribeCompany: UnsubscribeFunction = null;

const App: React.FC<Props> = (props) => {
  const { userInfo } = props;
  const availableLanguages = Object.keys(availableResourceLanguages);
  const [firestoreUser, setFirestoreUser] = useState<WPKUser | undefined>(undefined);
  const [company, setCompany] = useState<Company | undefined>(undefined);

  useEffect(() => {
    if (userInfo) {
      const { firebaseUser } = userInfo;
      unsubscribeUser = onSnapshot(doc(firestore, collections.users, firebaseUser.uid), (doc) => setFirestoreUser(doc.data() as WPKUser));
      unsubscribeCompany = onSnapshot(doc(firestore, collections.companies, firebaseUser.uid), (doc) => setCompany(doc.data() as Company));
    } else {
      setFirestoreUser(undefined);
      setCompany(undefined);
    }

    return () => {
      unsubscribeUser && unsubscribeUser();
      unsubscribeCompany && unsubscribeCompany();
    };
  }, [userInfo]);

  const propagateLogout = () => {
    unsubscribeUser && unsubscribeUser();
  };

  return (
    <ApplicationContext.Provider value={{ propagateLogout, availableLanguages }}>
      <UserContext.Provider value={{ userInfo, firestoreUser, company }}>
        <Router>
          <Switch>
            {loginRoutes()}
            {adminRoutes()}
            {applicationRoutes()}
            <Route component={FourOhFour} />
          </Switch>
        </Router>
      </UserContext.Provider>
    </ApplicationContext.Provider>
  );
};

export default withTranslation()(App);
