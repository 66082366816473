import { ExclamationOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, List, Modal, Radio, Typography } from 'antd';
import { FormProps } from 'antd/lib/form';
import Title from 'antd/lib/typography/Title';
import { groupBy, orderBy as lodashOrderBy } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { StyledSelectButton } from '../../../components/atoms/buttons';
import FormActions from '../../../components/molecules/formActions';
import { StyledRadio } from '../../../components/molecules/radio';
import { firestore } from '../../../firebase/init';
import { app } from '../../../routing/routes';
import { companyGetRef } from '../../../shared/company';
import { ApplicationContext } from '../../../shared/contexts';
import { Company, Handbook, HandbookFormData, handbookToHandbookFormData, Product, WPKLocation, WPKUser } from '../../../types/firebase';
import { HandbookStatus } from '../../../types/firebase/collections/handbookTypes';
import { MandateRoles, WpkRole } from '../../../types/global';
import collections from '../../../types/shared/collections';
import ProductPicker from '../../admin/products/picker';
import { onSnapshot, query, orderBy, collection, where, getDoc } from 'firebase/firestore';

const StyledDeactivateButton = styled(Button)`
  margin-top: 64px;
`;

const StyledConsent = styled(Form.Item)`
  margin-top: 64px;
`;

const StyledFormActions = styled(FormActions)`
  margin-top: 32px;
`;

const Consultant: React.FC<{ c: WPKConsultant }> = (props) => {
  const { c } = props;
  return (
    <StyledRadio value={c.key} key={c.key}>
      <b>{c.companyName}</b>&nbsp;
      {c.company.website && (
        <a href={c.company.website} target="_blank" rel="noopener noreferrer">
          ({c.company.website})
        </a>
      )}
    </StyledRadio>
  );
};

type WPKConsultant = WPKUser & { company: Company };

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFormValuesChanged: (values: any) => Promise<void>;
  onDeactivate?: (handbook?: Handbook) => Promise<void>;
  form: FormProps['form'];
  handbook?: HandbookFormData;
  company: Company;
  isMandate?: boolean;
}

export const HandbookForm: React.FC<Props> = (props) => {
  const { onFormValuesChanged, form, handbook, company, onDeactivate, isMandate } = props;
  const { availableLanguages } = useContext(ApplicationContext);
  const { t } = useTranslation();
  const editMode = !!handbook;

  const [locations, setLocations] = useState<WPKLocation[]>([]);
  const [mandateUsers, setMandateUsers] = useState<WPKConsultant[]>([]);
  const [showProductPicker, setShowProductPicker] = useState(false);
  const [handbookCopy, setHandbookCopy] = useState<HandbookFormData | undefined>(undefined);
  const [isSaving, setIsSaving] = useState(false);

  const isEditingExistingHandbook = !handbook || handbook?.status === 'DRAFT';

  useEffect(() => {
    // only run this once
    if (!handbook) return;
    handbookToHandbookFormData(handbook)
      .then((formData) => {
        if (!formData) return;

        setHandbookCopy(formData);
        form?.setFieldsValue(formData);
      })
      .catch((e) => console.error(e));
  }, [handbook, form]);

  useEffect(() => {
    const cleanups = [
      onSnapshot(query(collection(firestore, companyGetRef(company).path, collections.locations), orderBy('name')), (snapshot) =>
        setLocations(snapshot.docs.map((ds) => ds.data() as WPKLocation)),
      ),

      onSnapshot(query(collection(firestore, collections.users), where('role', 'in', MandateRoles)), async (snapshot) =>
        setMandateUsers(
          await Promise.all(
            snapshot.docs.map(async (ds) => {
              const user = ds.data() as WPKUser;
              const company = (await getDoc(user.companyRef)).data() as Company;
              return { ...user, company } as WPKConsultant;
            }),
          ),
        ),
      ),
    ];

    return () => cleanups.forEach((c) => c());
  }, [company, setLocations]);

  const onProductsSelected = (products: Product[]) => {
    const previousProductKeys = (handbookCopy?.products ?? []).map((p) => p.key);
    const newProductsToAdd = products.filter((p) => previousProductKeys.indexOf(p.key) === -1);
    const combinedNewProducts = [...(handbookCopy?.products ?? []), ...newProductsToAdd];

    const groupedProductsByENNumber = groupBy(combinedNewProducts, (p) => p.enNorm);
    const groupedProductDuplicates =
      Object.keys(groupedProductsByENNumber)
        .map((key) => (groupedProductsByENNumber[key].length > 1 ? key : ''))
        .filter((key) => !!key) ?? [];

    if (groupedProductDuplicates.length === 0) {
      setHandbookCopy({ ...((handbookCopy ?? {}) as Handbook), products: combinedNewProducts });
      form?.setFieldsValue({ products: combinedNewProducts });
      setShowProductPicker(false);
    } else {
      Modal.error({
        centered: true,
        title: t('private.handbooks.create.duplicateENNumberWarning.title'),
        content: t('private.handbooks.create.duplicateENNumberWarning.message', { enNumbers: groupedProductDuplicates.join(',') }),
      });
    }
  };

  const onFinishCallback = useCallback(() => {
    setIsSaving(true);
    onFormValuesChanged(handbookCopy).finally(() => setIsSaving(false));
  }, [onFormValuesChanged, handbookCopy]);

  const deactivateHandbookCallback = () => {
    Modal.confirm({
      title: t('private.handbooks.deactivateConfirm.title'),
      icon: <ExclamationOutlined />,
      content: t('private.handbooks.deactivateConfirm.description'),
      okText: t('general.form.ok'),
      cancelText: t('general.form.cancel'),
      onOk: () => onDeactivate?.(handbook),
    });
  };

  const renderListItem = (product: Product, onDelete: (index: number) => void, index: number, allowDelete: boolean) => (
    <List.Item actions={allowDelete ? [<MinusCircleOutlined key="delete" onClick={() => onDelete(index)} />] : []}>
      <List.Item.Meta title={`${product.enNorm} ${product.variant ?? ''} ${product.name}`} />
      <b style={{ marginLeft: 40 }}>CHF {product.price}</b>
    </List.Item>
  );

  const consultants = mandateUsers.filter((u) => u.role === WpkRole.Consultant);
  const certifiers = mandateUsers.filter((u) => u.role === WpkRole.Certifier);

  const totalProductsAmount = handbookCopy?.products?.reduce((acc, prod) => acc + prod.price, 0) ?? 0;

  const canAdjustProducts =
    !handbookCopy || [HandbookStatus.Draft, HandbookStatus.Init].includes(handbookCopy?.status ?? HandbookStatus.Draft);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinishCallback}
      initialValues={handbookCopy}
      onValuesChange={(changset, values) => setHandbookCopy(values as HandbookFormData)}
    >
      <Title level={4}>{t('general.languages.title')}</Title>
      <p>{t('private.handbooks.create.fields.language.introduction')}</p>

      <Form.Item name="language" rules={[{ required: true, message: t('general.form.required') }]}>
        <Radio.Group buttonStyle="solid" disabled={editMode}>
          {availableLanguages?.map((lang: string) => (
            <Radio.Button key={lang} value={lang}>
              {t(`general.languages.names.${lang}`)}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="name"
        rules={[{ required: true, message: t('general.form.required') }]}
        label={t('private.handbooks.create.fields.name.label')}
      >
        <Input
          disabled={handbook && [HandbookStatus.Released, HandbookStatus.Draft].indexOf(handbook.status) === -1 && editMode}
          placeholder={t('private.handbooks.create.fields.name.introduction')}
        />
      </Form.Item>

      <Title level={4}>{t('private.location.single')}</Title>

      <Form.Item
        name="location"
        valuePropName="key"
        getValueFromEvent={(e) => locations.filter((l) => l.key === e.target.value)[0]}
        rules={[{ required: true, message: t('general.form.required') }]}
      >
        <Radio.Group
          value={handbookCopy?.location?.key}
          disabled={handbook && [HandbookStatus.Released, HandbookStatus.Draft].indexOf(handbook.status) === -1 && editMode}
        >
          {locations.map((l, _index) => (
            <StyledRadio value={l.key} key={l.key}>
              <b>{l.name}</b> {l.street}, {l.zipCode} {l.city}
            </StyledRadio>
          ))}
        </Radio.Group>
      </Form.Item>

      <Title level={4}>{t('admin.header.products')}</Title>
      <Typography.Paragraph>{t('private.handbooks.create.fields.product.introduction')}</Typography.Paragraph>
      {showProductPicker && (
        <ProductPicker
          visible={true}
          onCancel={() => setShowProductPicker(false)}
          onOk={onProductsSelected}
          language={form?.getFieldValue('language')}
          pickerShowOnlyUsedItems
        />
      )}

      <Form.Item>
        <Form.Item
          name="products"
          valuePropName="products"
          noStyle
          rules={[
            { required: true, message: t('general.form.required') },
            () => ({
              validator(_rule, value: Product[]) {
                if (value && value.length > 10) {
                  return Promise.reject(t('private.handbooks.create.fields.product.limitation'));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Form.List name="products">
            {(fields, { remove }) => {
              if (!handbookCopy || !handbookCopy.products) return;
              const products = handbookCopy.products ?? [];
              return (
                fields.length > 0 && (
                  <List
                    className="demo-loadmore-list"
                    itemLayout="horizontal"
                    dataSource={products}
                    renderItem={(item: Product, index: number) =>
                      renderListItem(item, remove, index, fields.length > 1 && canAdjustProducts)
                    }
                  />
                )
              );
            }}
          </Form.List>

          {canAdjustProducts && (
            <StyledSelectButton
              type="default"
              onClick={() => setShowProductPicker(true)}
              disabled={
                !form?.getFieldValue('language') || handbookCopy
                  ? handbookCopy?.products
                    ? handbookCopy.products.length >= 10
                    : false
                  : false
              }
            >
              <PlusOutlined /> {t('admin.blueprints.create.fields.product.label')}
            </StyledSelectButton>
          )}
        </Form.Item>
      </Form.Item>

      <Title level={4}>{t('general.roles.names.consultant')}</Title>
      <Typography.Paragraph>{t('private.handbooks.create.fields.consultants.introduction')}</Typography.Paragraph>

      <Form.Item name="consultant" valuePropName="key" getValueFromEvent={(e) => consultants.filter((c) => c.key === e.target.value)[0]}>
        <Radio.Group disabled={(handbook?.status !== 'DRAFT' && editMode) || isMandate} value={handbookCopy?.consultant?.key}>
          <StyledRadio value={undefined} key="none">
            {t('private.handbooks.create.fields.consultants.noConsultant')}
          </StyledRadio>
          {consultants.map((c, index) => (
            <Consultant key={index} c={c} />
          ))}
        </Radio.Group>
      </Form.Item>

      <Title level={4}>{t('general.roles.names.certifier')}</Title>
      <Typography.Paragraph>{t('private.handbooks.create.fields.certifier.introduction')}</Typography.Paragraph>

      <Form.Item name="certifier" valuePropName="key" getValueFromEvent={(e) => certifiers.filter((c) => c.key === e.target.value)[0]}>
        <Radio.Group
          disabled={isMandate || (handbook && [HandbookStatus.Released, HandbookStatus.Draft].indexOf(handbook.status) === -1 && editMode)}
          value={handbookCopy?.certifier?.key}
        >
          <StyledRadio value={undefined} key="none">
            {t('private.handbooks.create.fields.certifier.noConsultant')}
          </StyledRadio>
          {certifiers.map((c, index) => (
            <Consultant key={index} c={c} />
          ))}
        </Radio.Group>
      </Form.Item>

      {!editMode && (
        <StyledConsent name="consentGiven" valuePropName="checked" rules={[{ required: true, message: t('general.form.required') }]}>
          <Checkbox>
            <span dangerouslySetInnerHTML={{ __html: t('private.handbooks.create.consent', { amount: totalProductsAmount }) }} />
          </Checkbox>
        </StyledConsent>
      )}

      {(isEditingExistingHandbook || handbook?.status === HandbookStatus.Released) && (
        <StyledFormActions isSaving={isSaving} cancelUrl={!editMode ? app.handbooks.list() : undefined} hideSaveAndNew />
      )}

      {handbook &&
        [HandbookStatus.CertApproved, HandbookStatus.CertRejected, HandbookStatus.Released].indexOf(handbook.status) !== -1 &&
        !handbook.isArchived && (
          <StyledDeactivateButton danger onClick={deactivateHandbookCallback}>
            {t('private.handbooks.deactivateConfirm.title')}
          </StyledDeactivateButton>
        )}
    </Form>
  );
};

export default HandbookForm;
