import { InboxOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Form, Input, Radio, Tooltip, Upload } from 'antd';
import { FormProps } from 'antd/lib/form';
import { Store } from 'antd/lib/form/interface';
import Title from 'antd/lib/typography/Title';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormActions from '../../../components/molecules/formActions';
import { admin } from '../../../routing/routes';
import { acceptedFiles } from '../../../services/firebase/documents';
import { ApplicationContext } from '../../../shared/contexts';
import { alwaysFalse } from '../../../shared/functions';
import { FirestoreDocument } from '../../../types/firebase';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFormValuesChanged: (values: any) => Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSaveAndNew?: () => void;
  hideSaveAndNew?: boolean;
  form: FormProps['form'];
  document?: FirestoreDocument;
}

const DocumentForm: React.FC<Props> = (props) => {
  const { availableLanguages } = useContext(ApplicationContext);
  const { t } = useTranslation();
  const { onFormValuesChanged, onSaveAndNew, form, hideSaveAndNew, document } = props;

  const [isSaving, setIsSaving] = useState(false);

  const editMode = !!document;

  const onSaveCallback = (values: Store) => {
    setIsSaving(true);
    onFormValuesChanged(values).finally(() => setIsSaving(false));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const limitFile = (e: any) => e.fileList.slice(-1);

  return (
    <Form form={form} layout="vertical" onFinish={onSaveCallback} initialValues={document}>
      <Title level={4}>{t('general.languages.title')}</Title>
      <p>{t('admin.documents.create.languageIntroduction')}</p>
      <Form.Item name="language" rules={[{ required: true, message: t('general.form.required') }]}>
        <Radio.Group buttonStyle="solid" disabled={editMode}>
          {availableLanguages?.map((lang: string) => (
            <Radio.Button key={lang} value={lang}>
              {t(`general.languages.names.${lang}`)}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>

      <Title level={4}>{t('admin.documents.create.detailsTitle')}</Title>
      <Form.Item
        name="name"
        rules={[{ required: true, message: t('general.form.required') }]}
        label={
          <span>
            {t('admin.documents.create.fields.name.label')}&nbsp;
            <Tooltip title={t('admin.documents.create.fields.name.introduction')}>
              <QuestionCircleOutlined />
            </Tooltip>
          </span>
        }
      >
        <Input size="large" placeholder={t('admin.documents.create.fields.name.placeholder')} />
      </Form.Item>

      <Form.Item
        name="description"
        rules={[{ required: true, message: t('general.form.required') }]}
        label={t('admin.documents.create.fields.description.label')}
      >
        <Input.TextArea autoSize={{ minRows: 3 }} placeholder={t('admin.documents.create.fields.description.placeholder')} />
      </Form.Item>

      <Form.Item name="adminDescription" label={t('admin.documents.create.fields.adminDescription.label')}>
        <Input.TextArea autoSize={{ minRows: 3 }} placeholder={t('admin.documents.create.fields.adminDescription.placeholder')} />
      </Form.Item>

      <Form.Item label={<span>{t('admin.documents.create.fields.file.label')}</span>}>
        <Form.Item
          name="files"
          valuePropName="fileList"
          getValueFromEvent={limitFile}
          noStyle
          rules={[{ required: true, message: t('general.form.required') }]}
        >
          <Upload.Dragger name="files" beforeUpload={alwaysFalse} accept={acceptedFiles.join(',')}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">{t('admin.documents.create.fields.file.dragText')}</p>
            <p className="ant-upload-hint">{t('admin.documents.create.fields.file.introduction')}</p>
          </Upload.Dragger>
        </Form.Item>
      </Form.Item>

      <FormActions isSaving={isSaving} cancelUrl={admin.documents.list()} saveAndNew={onSaveAndNew} hideSaveAndNew={hideSaveAndNew} />
    </Form>
  );
};

export default DocumentForm;
