import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { authentication } from '../../../routing/routes';
import { GenericObject } from '../../../types/helper';

interface Props<T> {
  params: string[];
  component: React.FC<T>;
}

const useQuery = () => new URLSearchParams(useLocation().search);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type VerifyQueryParams<T = any> = React.FC<Props<T>>;

const VerifyParams: VerifyQueryParams = (props) => {
  const { params, component: Component } = props;

  const query = useQuery();
  const urlQueryObject: GenericObject = params.reduce((acc, key) => ({ ...acc, [key]: query.get(key) }), {});

  const allQueryParametersProvided =
    params
      .map((key, _index) => typeof urlQueryObject[key] !== 'undefined') // filter out all undefineds
      .findIndex((val) => val === false) === -1;

  return allQueryParametersProvided ? <Component {...urlQueryObject} /> : <Redirect to={authentication.login()} />;
};

export default VerifyParams;
