import Modal from 'antd/lib/modal/Modal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ProductList } from '.';
import breakpoints from '../../../styles/breakpoints';
import colors from '../../../styles/colors';
import { Product } from '../../../types/firebase';

interface Props {
  visible: boolean;
  onOk: (products: Product[]) => void;
  onCancel: () => void;
  language: string;
  pickerShowOnlyUsedItems?: boolean;
}

export const StyledPickerModal = styled(Modal)`
  width: 100% !important;

  @media (min-width: ${breakpoints.tabletPortrait}) {
    max-width: 736px;
  }
  @media (min-width: ${breakpoints.tabletLandscape}) {
    max-width: 960px;
  }
  @media (min-width: ${breakpoints.desktopSmall}) {
    max-width: 1168px;
  }
  @media (min-width: ${breakpoints.desktopLarge}) {
    max-width: 1312px;
  }

  .ant-modal-body > *:first-child {
    padding: 0 24px;
  }
`;

const ProductPicker: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const { visible, onOk, onCancel, language, pickerShowOnlyUsedItems } = props;
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);

  const okClicked = () => onOk(selectedProducts);
  const cancelClicked = () => onCancel();
  const onChange = (_selectedRowKeys: React.ReactText[], selectedRows: Product[]) => setSelectedProducts(selectedRows);

  return (
    <StyledPickerModal
      title={false}
      centered
      visible={visible}
      onOk={okClicked}
      onCancel={cancelClicked}
      bodyStyle={{ padding: 0, backgroundColor: colors.background }}
      okText={t('general.form.choose')}
      cancelText={t('general.form.cancel')}
    >
      <ProductList pickerMode pickerOnChange={onChange} pickerFilterLanguage={language} pickerShowOnlyUsedItems={pickerShowOnlyUsedItems} />
    </StyledPickerModal>
  );
};

export default ProductPicker;
