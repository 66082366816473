import { Button, Form, Input } from 'antd';
import { Store } from 'antd/lib/form/interface';
import Title from 'antd/lib/typography/Title';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { updateUser } from '../../../services/firebase/user';
import { useNotifications } from '../../../shared/notifications';
import { WPKUser } from '../../../types/firebase';

const NameChangeForm: React.FC<{ user?: WPKUser }> = (props) => {
  const { user } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { notifyGeneralError, notifySaveSuccess } = useNotifications(t('public.login.name'));

  const changeName = (store: Store) =>
    updateUser({ ...user, ...store } as WPKUser)
      .then(notifySaveSuccess)
      .catch(notifyGeneralError);

  return (
    <Form form={form} onFinish={changeName} layout="vertical" initialValues={user}>
      <Title level={3}>{t('public.login.name')}</Title>

      <Form.Item
        name="firstName"
        rules={[{ required: true, message: t('general.form.required') }]}
        label={<span>{t('private.profile.firstName')}</span>}
      >
        <Input size="large" type="text"></Input>
      </Form.Item>

      <Form.Item
        name="lastName"
        rules={[{ required: true, message: t('general.form.required') }]}
        label={<span>{t('private.profile.lastName')}</span>}
      >
        <Input size="large" type="text"></Input>
      </Form.Item>

      <Form.Item name="description" label={<span>{t('admin.documents.list.header.description')}</span>}>
        <Input.TextArea autoSize={{ minRows: 3 }} />
      </Form.Item>

      <Form.Item>
        <Button size="large" type="primary" htmlType="submit">
          {t('general.form.save')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default NameChangeForm;
