import React from 'react';
import { WPKUser } from '../types/firebase/collections';
import { Company } from '../types/firebase/collections/company';
import { User, IdTokenResult } from '@firebase/auth';

export const UserContext = React.createContext<{
  userInfo?: {
    firebaseUser: User;
    idTokenResult: IdTokenResult;
  };
  firestoreUser?: WPKUser;
  company?: Company;
}>({});

export const ApplicationContext = React.createContext<{
  propagateLogout?: () => void;
  availableLanguages: string[];
}>({ availableLanguages: [] });
