import { MoreOutlined, PaperClipOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { collection, DocumentData, DocumentReference, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { History } from 'history';
import { TFunction } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import SearchBar from '../../../../components/molecules/searchBar';
import { firestore } from '../../../../firebase/init';
import { app } from '../../../../routing/routes';
import { updateCustomerForm } from '../../../../services/firebase/handbooks';
import { handbookGetRef } from '../../../../shared/handbooks';
import { useNotifications } from '../../../../shared/notifications';
import colors from '../../../../styles/colors';
import { layoutSpacings } from '../../../../styles/spacings';
import { Company, CustomerForm, Handbook } from '../../../../types/firebase';
import { HandbookStatus } from '../../../../types/firebase/collections/handbookTypes';
import { blueprintIdFromBlueprint } from '../../../../types/shared/blueprints';
import collections from '../../../../types/shared/collections';

interface Props {
  handbook: Handbook;
  company: Company;
  isMandate?: boolean;
  isAllowedToWrite: boolean;
}

type HandbookFormListRow = { form: CustomerForm; ref: DocumentReference<DocumentData> };

const StyledSearchBar = styled(SearchBar)`
  ${layoutSpacings.marginBottom}
`;

const StyledTableContainer = styled.div`
  tr.deactivated {
    color: ${colors.deactivatedColor};
    text-decoration: line-through;
  }
`;

export const HandbookFormsList: React.FC<Props> = (props) => {
  const history = useHistory();

  const { handbook, company, isMandate, isAllowedToWrite } = props;
  const { t } = useTranslation();
  const { notifySaveSuccess, notifyGeneralError } = useNotifications(t('admin.blueprints.single'));

  const [forms, setForms] = useState<HandbookFormListRow[] | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const handbookRef = handbookGetRef(company, handbook);

    const handbookCollection = collection(firestore, handbookRef.path, collections.forms);

    const whereClauses = [];
    if (searchTerm && searchTerm.length > 0) {
      whereClauses.push(where('searchText', 'array-contains-any', searchTerm.toLowerCase().split(' ')));
    }

    return onSnapshot(query(handbookCollection, ...whereClauses, orderBy('originalKey')), (snapshot) =>
      setForms(
        snapshot.docs.map((d) => ({
          form: d.data() as CustomerForm,
          ref: d.ref,
        })),
      ),
    );
  }, [handbook, company, searchTerm]);

  const onDeactivateCallback = useCallback(
    (row: HandbookFormListRow) =>
      updateCustomerForm({ isActive: !row.form.isActive } as Pick<CustomerForm, 'isActive'>, row.ref)
        .then(notifySaveSuccess)
        .catch((e) => {
          console.error(e);
          notifyGeneralError();
        }),
    [notifySaveSuccess, notifyGeneralError],
  );

  const columns = createColumns(t, history, onDeactivateCallback, isAllowedToWrite, company, handbook, isMandate);

  return (
    <StyledTableContainer>
      <StyledSearchBar onSearchInput={setSearchTerm} placeholderText={t('admin.blueprints.list.placeholder')} />
      <Table
        columns={columns}
        pagination={false}
        dataSource={forms ?? []}
        loading={!forms}
        rowKey={(row) => row.form.key}
        rowClassName={(row) => (row.form.isActive === false ? 'deactivated' : '')}
        locale={{ emptyText: t('general.emptyList') }}
        scroll={{ x: true }}
      />
    </StyledTableContainer>
  );
};

const createColumns = (
  t: TFunction,
  history: History,
  onDeactivate: (row: HandbookFormListRow) => void,
  isAllowedToWrite: boolean,
  company?: Company,
  handbook?: Handbook,
  isMandate?: boolean,
): ColumnsType<HandbookFormListRow> => {
  const cells: ColumnsType<HandbookFormListRow> = [
    {
      title: t('admin.blueprints.list.header.id'),
      dataIndex: 'key',
      key: 'key',
      fixed: true,
      render: (_text, row, _index) => blueprintIdFromBlueprint(row.form),
    },
    {
      title: t('admin.blueprints.list.header.title'),
      key: 'title',
      render: (_text, row, _index) => row.form.title,
    },
    {
      title: t('admin.blueprints.list.header.formType'),
      key: 'type',
      dataIndex: 'type',
      render: (_text, row, _index) => <span>{t(`admin.blueprints.types.${row.form.type}`)}</span>,
    },
    {
      title: t('private.handbooks.edit.forms.list.header.version'),
      key: 'version',
      dataIndex: 'version',
      align: 'right',
      render: (_text, row, _index) => (
        <span>
          {row.form.version.admin}.{row.form.version.customer}
        </span>
      ),
    },
    {
      title: '',
      key: 'attachments',
      width: 24,
      render: (_text, row, _index) =>
        (!!row.form.attachmentRefs && row.form.attachmentRefs.length > 0) ||
        (!!row.form.customerAttachments && row.form.customerAttachments.length > 0) ? (
          <PaperClipOutlined />
        ) : null,
    },
    {
      title: '',
      key: 'actions',
      width: 120,
      render: (_text, row, _index) => {
        const menu = (
          <Menu>
            <Menu.Item>
              <Button size="large" type="link" onClick={() => onDeactivate(row)} disabled={handbook?.status !== 'DRAFT'}>
                {row.form.isActive === false
                  ? t('private.handbooks.edit.forms.list.actions.enable')
                  : t('private.handbooks.edit.forms.list.actions.disable')}
              </Button>
            </Menu.Item>
          </Menu>
        );
        return (
          <>
            {!row.form.isRequired && handbook?.status === 'DRAFT' && isAllowedToWrite && (
              <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
                <Button type="ghost" shape="circle" icon={<MoreOutlined />} size="small" />
              </Dropdown>
            )}
            {handbook && handbook.status !== HandbookStatus.Deactivated && (
              <Button
                type="primary"
                shape="circle"
                icon={<RightOutlined />}
                size="small"
                style={{ marginLeft: 24 }}
                onClick={() => {
                  const data = { handbookId: handbook?.key, companyId: company?.key, formId: row.form.key };
                  const editFunction = isMandate ? app.mandate.forms.edit : app.handbooks.forms.edit;
                  return history.push(editFunction(false, data));
                }}
              />
            )}
          </>
        );
      },
      align: 'right',
    },
  ];

  return cells;
};
