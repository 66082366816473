import {
  DeleteOutlined,
  ExclamationOutlined,
  FileAddOutlined,
  MoreOutlined,
  PlusOutlined,
  RightOutlined,
  SearchOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Menu, Modal, Table, Tabs, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import Title from 'antd/lib/typography/Title';
import { History } from 'history';
import { TFunction } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import StyledListHeader from '../../../components/atoms/listPageHeader';
import { TooltipSubstringText } from '../../../components/atoms/tooltipText';
import MainLayout, { StyledChildrenContainer } from '../../../components/layouts/main';
import EmptyMessage from '../../../components/molecules/emptyMessage';
import LoadingIndicator from '../../../components/molecules/loadingIndicator';
import { LanguageRadioButtonFilter, RadioButtonFilterDefaultValue } from '../../../components/molecules/radioButtonFilter';
import SearchBar from '../../../components/molecules/searchBar';
import { StyledTabChildContainer, StyledTabContainer } from '../../../components/molecules/tabs';
import { firestore } from '../../../firebase/init';
import { admin } from '../../../routing/routes';
import { archiveProduct, deleteProduct, publishProduct } from '../../../services/firebase/products';
import { ApplicationContext, UserContext } from '../../../shared/contexts';
import { noOp } from '../../../shared/functions';
import { useNotifications } from '../../../shared/notifications';
import { productIsInUse } from '../../../shared/products';
import { userIsAdmin } from '../../../shared/user';
import { Product, ProductStatus } from '../../../types/firebase';
import collections from '../../../types/shared/collections';
import { PickerProps } from '../types';
import { collection, onSnapshot, doc, where, query } from 'firebase/firestore';

export const ProductList: React.FC<PickerProps<Product>> = (props) => {
  const { pickerMode, pickerOnChange, pickerFilterLanguage, pickerShowOnlyUsedItems } = props;
  const { t } = useTranslation();
  const [products, setProducts] = useState<Product[] | undefined>(undefined);
  const history = useHistory();
  const { availableLanguages } = useContext(ApplicationContext);
  const { userInfo } = useContext(UserContext);
  const { notifyDeletedSuccess, notifyGeneralError, notifyDeleteConfirm, notifySaveSuccess } = useNotifications<Product>(
    t('admin.products.single'),
  );
  const [selectedLanguage, setSelectedLanguage] = useState(RadioButtonFilterDefaultValue);
  const [searchTerm, setSearchTerm] = useState('');
  const isAdmin = userIsAdmin(userInfo?.idTokenResult);

  const searching = !!searchTerm || selectedLanguage !== RadioButtonFilterDefaultValue;

  useEffect(() => {
    const whereClauses = [];
    if (selectedLanguage !== RadioButtonFilterDefaultValue || !!pickerFilterLanguage) {
      whereClauses.push(where('language', '==', pickerFilterLanguage || selectedLanguage));
    }
    if (searchTerm && searchTerm.length > 0) {
      whereClauses.push(where('searchText', 'array-contains-any', searchTerm.toLowerCase().split(' ')));
    }
    if (pickerShowOnlyUsedItems === true) {
      whereClauses.push(where('status', '==', ProductStatus.Released));
    }

    return onSnapshot(query(collection(firestore, collections.products), ...whereClauses), (snapshot) => {
      const docsData = snapshot.docs.map((doc) => doc.data() as Product);
      setProducts(docsData);
    });
  }, [selectedLanguage, availableLanguages, searchTerm, pickerFilterLanguage, pickerShowOnlyUsedItems]);

  const onDeleteProduct = (product: Product) =>
    deleteProduct(product)
      .then(notifyDeletedSuccess)
      .catch((e) => {
        console.error(e);
        notifyGeneralError();
      });

  const onProductPublish = (product: Product) =>
    publishProduct(product)
      .then(notifySaveSuccess)
      .catch((e) => {
        console.error(e);
        notifyGeneralError();
      });

  const onProductArchive = (product: Product) =>
    archiveProduct(product)
      .then(notifySaveSuccess)
      .catch((e) => {
        console.error(e);
        notifyGeneralError();
      });

  const onProductPublishCallback = (product: Product) => {
    Modal.confirm({
      title: t('admin.products.list.publishConfirm.title'),
      icon: <WarningOutlined />,
      content: t('admin.products.list.publishConfirm.description'),
      okText: t('general.form.ok'),
      cancelText: t('general.form.cancel'),
      onOk: () => onProductPublish(product),
    });
  };

  const onProductArchiveCallback = (product: Product) => {
    Modal.confirm({
      title: t('admin.products.list.archiveConfirm.title'),
      icon: <WarningOutlined />,
      content: t('admin.products.list.archiveConfirm.description'),
      okText: t('general.form.ok'),
      cancelText: t('general.form.cancel'),
      onOk: () => onProductArchive(product),
    });
  };

  const columns = createColumns(
    t,
    (product: Product) => notifyDeleteConfirm(product, onDeleteProduct, noOp),
    onProductPublishCallback,
    onProductArchiveCallback,
    history,
    pickerMode,
  );

  const loading = typeof products === 'undefined' && <LoadingIndicator />;
  const searchEmpty = searching && products && products.length === 0 && (
    <EmptyMessage
      title={t('general.notFound.title', { item: t('admin.products.single') })}
      description={t('general.notFound.description')}
      buttonTitle={t('general.notFound.addButtonTitle', { item: t('admin.products.single') })}
      buttonIcon={<PlusOutlined />}
      to={admin.products.new()}
      logo={<SearchOutlined />}
      enableAddButton={isAdmin}
    />
  );

  const currentProducts = products?.filter((p) => p.status !== ProductStatus.Retired);
  const archivedProducts = products?.filter((p) => p.status === ProductStatus.Retired);

  const currentTable = !loading && currentProducts && currentProducts.length > 0 && (
    <Table
      rowSelection={pickerMode === true ? { onChange: pickerOnChange } : undefined}
      pagination={false}
      dataSource={currentProducts}
      columns={columns}
      locale={{ emptyText: t('general.emptyList') }}
      scroll={{ x: true }}
    />
  );

  const archiveTable = !loading && archivedProducts && archivedProducts.length > 0 && (
    <Table
      rowSelection={pickerMode === true ? { onChange: pickerOnChange } : undefined}
      pagination={false}
      dataSource={archivedProducts}
      columns={columns}
      locale={{ emptyText: t('general.emptyList') }}
      scroll={{ x: true }}
    />
  );

  return (
    <MainLayout
      skipLayout={pickerMode}
      pageHeader={
        <StyledListHeader>
          <Title level={4}>{t('admin.header.products')}</Title>
          <SearchBar
            extra={[
              isAdmin ? (
                <Link key="new" to={admin.products.new()}>
                  <Button icon={<PlusOutlined />} size="large" type="primary">
                    {t('admin.products.empty.addButtonTitle')}
                  </Button>
                </Link>
              ) : null,
            ]}
            placeholderText={t('admin.products.list.placeholder')}
            onSearchInput={setSearchTerm}
            radioFilters={pickerFilterLanguage ? [] : [<LanguageRadioButtonFilter key="language" onItemSelected={setSelectedLanguage} />]}
          />
        </StyledListHeader>
      }
    >
      {pickerMode && currentTable}

      {!pickerMode && (
        <StyledChildrenContainer>
          <StyledTabContainer className="card-container">
            <Tabs type="card" defaultActiveKey="form">
              <Tabs.TabPane tab={t('private.handbooks.list.tabs.current')} key="products">
                <StyledTabChildContainer>
                  {loading}
                  {searchEmpty}
                  {!searching && currentProducts && currentProducts.length === 0 && (
                    <EmptyMessage
                      title={t('admin.products.empty.title')}
                      description={t('admin.products.empty.description')}
                      buttonTitle={t('admin.products.empty.addButtonTitle')}
                      buttonIcon={<PlusOutlined />}
                      to={admin.products.new()}
                      logo={<FileAddOutlined />}
                      enableAddButton={isAdmin}
                    />
                  )}

                  {currentTable}
                </StyledTabChildContainer>
              </Tabs.TabPane>

              <Tabs.TabPane tab={t('private.handbooks.list.tabs.archive')} key="archives">
                <StyledTabChildContainer>{archiveTable}</StyledTabChildContainer>
              </Tabs.TabPane>
            </Tabs>
          </StyledTabContainer>
        </StyledChildrenContainer>
      )}
    </MainLayout>
  );
};

export default ProductList;

const createColumns = (
  t: TFunction,
  showDeleteConfirm: (product: Product) => void,
  showPublishConfirm: (product: Product) => void,
  showArchiveConfirm: (product: Product) => void,
  history: History,
  pickerMode?: boolean,
): ColumnsType<Product> => {
  let fields: ColumnsType<Product> = [
    {
      title: t('admin.products.list.header.id'),
      dataIndex: 'enNorm',
      key: 'enNorm',
      fixed: true,
    },
    {
      title: t('admin.products.list.header.variant'),
      dataIndex: 'variant',
      key: 'variant',
      fixed: true,
    },
    {
      title: t('admin.products.list.header.name'),
      dataIndex: 'name',
      key: 'name',
      render: (_text, product, _index) => <TooltipSubstringText textLength={30} text={product.name} />,
    },
    {
      title: t('admin.products.list.header.language'),
      key: 'language',
      render: (_text, product, _index) => <span>{t(`general.languages.names.${product.language}`)}</span>,
    },
    {
      title: t('admin.products.list.header.price'),
      key: 'price',
      dataIndex: 'price',
      align: 'right',
    },
    {
      title: t('admin.documents.list.header.description'),
      key: 'description',
      align: 'right',
      render: (_text, product, _index) => <TooltipSubstringText iconOnly text={product.description} />,
    },
  ];

  if (!pickerMode) {
    fields = [
      ...fields,
      {
        title: t('admin.products.list.header.status'),
        key: 'status',
        dataIndex: 'status',
        render: (_text, blueprint, _index) => {
          const color = t(`admin.products.statusColor.${blueprint.status}`);
          return <Tag color={color}>{t(`admin.products.status.${blueprint.status}`)}</Tag>;
        },
      },
      {
        title: t('admin.products.list.header.version'),
        key: 'version',
        dataIndex: 'version',
        align: 'right',
      },
      {
        title: '',
        key: 'actions',
        width: 120,
        render: (_text, product, _index) => {
          const menu = (
            <Menu>
              <Menu.Item>
                <Button size="large" type="link" danger onClick={() => showDeleteConfirm(product)} disabled={productIsInUse(product)}>
                  <DeleteOutlined />
                  {t('general.form.delete')}
                </Button>
              </Menu.Item>
              {product.status !== ProductStatus.Released && (
                <Menu.Item>
                  <Button size="large" type="link" onClick={() => showPublishConfirm(product)}>
                    <ExclamationOutlined />
                    {t('admin.products.list.actions.publish')}
                  </Button>
                </Menu.Item>
              )}
              {product.status === ProductStatus.Released && (
                <Menu.Item>
                  <Button size="large" type="link" onClick={() => showArchiveConfirm(product)}>
                    <ExclamationOutlined />
                    {t('admin.products.list.actions.archive')}
                  </Button>
                </Menu.Item>
              )}
            </Menu>
          );
          return (
            <div style={{ width: 80 }}>
              <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
                <Button type="ghost" shape="circle" icon={<MoreOutlined />} size="small" />
              </Dropdown>
              <Button
                type="primary"
                shape="circle"
                icon={<RightOutlined />}
                size="small"
                style={{ marginLeft: 24 }}
                onClick={() => history.push(admin.products.edit(false, { productId: product.key }))}
              />
            </div>
          );
        },
        align: 'right',
      },
    ];
  }

  return fields;
};
