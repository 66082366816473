import { DeleteOutlined } from '@ant-design/icons';
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon';
import React from 'react';
import styled from 'styled-components';
import colors from '../../styles/colors';

const StyledDeleteButton = styled(DeleteOutlined)`
  svg {
    fill: ${colors.red};
  }
`;

export const DeleteButtonIcon: React.FC<AntdIconProps> = ({ ref, as, ...props }) => {
  return <StyledDeleteButton {...props} />;
};
