import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input } from 'antd';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { FormInputElementProps } from '.';

type ListValue = { isAdminItem: boolean; value: string; id: string; isChecked: boolean };
type ListValues = {
  items: ListValue[];
  checked: { [key: string]: boolean | undefined };
};

const StyledButton = styled(Button)``;

const StyledList = styled.div``;

const StyledFormItem = styled(Form.Item)`
  display: block;
  margin-bottom: 24px !important;

  .ant-form-item-label {
    margin-bottom: 8px;
  }

  & input.ant-input {
    width: calc(100% - 62px) !important;
    font-size: 14px;
  }

  & input:disabled {
    background-color: red;
    color: rgba(0, 0, 0, 0.65);
    border: none;
  }

  & .ant-row.ant-form-item {
    margin-bottom: 8px;
  }

  .ant-checkbox-wrapper + span {
    margin-left: 16px;
  }
`;

const isAllowedToRemove = (value: ListValue, isAdminEditing?: boolean, readonly?: boolean) =>
  isAdminEditing === true || (!isAdminEditing && !value.isAdminItem && !readonly);

export const ListCheckbox: React.FC<FormInputElementProps<ListValues>> = (props) => {
  const { onValueChanged, value: listValues, isAdminEditing, isUserEditable, customerValue, adminValue, readonly, additional } = props;
  const { t } = useTranslation();
  const calculatedValues: ListValues = (() => {
    // if the admin is editing we simply pass down the value, as there cannot be any customer values
    if (isAdminEditing === true) {
      return listValues ? listValues.value : { items: [], checked: {} };
    }
    // but, if we have a user editing the form we only want the user values in the state, but render the admin values too (note, lists are append only)
    return customerValue ? customerValue.value : { items: [], checked: {} };
  })();

  const [internalData, setInternalData] = useState<ListValues>(calculatedValues);

  const itemsToRender = useMemo(
    () => (isAdminEditing === true ? internalData.items : [...(adminValue?.value.items ?? []), ...internalData.items]),
    [internalData, adminValue, isAdminEditing],
  );

  const checkedValuesToRender = useMemo(
    () => (isAdminEditing === true ? internalData.checked : { ...(adminValue?.value.checked ?? {}), ...internalData.checked }),
    [internalData, adminValue, isAdminEditing],
  );

  const sanitizedIndex = (index: number) => (isAdminEditing === true ? index : index - (adminValue?.value.items.length ?? 0));

  const addItem = () => {
    const newListValues: ListValue[] = [
      ...internalData.items,
      {
        id: uuidv4(),
        isAdminItem: isAdminEditing === true,
        value: '',
        isChecked: false,
      },
    ];

    const copy = { ...internalData, items: newListValues };
    setInternalData(copy);
    onValueChanged(copy);
  };

  const onChange = (newValue: string, index: number) => {
    const useIndex = sanitizedIndex(index);
    const newItems = [...internalData.items];
    newItems[useIndex].value = newValue;
    newItems[useIndex].isAdminItem = isAdminEditing === true;

    const copy = { ...internalData, items: newItems };
    setInternalData(copy);
    onValueChanged(copy);
  };

  const onChangeChecked = (newValue: boolean, index: number, elementId: string) => {
    const updatedChecked = { ...internalData.checked, [elementId]: newValue };
    const copy = { ...internalData, checked: updatedChecked };
    setInternalData(copy);
    onValueChanged(copy);
  };

  const onRemove = (elementId: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [elementId]: _unused, ...newChecked } = internalData.checked;
    const newItems = internalData.items.filter((el) => el.id !== elementId);
    const copy = { ...internalData, checked: newChecked, items: newItems };
    setInternalData(copy);
    onValueChanged(copy);
  };

  return (
    <StyledFormItem label={isAdminEditing === true && t('formEditor.addButtons.listCheckbox')}>
      <StyledList>
        {itemsToRender.map((field, index) => {
          const checked = checkedValuesToRender[field.id];
          const hideEditor = readonly || (field.isAdminItem && !isAdminEditing);

          return (
            <Form.Item required={false} key={field.id} className={checked ? 'checked-item' : 'unchecked-item'}>
              <Form.Item noStyle>
                <Checkbox
                  defaultChecked={checked}
                  onChange={(e) => onChangeChecked(e.target.checked, index, field.id)}
                  disabled={readonly}
                ></Checkbox>
                {hideEditor && <span>{field.value}</span>}
                {!hideEditor && (
                  <Input
                    className="form-checkbox-input"
                    size="large"
                    defaultValue={field.value}
                    style={{ width: '60%', marginLeft: 16 }}
                    onChange={(e) => onChange(e.target.value, index)}
                  />
                )}
              </Form.Item>
              {isAllowedToRemove(field, isAdminEditing, readonly) ? (
                <MinusCircleOutlined className="dynamic-delete-button" style={{ margin: '0 8px' }} onClick={() => onRemove(field.id)} />
              ) : null}
            </Form.Item>
          );
        })}
      </StyledList>

      {(isAdminEditing === true || isUserEditable === true) && !readonly && (
        <StyledButton onClick={addItem}>
          <PlusOutlined /> {t('formEditor.listAdd')}
        </StyledButton>
      )}

      <div>{additional}</div>
    </StyledFormItem>
  );
};
