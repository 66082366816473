import { CheckOutlined, CopyOutlined } from '@ant-design/icons';
import { Avatar, Button, Comment, Form, Input, Tag, Typography } from 'antd';
import { FormProps } from 'antd/lib/form';
import { useForm } from 'antd/lib/form/Form';
import { Store } from 'antd/lib/form/interface';
import React, { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UserContext } from '../../shared/contexts';
import { userGetRef } from '../../shared/user';
import { WPKFormComment } from '../../types/firebase';
import { DeleteButtonIcon } from '../atoms/icons';
import LastUpdatedAt from '../molecules/lastUpdatedAt';
import firebase from 'firebase/app';
import { DocumentData, DocumentReference, refEqual } from 'firebase/firestore';

type CommentsContextProps = {
  comments: WPKFormComment[];
  onSaveComment?: (sectionId: string, sectionIndex: number, sectionTitle: string, text: string) => Promise<boolean>;
  onDeleteComment?: (sectionId: string, commentId: string) => void;
  onUpdateComment?: (comment: WPKFormComment, newText: string) => Promise<boolean>;
};
export const CommentsContext = createContext<CommentsContextProps>({ comments: [] });

interface Props {
  readonly: boolean;
  sectionId: string;
  sectionTitle: string;
  sectionIndex: number;
}

const CommentsEditor: React.FC<Props> = (props) => {
  const { readonly, sectionId, sectionTitle, sectionIndex } = props;
  const { t } = useTranslation();
  const { comments, onSaveComment, onDeleteComment, onUpdateComment } = useContext(CommentsContext);
  const { firestoreUser } = useContext(UserContext);

  const [form] = useForm();

  if (!onSaveComment || !firestoreUser) return null;

  const userRef = userGetRef(firestoreUser);
  const filteredComments = comments.filter((c) => c.sectionId === sectionId);

  const onFinish = (data: Store) =>
    onSaveComment(sectionId, sectionIndex, sectionTitle, data['content']).then(() => {
      form?.resetFields();
    });

  return (
    <>
      <Typography.Title style={{ fontSize: 14 }}>{t('commentsEditor.countedTitle', { count: filteredComments.length })}</Typography.Title>

      {filteredComments.map((c) => (
        <CommentCard
          key={c.key}
          comment={c}
          onDeleteComment={onDeleteComment}
          userRef={userRef}
          readonly={readonly}
          onUpdateComment={onUpdateComment}
        />
      ))}

      {!readonly && <CommentEditor form={form} onFinish={onFinish} />}
    </>
  );
};

export default React.memo(CommentsEditor);

interface CommentEditorProps extends Partial<Pick<FormProps, 'onFinish' | 'form'>> {
  value?: string;
  onCancel?: () => void;
}

export const CommentEditor: React.FC<CommentEditorProps> = (props) => {
  const { onFinish, onCancel, form, value } = props;
  const { t } = useTranslation();

  return (
    <Form onFinish={onFinish} form={form} initialValues={{ content: value }}>
      <Form.Item name="content" rules={[{ required: true, message: t('general.form.required') }]} style={{ marginBottom: 8 }}>
        <Input.TextArea autoSize={{ minRows: 1 }} placeholder={t('commentsEditor.placeholder')} />
      </Form.Item>
      <Form.Item>
        <Button size="large" htmlType="submit" type="default">
          {t(`commentsEditor.${!value ? 'addComment' : 'confirmEdit'}`)}
        </Button>
        {onCancel && (
          <Button size="large" type="link" onClick={onCancel}>
            {t('general.form.cancel')}
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};

interface CommentProps extends Pick<CommentsContextProps, 'onDeleteComment' | 'onUpdateComment'> {
  comment: WPKFormComment;
  userRef: DocumentReference<DocumentData>;
  readonly?: boolean;
}

const StyledCheckedIcon = styled(CheckOutlined)`
  margin-left: 8px;
`;

export const CommentCard: React.FC<CommentProps> = (props) => {
  const { comment, userRef, onDeleteComment, onUpdateComment, readonly } = props;
  const { t } = useTranslation();

  const [editMode, setEditMode] = useState(false);

  const onFinishCallback = (data: Store) => void onUpdateComment?.(comment, data['content']).then(() => setEditMode(false));

  const ownerCanChange = !readonly && refEqual(comment.creatorRef, userRef);

  return (
    <Comment
      avatar={comment.avatarUrl ? <Avatar src={comment.avatarUrl} alt={comment.author} /> : null}
      key={comment.key}
      actions={
        editMode
          ? []
          : [
              onUpdateComment && ownerCanChange ? (
                <span onClick={() => setEditMode(true)}>
                  <CopyOutlined />
                  &nbsp;{t('commentsEditor.edit')}
                </span>
              ) : null,
              onDeleteComment && ownerCanChange ? (
                <span onClick={() => onDeleteComment(comment.sectionId, comment.key)}>
                  <DeleteButtonIcon />
                  &nbsp;{t('general.form.delete')}
                </span>
              ) : null,
            ].filter((a) => !!a)
      }
      author={comment.author}
      content={
        editMode ? (
          <CommentEditor value={comment.text} onCancel={() => setEditMode(false)} onFinish={onFinishCallback} />
        ) : (
          <p>{comment.text}</p>
        )
      }
      datetime={
        <>
          <Tag color={t(`admin.users.roleColors.${comment.authorRole}`)}>{t(`general.roles.names.${comment.authorRole}`)}</Tag>&nbsp;
          <LastUpdatedAt milliSeconds={comment.createdAt.toMillis()} />
          {comment.isDone === true && <StyledCheckedIcon />}
        </>
      }
    />
  );
};
