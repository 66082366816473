import { Company, Handbook, CustomerForm } from '../types/firebase';
import { firestore } from '../firebase/init';
import { firestoreCompanyPath } from './company';
import collections from '../types/shared/collections';
import { doc } from 'firebase/firestore';

export const firestoreHandbookPath = (companyId: string, handbookId: string) => {
  return `${firestoreCompanyPath(companyId)}/${collections.handbooks}/${handbookId}`;
};
export const handbookGetRef = (company: Company, handbook: Handbook) => doc(firestore, firestoreHandbookPath(company.key, handbook.key));
export const handbookGetRefById = (companyId: string, handbookId: string) => doc(firestore, firestoreHandbookPath(companyId, handbookId));

export const firestoreHandbookFormPath = (companyId: string, handbookId: string, formId: string) => {
  return `${firestoreCompanyPath(companyId)}/${collections.handbooks}/${handbookId}/${collections.forms}/${formId}`;
};
export const handbookFormGetRef = (company: Company, handbook: Handbook, customerForm: CustomerForm) => {
  return doc(firestore, firestoreHandbookFormPath(company.key, handbook.key, customerForm.key));
};
export const handbookFormGetRefById = (companyId: string, handbookId: string, formId: string) => {
  return doc(firestore, firestoreHandbookFormPath(companyId, handbookId, formId));
};

export const handbookCommentGetRefById = (companyId: string, handbookId: string, commentId: string) => {
  return doc(firestore, `${firestoreHandbookPath(companyId, handbookId)}/${collections.comments}/${commentId}`);
};
