import { InboxOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Typography, Upload } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { collection, doc, onSnapshot, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import EmptyMessage from '../../../../components/molecules/emptyMessage';
import { firestore } from '../../../../firebase/init';
import { updateBlueprint } from '../../../../services/firebase/blueprints';
import { acceptedFiles, createHandbookFormDocument, deleteHandbookFormDocument } from '../../../../services/firebase/documents';
import { documentDocPathForDocument } from '../../../../shared/documents';
import { alwaysFalse, noOp } from '../../../../shared/functions';
import { useNotifications } from '../../../../shared/notifications';
import { layoutSpacings } from '../../../../styles/spacings';
import { Blueprint, Company, CustomerAttachment, CustomerForm, FirestoreDocument, Handbook } from '../../../../types/firebase';
import { HandbookStatus } from '../../../../types/firebase/collections/handbookTypes';
import collections from '../../../../types/shared/collections';
import { createColumns, createCustomerAttachmentColumns, CustomerDocumentsTable, DocumentsTable } from '../../documents';
import DocumentPicker from '../../documents/picker';

interface Props {
  blueprint: Blueprint;
  isAdminEditing: boolean;
  readonly: boolean;
  company?: Company;
  handbook?: Handbook;
}

const StyledUploadDragger = styled(Upload.Dragger)`
  ${layoutSpacings.marginTop}
  ${layoutSpacings.paddingTop}
`;

const StyledCustomerAttachmentsTitle = styled(Typography.Title)`
  ${layoutSpacings.marginTop}
`;

export const BlueprintAttachment: React.FC<Props> = (props) => {
  const { blueprint, isAdminEditing, readonly, handbook, company } = props;
  const { t } = useTranslation();
  const { notifyGeneralError, notifySaveSuccess, notifyDeleteConfirm } = useNotifications<FirestoreDocument>(t('admin.documents.single'));
  const { notifyDeleteConfirm: notifyDeleteCustomerDoc, notifyDeletedSuccess: notifyDeleteCustomerDocSuccess } =
    useNotifications<CustomerAttachment>(t('admin.documents.single'));

  const [showAdminDocumentModal, setShowAdminDocumentModal] = useState(false);
  const [attachments, setAttachments] = useState<FirestoreDocument[]>([]);

  const update = (newBlueprint: Blueprint) =>
    updateBlueprint(newBlueprint)
      .then(notifySaveSuccess)
      .catch((e) => {
        console.error(e);
        notifyGeneralError();
      });

  const onDocumentsSelected = (documents: FirestoreDocument[]) => {
    setShowAdminDocumentModal(false);

    const previousDocumentIds = blueprint.attachmentRefs?.map((a) => a.id) ?? [];
    const newDocumentsToAdd = documents.filter((p) => previousDocumentIds.indexOf(p.key) === -1);
    const newDocumentRefsRefs = newDocumentsToAdd.map((p) => doc(firestore, documentDocPathForDocument(p)));
    const attachmentRefs = [...(blueprint.attachmentRefs ?? []), ...newDocumentRefsRefs];

    const updatedBlueprint: Blueprint = { ...blueprint, attachmentRefs };
    void update(updatedBlueprint);
  };

  const onRemoveFileFromBlueprint = (document: FirestoreDocument) => {
    const updatedRefs = (blueprint.attachmentRefs ?? []).filter((a) => a.id !== document.key);
    const updatedBlueprint: Blueprint = { ...blueprint, attachmentRefs: updatedRefs };
    void update(updatedBlueprint);
  };

  const removeBlueprintConfirm = (document: FirestoreDocument) => notifyDeleteConfirm(document, onRemoveFileFromBlueprint, noOp);

  const uploadFile = (info: UploadChangeParam<UploadFile<File>>) => {
    if (!handbook || !company) return;
    const [uploadFile] = info.fileList.slice(-1);
    createHandbookFormDocument(company, handbook, blueprint as CustomerForm, uploadFile.originFileObj as File)
      .then(() => {
        notifySaveSuccess();
        return true;
      })
      .catch((error) => {
        console.error(error);
        notifyGeneralError();
        return false;
      });
  };

  const onDeleteCustomerDocument = (attachment: CustomerAttachment) => {
    if (!handbook || !company || !blueprint) return;

    deleteHandbookFormDocument(company, handbook, blueprint as CustomerForm, attachment)
      .then(notifyDeleteCustomerDocSuccess)
      .catch((e) => {
        console.error(e);
        notifyGeneralError();
      });
  };
  const deleteFileCallback = (attachment: CustomerAttachment) => notifyDeleteCustomerDoc(attachment, onDeleteCustomerDocument, noOp);

  useEffect(() => {
    return onSnapshot(
      query(
        collection(firestore, collections.documents),
        where(
          'key',
          'in',
          blueprint.attachmentRefs?.length === 0 ? ['whatever-will-never-match'] : blueprint.attachmentRefs?.map((a) => a.id),
        ),
      ),
      (snapshot) => setAttachments(snapshot.docs.map((d) => d.data()) as FirestoreDocument[]),
    );
  }, [blueprint, setAttachments]);

  const columns = createColumns(t, removeBlueprintConfirm, isAdminEditing, isAdminEditing ? !readonly : false);
  const customerAttachmentColumns = createCustomerAttachmentColumns(t, deleteFileCallback, readonly);

  return (
    <div>
      {isAdminEditing === true && showAdminDocumentModal && (
        <DocumentPicker
          visible={true}
          onCancel={() => setShowAdminDocumentModal(false)}
          onOk={onDocumentsSelected}
          language={blueprint.language}
        />
      )}

      {!isAdminEditing && (
        <>
          <Typography.Title level={4}>{t('private.handbooks.edit.forms.list.attachments.admin')}</Typography.Title>
        </>
      )}

      {blueprint.attachmentRefs && blueprint.attachmentRefs.length > 0 && <DocumentsTable columns={columns} documents={attachments} />}

      {isAdminEditing === true && blueprint.attachmentRefs && blueprint.attachmentRefs.length > 0 && !readonly && (
        <Button
          size="large"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setShowAdminDocumentModal(true)}
          style={{ marginTop: 64, marginBottom: 24 }}
        >
          {t('general.link.addButtonTitle', { item: t('admin.header.documents') })}
        </Button>
      )}

      {!isAdminEditing && blueprint.customerAttachments && (
        <>
          <StyledCustomerAttachmentsTitle level={4}>
            {t('private.handbooks.edit.forms.list.attachments.customer')}
          </StyledCustomerAttachmentsTitle>
          {blueprint.customerAttachments.length > 0 && (
            <CustomerDocumentsTable columns={customerAttachmentColumns} attachments={blueprint.customerAttachments} />
          )}
        </>
      )}

      {!readonly && isAdminEditing === true && blueprint.attachmentRefs && blueprint.attachmentRefs.length === 0 && (
        <EmptyMessage
          title={t('general.link.title', { item: t('admin.documents.single') })}
          description={t('general.link.description', { items: t('admin.header.documents') })}
          buttonTitle={t('general.link.addButtonTitle', { item: t('admin.header.documents') })}
          buttonIcon={<PlusOutlined />}
          onClick={() => setShowAdminDocumentModal(true)}
          logo={<PlusOutlined />}
          enableAddButton
        />
      )}

      {handbook?.status !== HandbookStatus.Deactivated && !handbook?.isArchived && !isAdminEditing && (
        <StyledUploadDragger
          multiple={true}
          onChange={uploadFile}
          showUploadList={false}
          beforeUpload={alwaysFalse}
          accept={acceptedFiles.join(',')}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">{t('admin.documents.create.fields.file.dragText')}</p>
          <p className="ant-upload-hint">{t('admin.documents.create.fields.file.introduction')}</p>
        </StyledUploadDragger>
      )}
    </div>
  );
};

export default BlueprintAttachment;
