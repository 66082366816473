import React from 'react';
import PrivateRoute from '../../components/organisms/router/privateRoute';
import { admin } from '../../routing/routes';
import { BlueprintEdit, BlueprintList, BlueprintNew, BlueprintPrint } from './blueprints';
import CompanyList from './companies/list';
import CompanyShow from './companies/show';
import DocumentList from './documents/list';
import DocumentNew from './documents/new';
import { ProductEdit, ProductList, ProductNew } from './products';
import UserList from './users/list';

const adminRoutes = () => [
  <PrivateRoute authorizeAdmin exact path={admin.blueprints.list()} key={admin.blueprints.list()} component={BlueprintList} />,
  <PrivateRoute authorizeAdmin exact path={admin.blueprints.new()} key={admin.blueprints.new()} component={BlueprintNew} />,
  <PrivateRoute authorizeAdmin exact path={admin.blueprints.edit()} key={admin.blueprints.edit()} component={BlueprintEdit} />,
  <PrivateRoute authorizeAdmin exact path={admin.blueprints.print()} key={admin.blueprints.print()} component={BlueprintPrint} />,

  <PrivateRoute authorizeAdmin exact path={admin.products.list()} key={admin.products.list()} component={ProductList} />,
  <PrivateRoute authorizeAdmin exact path={admin.products.new()} key={admin.products.new()} component={ProductNew} />,
  <PrivateRoute authorizeAdmin exact path={admin.products.edit()} key={admin.products.edit()} component={ProductEdit} />,

  <PrivateRoute authorizeAdmin exact path={admin.documents.list()} key={admin.documents.list()} component={DocumentList} />,
  <PrivateRoute authorizeAdmin exact path={admin.documents.new()} key={admin.documents.new()} component={DocumentNew} />,

  <PrivateRoute authorizeAdmin exact path={admin.users.list()} key={admin.users.list()} component={UserList} />,
  <PrivateRoute authorizeAdmin exact path={admin.companies.list()} key={admin.companies.list()} component={CompanyList} />,
  <PrivateRoute authorizeAdmin exact path={admin.companies.show()} key={admin.companies.show()} component={CompanyShow} />,
];

export default adminRoutes;
