import React from 'react';
import styled from 'styled-components';

import { BlueprintType } from '../../types/firebase';

interface Props {
  type: BlueprintType;
}

type BlueprintTypeColorsObject = { [key in BlueprintType]: string };
export const BlueprintTypeColors: BlueprintTypeColorsObject = {
  [BlueprintType.AA]: '#009FE3',
  [BlueprintType.CL]: '#E5007D',
  [BlueprintType.PB]: '#7C7B7B',
  [BlueprintType.FO]: '#41AD49',
  [BlueprintType.RL]: '#FFED00',
  [BlueprintType.VL]: '#702283',
};

const StyledCircle = styled.div<Props>`
  display: inline-block;
  background-color: ${(props) => BlueprintTypeColors[props.type]};
  width: 12px;
  height: 12px;
  border-radius: 50%;
`;

const BlueprintColor: React.FC<Props> = ({ type, ...rest }) => {
  return <StyledCircle type={type} {...rest} />;
};

export default BlueprintColor;
