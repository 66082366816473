import firebase from 'firebase/app';

import { WPKLocation } from '../types/firebase/collections/location';
import { firestore } from '../firebase/init';
import { Company, OnboardingData } from '../types/firebase/collections/company';
import { companyIdFromCompany } from './company';
import collections from '../types/shared/collections';
import { doc, DocumentData, DocumentReference, Timestamp } from 'firebase/firestore';

export const locationIdFromLocation = (location: WPKLocation) => location.key;
export const firestoreLocationPath = (companyId: string, id: string) => {
  return `/${collections.companies}/${companyId}/${collections.locations}/${id}`;
};
export const locationGetRef = (company: Company, location: WPKLocation) => {
  return doc(firestore, firestoreLocationPath(companyIdFromCompany(company), locationIdFromLocation(location)));
};

export const createNewLocation = (locationRef: DocumentReference<DocumentData>, data: OnboardingData['location']) => {
  return {
    ...data,
    key: locationRef.id,
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),
  } as WPKLocation;
};

export const locationIsInUse = (location: WPKLocation) => {
  if (!location.handbooks) return false;
  const blueprintKeys = Object.keys(location.handbooks);
  const referencedForms = blueprintKeys.filter((key) => location.handbooks?.[key] === true);
  return referencedForms.length > 0;
};
