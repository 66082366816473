import bluebird from 'bluebird';
import { Timestamp, runTransaction, collection, doc, addDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes, deleteObject } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { firestore, storage } from '../../firebase/init';
import { handbookFormGetRef } from '../../shared/handbooks';
import { Company, CustomerAttachment, CustomerForm, FirestoreDocument, Handbook, WPKUser } from '../../types/firebase';
import { WpkRole } from '../../types/global';
import collections from '../../types/shared/collections';

export const storageAdminDocumentsFolder = process.env.NODE_ENV === 'development' ? 'adminDocumentsDevelopment' : 'adminDocuments';
export const storageUserDocumentsFolder = process.env.NODE_ENV === 'development' ? 'userDocumentsDevelopment' : 'userDocuments';
export const acceptedImageFiles = ['.jpg', '.png'];
export const acceptedFiles = [
  ...acceptedImageFiles,
  '.pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

export const createAdminDocument = (document: FirestoreDocument, user: WPKUser | undefined) =>
  bluebird.resolve(
    runTransaction(firestore, async (transaction) => {
      if (!document.file) throw new Error('No file provided');

      const { storageFilename, originalFilename, fileExtension } = createFileData(document.file);
      const isAdmin = user?.role === WpkRole.Admin;

      const newDocumentRef = doc(collection(firestore, collections.documents));
      const fileRef = isAdmin
        ? ref(storage, `${storageAdminDocumentsFolder}/${storageFilename}`)
        : ref(storage, `${storageUserDocumentsFolder}/${storageFilename}`);

      await uploadBytes(fileRef, document.file);

      const downloadUrl = await getDownloadURL(fileRef);

      const newDocument: FirestoreDocument = {
        key: newDocumentRef.id,
        name: document.name,
        language: document.language,
        originalFilename: originalFilename,
        downloadUrl,
        fileExtension,
        storageRef: fileRef.toString(),
        blueprints: {},
        searchText: [],
        description: document.description,
        adminDescription: document.adminDescription ?? null,
        updatedAt: Timestamp.now(),
        createdAt: Timestamp.now(),
      };

      transaction.set(newDocumentRef, newDocument);
    }),
  );

export const deleteAdminDocument = (document: FirestoreDocument) =>
  bluebird.resolve(
    runTransaction(firestore, async (transaction) => {
      const fileOnStorageRef = ref(storage, document.storageRef); // TODO test me
      const docRef = doc(firestore, collections.documents, document.key);

      await deleteObject(fileOnStorageRef);
      transaction.delete(docRef);
    }),
  );

export const createHandbookFormDocument = (company: Company, handbook: Handbook, form: CustomerForm, file: File) => {
  return bluebird.resolve(
    runTransaction(firestore, async (t) => {
      const { storageFilename, fileExtension, originalFilename } = createFileData(file);
      const fileRef = ref(storage, `${storageUserDocumentsFolder}/${storageFilename}`);

      const formRef = handbookFormGetRef(company, handbook, form);
      await uploadBytes(fileRef, file);

      const downloadUrl = await getDownloadURL(fileRef);

      const customerAttachment: CustomerAttachment = {
        key: storageFilename,
        name: file.name,
        fileExtension: fileExtension,
        originalFilename,
        downloadUrl,
      };

      t.set(formRef, { customerAttachments: arrayUnion(customerAttachment) }, { merge: true });
    }),
  );
};

export const deleteHandbookFormDocument = (company: Company, handbook: Handbook, form: CustomerForm, attachment: CustomerAttachment) => {
  return bluebird.resolve(
    runTransaction(firestore, async (t) => {
      const formRef = handbookFormGetRef(company, handbook, form);
      const fileRef = ref(storage, `${storageUserDocumentsFolder}/${attachment.key}`);
      await deleteObject(fileRef);

      t.set(formRef, { customerAttachments: arrayRemove(attachment) }, { merge: true });
    }),
  );
};

export const createFileData = (file: File) => {
  const originalFilename = file.name;
  const storageFilename = `${uuidv4()}_${originalFilename}`;
  const fileExtension = file.name.split('.').pop()?.toUpperCase() ?? 'UNKNOWN';
  return { storageFilename, originalFilename, fileExtension };
};
