import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Spinner = <LoadingOutlined style={{ fontSize: 48 }} spin />;

const StyledSpin = styled(Spin)`
  width: 100%;
`;

interface Props {
  tip?: string;
}

const LoadingIndicator: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { tip } = props;

  const defaultTip = t('general.loading');

  return <StyledSpin indicator={Spinner} size="large" tip={tip ?? defaultTip} />;
};

export default LoadingIndicator;
