import React from 'react';
import { NavLink } from 'react-router-dom';
import { TFunction } from 'i18next';
import { Menu } from 'antd';
import { LogoutOutlined, UserOutlined, FlagOutlined } from '@ant-design/icons';
import { app, admin } from '../../../routing/routes';
import styled from 'styled-components';

const StyledMenuItem = styled(Menu.Item)``;

export const UserContextMenu: React.FC<{
  exit: () => void;
  t: TFunction;
  availableLanguages: string[];
  currentLanguage: string;
  changeLanguage: (langKey: string) => void;
}> = ({ exit, t, availableLanguages, currentLanguage, changeLanguage }) => (
  <Menu selectedKeys={[currentLanguage]}>
    <StyledMenuItem key="profile">
      <NavLink to={app.profile()}>
        <UserOutlined />
        <span>{t('private.profile.title')}</span>
      </NavLink>
    </StyledMenuItem>
    {availableLanguages && (
      <Menu.SubMenu
        title={
          <>
            <FlagOutlined />
            <span>{t('general.languages.title')}</span>
          </>
        }
      >
        {availableLanguages.map((langKey) => (
          <StyledMenuItem key={langKey} onClick={() => changeLanguage(langKey)}>
            <span>{t(`general.languages.names.${langKey}`)}</span>
          </StyledMenuItem>
        ))}
      </Menu.SubMenu>
    )}
    <StyledMenuItem key="logout" onClick={exit}>
      <LogoutOutlined />
      <span>{t('private.logout')}</span>
    </StyledMenuItem>
  </Menu>
);
export default UserContextMenu;

export const adminMenuItems = (t: TFunction) => [
  <StyledMenuItem key={admin.blueprints.list()}>
    <NavLink activeClassName="" to={admin.blueprints.list()}>
      {t('admin.header.blueprints')}
    </NavLink>
  </StyledMenuItem>,
  <StyledMenuItem key={admin.products.list()}>
    <NavLink activeClassName="" to={admin.products.list()}>
      {t('admin.header.products')}
    </NavLink>
  </StyledMenuItem>,
  <StyledMenuItem key={admin.documents.list()}>
    <NavLink to={admin.documents.list()}>{t('admin.header.documents')}</NavLink>
  </StyledMenuItem>,
  <StyledMenuItem key={admin.users.list()}>
    <NavLink to={admin.users.list()}>{t('admin.header.users')}</NavLink>
  </StyledMenuItem>,
  <StyledMenuItem key={admin.companies.list()}>
    <NavLink to={admin.companies.list()}>{t('admin.header.companies')}</NavLink>
  </StyledMenuItem>,
];

export const customerMenuItems = (t: TFunction) => [
  <StyledMenuItem key={app.handbooks.list()}>
    <NavLink to={app.handbooks.list()}>{t('private.customer.header.handbooks')}</NavLink>
  </StyledMenuItem>,
  <StyledMenuItem key={app.company.edit()}>
    <NavLink to={app.company.edit()}>{t('private.customer.header.manufacturing')}</NavLink>
  </StyledMenuItem>,
];

export const mandateMenuItems = (t: TFunction) => [
  <StyledMenuItem key={app.mandate.list()}>
    <NavLink to={app.mandate.list()}>{t('private.consultant.header.mandate')}</NavLink>
  </StyledMenuItem>,
  <StyledMenuItem key={app.company.edit()}>
    <NavLink to={app.company.edit()}>{t('private.consultant.header.company')}</NavLink>
  </StyledMenuItem>,
];
