import { QuestionCircleOutlined } from '@ant-design/icons';
import { Form, Input, Radio, Tooltip } from 'antd';
import { FormProps } from 'antd/lib/form';
import { Store } from 'antd/lib/form/interface';
import Title from 'antd/lib/typography/Title';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledNumberInput } from '../../../components/atoms/inputs';
import FormActions from '../../../components/molecules/formActions';
import { admin } from '../../../routing/routes';
import { ApplicationContext } from '../../../shared/contexts';
import { Product } from '../../../types/firebase';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFormValuesChanged: (values: any) => Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSaveAndNew?: () => void;
  hideSaveAndNew?: boolean;
  form: FormProps['form'];
  product?: Product;
}

const ProductForm: React.FC<Props> = (props) => {
  const { availableLanguages } = useContext(ApplicationContext);
  const { t } = useTranslation();
  const { onFormValuesChanged, onSaveAndNew, form, hideSaveAndNew, product } = props;

  const [isSaving, setIsSaving] = useState(false);

  const editMode = !!product;

  const onSave = (values: Store) => {
    setIsSaving(true);
    onFormValuesChanged(values).finally(() => setIsSaving(false));
  };

  return (
    <Form form={form} layout="vertical" onFinish={onSave} initialValues={product}>
      <Title level={4}>{t('general.languages.title')}</Title>
      <p>{t('admin.products.create.languageIntroduction')}</p>

      <Form.Item name="language" rules={[{ required: true, message: t('general.form.required') }]}>
        <Radio.Group buttonStyle="solid" disabled={editMode}>
          {availableLanguages?.map((lang: string) => (
            <Radio.Button key={lang} value={lang}>
              {t(`general.languages.names.${lang}`)}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>

      <Title level={4}>{t('admin.products.create.detailsTitle')}</Title>

      <Form.Item
        name="enNorm"
        rules={[{ required: true, message: t('general.form.required') }]}
        label={t('admin.products.create.fields.enNorm.label')}
      >
        <Input size="large" placeholder={t('admin.products.create.fields.enNorm.placeholder')} disabled={editMode} />
      </Form.Item>

      <Form.Item
        name="variant"
        rules={[{ required: false }]}
        label={
          <span>
            {t('admin.products.create.fields.variant.label')}&nbsp;
            <Tooltip title={t('admin.products.create.fields.variant.introduction')}>
              <QuestionCircleOutlined />
            </Tooltip>
          </span>
        }
      >
        <Input size="large" placeholder={t('admin.products.create.fields.variant.placeholder')} disabled={editMode} />
      </Form.Item>

      <Form.Item
        name="name"
        rules={[{ required: true, message: t('general.form.required') }]}
        label={
          <span>
            {t('admin.products.create.fields.name.label')}&nbsp;
            <Tooltip title={t('admin.products.create.fields.name.introduction')}>
              <QuestionCircleOutlined />
            </Tooltip>
          </span>
        }
      >
        <Input size="large" placeholder={t('admin.products.create.fields.name.placeholder')} />
      </Form.Item>

      <Form.Item name="description" label={t('admin.documents.create.fields.description.label')}>
        <Input.TextArea autoSize={{ minRows: 3 }} placeholder={t('admin.documents.create.fields.description.placeholder')} />
      </Form.Item>

      <Form.Item
        name="version"
        rules={[{ required: true, message: t('general.form.required') }]}
        label={t('admin.products.create.fields.version.label')}
      >
        <StyledNumberInput min={1} size="large" placeholder={t('admin.products.create.fields.version.placeholder')} disabled={editMode} />
      </Form.Item>

      <Form.Item
        name="price"
        rules={[{ required: true, message: t('general.form.required') }]}
        label={
          <span>
            {t('admin.products.create.fields.price.label')}&nbsp;
            <Tooltip title={t('admin.products.create.fields.price.introduction')}>
              <QuestionCircleOutlined />
            </Tooltip>
          </span>
        }
      >
        <StyledNumberInput size="large" inputMode="decimal" placeholder={t('admin.products.create.fields.price.placeholder')} min={0} />
      </Form.Item>

      <FormActions isSaving={isSaving} cancelUrl={admin.products.list()} saveAndNew={onSaveAndNew} hideSaveAndNew={hideSaveAndNew} />
    </Form>
  );
};

export default ProductForm;
