import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { FormInputElementProps } from '.';

type ListValue = { isAdminItem: boolean; value: string; id: string };
type ListValues = ListValue[];

const StyledButton = styled(Button)``;

const StyledList = styled.ul`
  padding-left: 18px;
`;

const StyledFormItem = styled(Form.Item)`
  display: block;

  .ant-form-item-label {
    margin-bottom: 8px;
  }

  & textarea.ant-input {
    width: calc(100% - 30px) !important;
  }

  & *:disabled {
    background-color: red;
    color: rgba(0, 0, 0, 0.65);
    border: none;
  }

  & ul textarea {
    vertical-align: text-top;
    margin-top: -6px;
    resize: none;
  }

  & ul li > .ant-form-item {
    margin-bottom: 8px;
  }
`;

const isAllowedToRemove = (value: ListValue, isAdminEditing?: boolean) =>
  isAdminEditing === true || (!isAdminEditing && !value.isAdminItem);

export const ListText: React.FC<FormInputElementProps<ListValues>> = (props) => {
  const { onValueChanged, value: listValues, isAdminEditing, isUserEditable, customerValue, adminValue, readonly, additional } = props;
  const { t } = useTranslation();
  const internalValues: ListValues = (() => {
    // if the admin is editing we simply pass down the value, as there cannot be any customer values
    if (isAdminEditing === true) {
      return listValues ? listValues.value : ([] as ListValues);
    }
    // but, if we have a user editing the form we only want the user values in the state, but render the admin values too (note, lists are append only)
    return customerValue ? customerValue.value : ([] as ListValues);
  })();
  const [internalListItems, setInternalListItems] = useState<ListValues>(internalValues);

  const itemsToRender = useMemo(() => {
    return isAdminEditing === true ? internalListItems : [...(adminValue?.value ?? []), ...internalListItems];
  }, [internalListItems, adminValue, isAdminEditing]);

  const sanitizedIndex = (index: number) => (isAdminEditing === true ? index : index - (adminValue?.value.length ?? 0));

  const addItem = () => {
    const newListValues: ListValues = [
      ...internalListItems,
      {
        isAdminItem: isAdminEditing === true,
        value: '',
        id: uuidv4(),
      },
    ];
    setInternalListItems(newListValues);
    onValueChanged(newListValues);
  };

  const onChange = (newValue: string, index: number) => {
    const useIndex = sanitizedIndex(index);
    const copy = [...internalListItems];
    copy[useIndex].value = newValue;
    copy[useIndex].isAdminItem = isAdminEditing === true;
    setInternalListItems(copy);
    onValueChanged(copy);
  };

  const onRemove = (index: number) => {
    const useIndex = sanitizedIndex(index);
    const copy = internalListItems.filter((_el, idx) => idx !== useIndex);
    setInternalListItems(copy);
    onValueChanged(copy);
  };

  return (
    <div>
      <StyledFormItem label={isAdminEditing === true && t('formEditor.addButtons.listText')}>
        <StyledList>
          {itemsToRender.map((field, index) => {
            const hideEditor = readonly || (field.isAdminItem && !isAdminEditing);

            return (
              <li key={field.id}>
                {hideEditor && <span>{field.value}</span>}
                {!hideEditor && (
                  <Form.Item required={false}>
                    <Form.Item noStyle>
                      <Input.TextArea
                        autoSize={{ minRows: 1 }}
                        defaultValue={field.value}
                        style={{ width: '60%' }}
                        onChange={(e) => onChange(e.target.value, index)}
                      />
                    </Form.Item>
                    {isAllowedToRemove(field, isAdminEditing) ? (
                      <MinusCircleOutlined className="dynamic-delete-button" style={{ margin: '0 8px' }} onClick={() => onRemove(index)} />
                    ) : null}
                  </Form.Item>
                )}
              </li>
            );
          })}
        </StyledList>

        {(isAdminEditing === true || isUserEditable === true) && !readonly && (
          <StyledButton onClick={addItem}>
            <PlusOutlined /> {t('formEditor.listAdd')}
          </StyledButton>
        )}

        <div>{additional}</div>
      </StyledFormItem>
    </div>
  );
};
