import {
  BlueprintCopyData,
  BlueprintCreateData,
  BlueprintDeleteData,
  BlueprintPublishData,
  BlueprintUpdateData,
} from '../types/firebase/functions/blueprints';
import {
  HandbookCopyData,
  HandbookCreateData,
  HandbookDeactivateData,
  HandbookDeleteData,
  HandbookPublishData,
  HandbookRemoveInstructorData,
  HandbookSetInstructorData,
} from '../types/firebase/functions/handbooks';
import { functions } from './init';
import { httpsCallable } from 'firebase/functions';

export const callables = {
  admin: {
    onProductsExport: (language: string) => httpsCallable(functions, 'admin-onProductsExport')(language),
  },

  user: {
    login: () => {
      const loginFunction = httpsCallable(functions, 'users-onLogin');
      return loginFunction();
    },
  },

  blueprints: {
    create: (data: BlueprintCreateData) => httpsCallable(functions, 'blueprints-onCreate')(data),
    update: (data: BlueprintUpdateData) => httpsCallable(functions, 'blueprints-onUpdate')(data),
    copy: (data: BlueprintCopyData) => httpsCallable(functions, 'blueprints-onCopy')(data),
    delete: (data: BlueprintDeleteData) => httpsCallable(functions, 'blueprints-onDelete')(data),
    publish: (data: BlueprintPublishData) => httpsCallable(functions, 'blueprints-onPublish')(data),
  },

  handbooks: {
    create: (data: HandbookCreateData) => httpsCallable(functions, 'handbooks-onCreate')(data),
    copy: (data: HandbookCopyData) => httpsCallable(functions, 'handbooks-onCopy')(data),
    delete: (data: HandbookDeleteData) => httpsCallable(functions, 'handbooks-onDelete')(data),
    publish: (data: HandbookPublishData) => httpsCallable(functions, 'handbooks-onPublish')(data),
    deactivate: (data: HandbookDeactivateData) => httpsCallable(functions, 'handbooks-onDeactivate')(data),
    setInstructor: (data: HandbookSetInstructorData) => httpsCallable(functions, 'handbooks-setInstructor')(data),
    removeInstructor: (data: HandbookRemoveInstructorData) => httpsCallable(functions, 'handbooks-removeInstructor')(data),
  },
};
