import firebase from 'firebase/app';
import { callables } from '../../firebase/functions';
import { auth } from '../../firebase/init';
import { app } from '../../routing/routes';
import {
  applyActionCode,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  updatePassword,
  User,
  verifyPasswordResetCode,
} from 'firebase/auth';

export type AuthCallback = (success: boolean) => void;

export const logout = (callback: AuthCallback) => {
  auth
    .signOut()
    .then(() => callback(true))
    .catch(() => callback(false));
};

export const login = (email: string, password: string, callback: AuthCallback) => {
  signInWithEmailAndPassword(auth, email, password)
    .then(callables.user.login)
    .then(() => callback(true))
    .catch((error) => {
      console.error(error);
      callback(false);
    });
};

export const sendVerificationEmail = (user: User | null, callback?: AuthCallback) => {
  if (!user) return null;

  const actionCodeSettings = {
    url: app.index(true),
  };

  return sendEmailVerification(user, actionCodeSettings).then((result) => {
    if (callback) {
      callback(true);
    }
    return result;
  });
};

export const register = (email: string, password: string, callback: AuthCallback) => {
  createUserWithEmailAndPassword(auth, email, password)
    .then((result) => sendVerificationEmail(result.user))
    .then(() => callback(true))
    .catch((error) => {
      console.error(error);
      callback(false);
    });
};

export const sendResetEmail = (email: string, callback: AuthCallback) => {
  sendPasswordResetEmail(auth, email)
    .then(() => callback(true))
    .catch((error) => {
      console.error(error);
      callback(false);
    });
};

export const resetPassword = (oobCode: string, newPassword: string, callback: AuthCallback) => {
  verifyPasswordResetCode(auth, oobCode)
    .then(() => {
      confirmPasswordReset(auth, oobCode, newPassword)
        .then(() => callback(true))
        .catch((error) => {
          console.error(error);
          callback(false);
        });
    })
    .catch((error) => {
      console.error(error);
      callback(false);
    });
};

export const changePassword = (newPassword: string, callback: AuthCallback) => {
  if (!auth?.currentUser) return;

  updatePassword(auth.currentUser, newPassword)
    .then(() => callback(true))
    .catch((error) => {
      console.error(error);
      callback(false);
    });
};

export const verifyEmail = (oobCode: string, callback: AuthCallback) => {
  applyActionCode(auth, oobCode)
    .then(() => callback(true))
    .catch((error) => {
      console.error(error);
      callback(false);
    });
};
