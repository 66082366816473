import { PlusOutlined } from '@ant-design/icons';
import { Radio } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { FormEditorInputType } from '../../../types/firebase';

interface Props {
  buttons: Array<{ label: string; type: FormEditorInputType }>;
  onButtonClick: (type: FormEditorInputType) => void;
}

const StyledRadioGroup = styled(Radio.Group)`
  margin-top: 32px;
  margin-bottom: 16px;
`;

const AddButtonGroup: React.FC<Props> = (props) => {
  const { buttons, onButtonClick } = props;

  return (
    <StyledRadioGroup size="large" value="asdfasdfasdf">
      {buttons.map((b, index) => (
        <Radio.Button key={b.type} value={b.label} onChange={() => onButtonClick(b.type)}>
          {index === 0 && (
            <>
              <PlusOutlined />
              &nbsp;
            </>
          )}
          {b.label}
        </Radio.Button>
      ))}
    </StyledRadioGroup>
  );
};

export default React.memo(AddButtonGroup);
