import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import * as firebaseAuth from '../../../services/firebase/auth';
import { authentication } from '../../../routing/routes';
import { StyledCard, StyledSpin, StyledMailOutline } from './sharedComponents';
import PublicLayout from '../../../components/layouts/publicLayout';

interface Props {
  mode: CallbackMode;
  oobCode: string;
}

enum FormState {
  showForm,
  loading,
  success,
  error,
}

enum FormFields {
  email = 'email',
  password = 'password',
  passwordConfirm = 'passwordConfirm',
}

// Callback modes are defined by Firebase.
// See: https://firebase.google.com/docs/auth/custom-email-handler
enum CallbackMode {
  resetPassword = 'resetPassword',
  verifyEmail = 'verifyEmail',
}

const Callbacks: React.FC<Props> = (props) => {
  const { mode, oobCode } = props;

  const [actionState, setActionState] = useState(FormState.showForm);
  const { t } = useTranslation();

  const authCallback: firebaseAuth.AuthCallback = (success) => setActionState(success ? FormState.success : FormState.error);

  const passwordResetHandler = (values: { password?: string }) => {
    const { [FormFields.password]: password } = values;
    if (!password) return;

    setActionState(FormState.loading);
    firebaseAuth.resetPassword(oobCode, password, authCallback);
  };

  useEffect(() => {
    if (mode === CallbackMode.verifyEmail) {
      setActionState(FormState.loading);
      firebaseAuth.verifyEmail(oobCode, authCallback);
    }
  }, [mode, oobCode]);

  const resultTitle = {
    [CallbackMode.verifyEmail]: t('public.login.emailVerified'),
    [CallbackMode.resetPassword]: t('public.login.passwordResetted'),
  };
  const resultDescription = {
    [CallbackMode.verifyEmail]: t('public.login.emailVerifiedSubtitle'),
    [CallbackMode.resetPassword]: t('public.login.passwordResettedSubtitle'),
  };
  return (
    <PublicLayout>
      <StyledCard size="default">
        {actionState === FormState.loading && <StyledSpin />}

        {actionState === FormState.error && (
          <>
            <Typography.Title level={3}>{t('public.login.error')}</Typography.Title>
            <p>{t('public.login.oobCodeError')}</p>

            <Button size="large" type="primary">
              <Link to={authentication.login()}>{t('public.login.backToLogin')}</Link>
            </Button>
          </>
        )}

        {actionState === FormState.success && (
          <>
            <Typography.Title level={3}>{resultTitle[mode]}</Typography.Title>
            <p>{resultDescription[mode]}</p>

            <Button size="large" type="primary">
              <Link to={authentication.login()}>{t('public.login.backToLogin')}</Link>
            </Button>
          </>
        )}

        {mode === CallbackMode.resetPassword && actionState === FormState.showForm && (
          <>
            <Typography.Title level={3}>{t('public.login.resetPassword')}</Typography.Title>

            <Form onFinish={passwordResetHandler}>
              <Form.Item name={FormFields.password} rules={[{ required: true, message: t('public.login.passwordRequired') }]}>
                <Input.Password size="large" prefix={<StyledMailOutline />} placeholder={t('public.login.newPassword')} />
              </Form.Item>
              <Form.Item
                name={FormFields.passwordConfirm}
                dependencies={[FormFields.password]}
                rules={[
                  {
                    required: true,
                    message: t('public.login.passwordRequired'),
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue(FormFields.password) === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(t('public.login.passwordsNotMatching'));
                    },
                  }),
                ]}
              >
                <Input.Password size="large" prefix={<StyledMailOutline />} placeholder={t('public.login.passwordConfirm')} />
              </Form.Item>
              <Form.Item>
                <Button size="large" type="primary" htmlType="submit">
                  {t('public.login.resetPassword')}
                </Button>
              </Form.Item>
            </Form>
          </>
        )}
      </StyledCard>
    </PublicLayout>
  );
};

export default Callbacks;
