import React, { useState } from 'react';
import { Form, DatePicker, Input, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormInstance } from 'antd/lib/form';

interface Props {
  form: FormInstance;
  certificationNumber?: string;
}

export interface HandbookCertificationFormData {
  success: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validUntil?: any;
  certificationNumber?: string;
}

export const HandbookCertificationFormItems: React.FC<Props> = (props) => {
  const { form, certificationNumber } = props;
  const { t } = useTranslation();

  const [success, setSuccess] = useState(false);

  const onChange = (s: boolean) => {
    form.resetFields(['validUntil', 'certificationNumber']);
    setSuccess(s);
  };

  return (
    <Form form={form} layout="vertical" initialValues={{ certificationNumber }}>
      <Form.Item name="success" label={t('private.handbooks.edit.certification.fields.success.label')}>
        <Switch checked={success} onChange={onChange} />
      </Form.Item>

      <Form.Item
        name="validUntil"
        label={t(t('private.handbooks.edit.certification.fields.date.label'))}
        rules={[{ required: success, message: t('general.form.required') }]}
      >
        <DatePicker disabled={!success} />
      </Form.Item>

      <Form.Item
        name="certificationNumber"
        label={t(t('private.handbooks.edit.certification.fields.certificationNumber.label'))}
        rules={[{ required: success, message: t('general.form.required') }]}
      >
        <Input size="large" defaultValue={certificationNumber} disabled={!!certificationNumber || !success} />
      </Form.Item>
    </Form>
  );
};
