import bluebird from 'bluebird';
import { runTransaction, setDoc, Timestamp, deleteDoc } from 'firebase/firestore';
import { firestore } from '../../firebase/init';
import { productGetRef, productIdFromProduct } from '../../shared/products';
import { DuplicateSaveError } from '../../types/errors';
import { Product, ProductStatus } from '../../types/firebase';

export const createProduct = (product: Product) => {
  const docRef = productGetRef(product);
  const createPromise = runTransaction(firestore, (transaction) => {
    return transaction.get(docRef).then((snapshot) => {
      if (snapshot.exists()) throw new DuplicateSaveError('There is already an exact variant!');

      const productToCreate: Product = {
        ...product,
        key: productIdFromProduct(product),
        variant: product.variant ?? null,
        blueprints: {},
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
        status: ProductStatus.Draft,
        description: product.description ?? null,
      };
      transaction.set(docRef, productToCreate);
    });
  });

  return bluebird.resolve(createPromise);
};

export const publishProduct = (product: Product) => {
  const docRef = productGetRef(product);
  return bluebird.resolve(setDoc(docRef, { status: ProductStatus.Released }, { merge: true }));
};

export const archiveProduct = (product: Product) => {
  const docRef = productGetRef(product);
  return bluebird.resolve(setDoc(docRef, { status: ProductStatus.Retired }, { merge: true }));
};

export const updateProduct = (product: Product) => {
  const docRef = productGetRef(product);
  return bluebird.resolve(setDoc(docRef, { ...product, updatedAt: Timestamp.now() }, { merge: true }));
};

export const deleteProduct = (product: Product) => {
  const docRef = productGetRef(product);
  return bluebird.resolve(deleteDoc(docRef));
};
