import { CommentOutlined } from '@ant-design/icons';
import { Avatar, List, Skeleton, Timeline } from 'antd';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EmptyMessage from '../../../../components/molecules/emptyMessage';
import LoadingIndicator from '../../../../components/molecules/loadingIndicator';
import { firestore } from '../../../../firebase/init';
import { UserContext } from '../../../../shared/contexts';
import { handbookGetRef } from '../../../../shared/handbooks';
import { Company, Handbook } from '../../../../types/firebase';
import { WPKChangeHistoryEntry } from '../../../../types/firebase/collections/changeHistory';
import collections from '../../../../types/shared/collections';

interface Props {
  company: Company;
  handbook: Handbook;
}

export const HandbookChangeHistory: React.FC<Props> = (props) => {
  const { handbook, company } = props;
  const { firestoreUser } = useContext(UserContext);
  const { t } = useTranslation();

  const [historyEntries, setHistoryEntries] = useState<WPKChangeHistoryEntry[] | undefined>(undefined);

  useEffect(() => {
    const handBookRef = handbookGetRef(company, handbook);
    const cleanup = onSnapshot(
      query(collection(firestore, handBookRef.path, collections.changeHistory), orderBy('createdAt', 'desc')),
      (snapshot) => setHistoryEntries(snapshot.docs.map((d) => d.data() as WPKChangeHistoryEntry)),
    );

    return cleanup;
  }, [company, handbook]);

  return (
    <>
      {(!historyEntries || !firestoreUser) && <LoadingIndicator />}

      {!!historyEntries && historyEntries.length === 0 && (
        <EmptyMessage
          title={t('general.notFound.title', { item: t('changeHistory.single') })}
          description={t('general.notFound.description')}
          logo={<CommentOutlined />}
        />
      )}

      {historyEntries && historyEntries.length > 0 && firestoreUser && (
        <Timeline>
          {historyEntries.map((entry) => {
            return (
              <Timeline.Item key={entry.key} color={t(`admin.users.roleColors.${entry.authorRole}`)}>
                <List.Item.Meta
                  avatar={<Avatar src={entry.avatarUrl} />}
                  title={`${entry.createdAt.toDate().toLocaleString()} - ${entry.author} - ${entry.formTitle} (${entry.formId})`}
                  description={entry.text}
                  style={{ whiteSpace: 'pre-line' }}
                />
              </Timeline.Item>
            );
          })}
        </Timeline>
      )}
    </>
  );
};
